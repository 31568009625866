#wrapper {

    #main {

        .s-pages {

            .container {

                .c-blog {
                    position: relative;
                    width: 100%;

                    .blog-desc {
                        position: relative;
                        width: 100%;
                        margin-bottom: 32px;

                        p {
                            position: relative;
                            width: 100%;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .blog-list {
                        position: relative;
                        width: 100%;

                        h3 {
                            margin: 0 0;
                            position: relative;
                            width: 100%;
                            text-align: left;
                        }

                        p {
                            position: relative;
                            width: 100%;
                            margin: 0 0 12px 0;
                        }

                        .row {
                            margin: 0 -12px;

                            .top-3 {
                                position: relative;
                                width: 100%;
                                padding-right: 25%;
                                margin-bottom: 12px;

                                .blog {
                                    width: 100%;
                                    padding: 0 12px;

                                    .wrp-blog {

                                        .blog-thumbs {
                                            padding-top: calc((1/2) * 100%);
                                        }
                                    }

                                    &.top-right,
                                    &.bottom-right {
                                        position: absolute;
                                        right: 0;
                                        padding: 0 12px;
                                        width: 25%;
                                        height: calc(50% - 24px);
                                        margin: 0 0;

                                        .wrp-blog {

                                            .blog-thumbs {
                                                padding-top: 0;
                                                height: 100%;

                                                a {
                                                    position: relative;
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                    &.top-right {
                                        top: 12px;
                                    }

                                    &.bottom-right {
                                        bottom: 12px;
                                    }
                                }
                            }

                            .blog {
                                width: 25%;
                                padding: 0 12px;
                                margin: 12px 0;
                            }
                        }
                    }

                    .around-bali,
                    .blog-categories {
                        position: relative;
                        width: 100%;
                        margin-top: 32px;

                        h3 {
                            position: relative;
                            width: 100%;
                            text-align: left;
                            margin-bottom: 24px;
                        }

                        .row {
                            margin: 0 0;

                            .swiper-container {
                                position: relative;
                                width: 100%;

                                .swiper-wrapper {
                                    position: relative;

                                    .blog {
                                        width: 100%;
                                        padding: 0 0;
                                    }
                                }

                                .btn-prev,
                                .btn-next {
                                    position: absolute;
                                    top: 50%;
                                    width: 32px;
                                    height: 32px;
                                    z-index: 2;
                                    display: block;
                                    background: rgba(255, 255, 255, .6);
                                    @include border-radius(50%);
                                    overflow: hidden;
                                    @include vendor('transition', ('all 0.5s'));
                                    @include vendor('transform', 'translate(0, -50%)');

                                    i.icon {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                        padding: 4px;

                                        &:before {
                                            color: $primary;
                                        }
                                    }

                                    &:hover {
                                        cursor: pointer;
                                        background: $primary;

                                        i.icon {

                                            &:before {
                                                color: $white;
                                            }
                                        }
                                    }
                                }

                                .btn-prev {left: 8px;}
                                .btn-next {right: 8px;}
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=medium') {
    
            .s-pages {

                .container {

                    .c-blog {

                        .blog-desc {
                            margin-bottom: 24px;
                        }

                        .blog-list {

                            .row {
                                margin: 0 -8px;

                                .top-3 {
                                    padding-right: 0;
                                    margin-bottom: 0;
                                    @include float-pos;
    
                                    .blog {
                                        width: 100%;
                                        padding: 0 8px;
                                        float: left;
    
                                        .wrp-blog {
    
                                            .blog-thumbs {
                                                padding-top: calc((1/2) * 100%);
                                            }
                                        }
    
                                        &.top-right,
                                        &.bottom-right {
                                            position: relative;
                                            right: 0;
                                            padding: 0 8px;
                                            width: 50%;
                                            height: auto;
                                            margin: 12px 0;
    
                                            .wrp-blog {
    
                                                .blog-thumbs {
                                                    padding-top: calc((1/2) * 100%);
                                                    height: auto;
    
                                                    a {
                                                        position: absolute;
                                                        display: block;
                                                    }
                                                }
                                            }
                                        }
    
                                        &.top-right {
                                            top: 0;
                                        }
    
                                        &.bottom-right {
                                            bottom: 0;
                                        }
                                    }
                                }

                                .blog {
                                    width: 33.33%;
                                    padding: 0 8px;
                                }
                            }
                        }
                    }
                }
            }
        } 

        @include breakpoint('<=xsmall') {
    
            .s-pages {

                .container {

                    .c-blog {

                        .blog-desc {
                            margin-bottom: 16px;
                        }

                        .blog-list {

                            .row {
                                margin: 0 -8px;

                                .top-3 {
    
                                    .blog {
    
                                        .wrp-blog {
    
                                            .blog-thumbs {
                                                padding-top: calc((1/2) * 100%);
                                            }
                                        }
    
                                        &.top-right,
                                        &.bottom-right {
                                            width: 50%;
    
                                            .wrp-blog {
    
                                                .blog-thumbs {
                                                    padding-top: calc((1/1) * 100%);
                                                }
                                            }
                                        }
                                    }
                                }
                                
                                .blog {
                                    width:50%;
                                    padding: 0 8px;
                                    margin: 8px 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=xxsmall') {
    
            .s-pages {

                .container {

                    .c-blog {

                        .blog-desc {
                            margin-bottom: 16px;
                        }

                        .blog-list {

                            .row {
                                margin: 0 -8px;

                                .top-3 {
    
                                    .blog {
    
                                        .wrp-blog {
    
                                            .blog-thumbs {
                                                padding-top: calc((1/2) * 100%);
                                            }
                                        }
    
                                        &.top-right,
                                        &.bottom-right {
                                            width: 100%;
    
                                            .wrp-blog {
    
                                                .blog-thumbs {
                                                    padding-top: calc((1/2) * 100%);
                                                }
                                            }
                                        }
                                    }
                                }
                                
                                .blog {
                                    width:100%;
                                    padding: 0 8px;
                                    margin: 8px 0;
    
                                    .wrp-blog {

                                        .blog-thumbs {
                                            padding-top: calc((1/2) * 100%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}