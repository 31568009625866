#wrapper {

    #main {

        .s-pages {

            .container {

                .side-content {

                    .w-side {

                        .list-villa {
                            position: relative;
                            width: 100%;

                            .row {
                                margin: 0 -8px;

                                .villa {
                                    width: calc(100% / 3);
                                    padding: 0 8px;
                                    margin-bottom: 16px;

                                    &.full {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=xlarge') {

            .s-pages {

                .container {

                    .side-content {

                        .w-side {


                        }
                    }
                }
            }
        }

        @include breakpoint('<=large') {

            .s-pages {

                .container {

                    .side-content {

                        .w-side {
        
                            .list-villa {
    
                                .row {
    
                                    .villa {
                                        width: calc(100% / 2);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=medium') {

            .s-pages {

                .container {

                    .side-content {

                        .w-side {
        
                            .list-villa {
    
                                .row {
    
                                    .villa {
                                        width: calc(100% / 3);
                                    }
                                }
                            } 
                        }
                    }
                }
            }
        }

        @include breakpoint('<=small') {

            .s-pages {

                .container {

                    .side-content {

                        .w-side {
        
                            .list-villa {
    
                                .row {
    
                                    .villa {
                                        width: calc(100% / 2);
                                    }
                                }
                            }  
                        }
                    }
                }
            }
        }

        @include breakpoint('<=xsmall') {

            .s-pages {

                .container {

                    .side-content {

                        .w-side {
        
                            .list-villa {
    
                                .row {
    
                                    .villa {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}