/*  
    ============================================================= 
        WRAPPER
    ============================================================= 
*/

#wrapper {
    z-index: 1;
    min-height: 100vh;
	@include vendor('transition', ('transform 0.5s ease'));
    @include flex($flex-direction: column);
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    /*  
        ============================================================= 
            MAIN
        ============================================================= 
    */

    #main {
		position: relative;
		margin: 0 auto;
		padding: 0 0;
		width: 100%;
        flex: 1 0 auto;
        gap: 0;
        column-gap: 0;

        //pages
        &.p-pages {
            position: relative;
            width: 100%;

            //section list villa
            .s-list {
                position: relative;
                width: 100%;
                margin-bottom: 48px;

                .container {

                    .list-villa {
                        position: relative;
                        width: 100%;
                        margin-top: 48px;

                        .row {
                            margin: 0 -8px;

                            .villa {
                                padding: 0 8px;
                                margin-bottom: 24px;
                                width: calc(100% / 4);

                                &.full {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            //section pages
            .s-pages {
                position: relative;
                width: 100%;

                .container {
                    @include float-pos;

                    // side nav
                    .side-nav {
                        float: left;
                        width: 300px;
                        padding-right: 16px;

                        .w-side {
                            position: relative;
                            width: 100%;

                            .side-search {
                                position: relative;
                                width: 100%;
                                background-color: $primary;
                                padding: 16px 16px;
                                @include border-radius(5px);
                                margin-bottom: 24px;
        
                                .form,
                                form {
                                    position: relative;
                                    width: 100%;
        
                                    .row {
                                        margin-left: -4px;
                                        margin-right: -4px;
        
                                        .box {
                                            width: 100%;
                                            padding: 0 4px;
                                            margin-bottom: 16px;
        
                                            input[type="text"] {
                                                height: 56px;
                                                padding: 16px 16px 16px 48px;
                                                border: 1px solid #dee0e2;
                                                background: $white;
                                                border-radius: 5px;	
                                                color: #80868e;
                                                font-size: 16px;
                                                line-height: 24px;
                                                font-family: $barlow;
                                                font-weight: 300;
                                                letter-spacing: 1px;
        
                                                &::-webkit-input-placeholder {
                                                    padding: 16px 0px;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    color: rgba($black, .5);
                                                    font-family: $barlow;
                                                    font-weight: 300;
                                                    letter-spacing: 1px;
                                                }
                                            
                                                &:-moz-placeholder {
                                                    padding: 16px 0px;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    color: rgba($black, .5);
                                                    font-family: $barlow;
                                                    font-weight: 300;
                                                    letter-spacing: 1px;
                                                }
                                            
                                                &::-moz-placeholder {
                                                    padding: 16px 0px;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    color: rgba($black, .5);
                                                    font-family: $barlow;
                                                    font-weight: 300;
                                                    letter-spacing: 1px;
                                                }
                                            
                                                &:-ms-input-placeholder {
                                                    padding: 16px 0px;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    color: rgba($black, .5);
                                                    font-family: $barlow;
                                                    font-weight: 300;
                                                    letter-spacing: 1px;
                                                }
                                            }
        
                                            .select-box {
                                        
                                                .selectric-wrapper {
                                                    
                                                    .selectric {                                                @include set-border-radius(5px, 5px, 5px, 5px);
                                                        background: $white;
                                                        height: 56px;
                                                        padding: 16px 48px 16px 16px;
                                        
                                                        .label {
                                                            font-size: 16px;
                                                            height: auto;
                                                        }
                                        
                                                        .button {
                                                            width: 48px;
                                                            height: 48px;
                                                            padding: 12px;
                                                            top: 4px;
                                        
                                                            &:after {
                                                                font-size: 16px;
                                                            }
                                                        }
                                                    }
                                        
                                                    .selectric-items {
                                                        background: $white;
                                                        @include border-radius(0px);
                                        
                                                        .selectric-scroll {
                                        
                                                            ul {
                                        
                                                                li {
                                        
                                                                    &.selected.highlighted {
                                                                        background-color: $gray-light;
                                                                        color: $primary;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                        
                                                    &.selectric-open {
                                        
                                                        .selectric {
                                                            @include set-border-radius(0px, 0px, 0px, 0px);
                                                        }
                                                    }
                                                }
                                            }
        
                                            .btn.primary {
                                                height: 56px;
                                                margin-bottom: 0;
                                                width: 100%;
                                                background-color: $white;
        
                                                span {
                                                    font-size: 16px;
                                                    text-transform: uppercase;
                                                    color: $black;
                                                }
                                            }
        
                                            &.has-icon {
                                                
                                                input[type="text"] {
                                                    padding-left: 48px;
                                                }
        
                                                i.icon {
                                                    position: absolute;
                                                    top: 8px;
                                                    left: 4px;
        
                                                    &:before {
                                                        color: $primary;
                                                    }
                                                }
        
                                                &:after {display: none;}
                                            }
                                        }
                                    }
                                }
                            }

                            .side-filter {
                                position: relative;
                                width: 100%;
                                margin-bottom: 24px;
                                display: block;
        
                                .w-side-filter {
                                    position: relative;
                                    width: 100%;
                                    @include set-border-radius(5px, 0, 5px, 0);
                                    border: 1px solid $gray-light;
                                    overflow: hidden;
        
                                    h5 {
                                        background-color: $primary;
                                        color: $white;
                                        text-align: left;
                                        padding: 4px 8px;
                                    }
        
                                    .content-filter {
                                        position: relative;
                                        width: 100%;
                                        padding: 16px 8px;
                                        margin: 0 0;
                
                                        .box {
                                            width: 100%;
                                            padding: 4px 0;
                                        }
                                    }
                                }
                            }

                            .filter {
                                position: relative;
                                width: 100%;
                                display: none;
                                margin-bottom: 24px;
                        
                                .w-filter {
                                    position: relative;
                                    width: 100%;
                        
                                    .filter-title {
                                        position: relative;
                                        width: 100%;
                                        display: inline-block;
                                        margin-bottom: 16px;
                                    }
                        
                                    .tabs-filter {
                                        position: relative;
                                        width: 100%;
                                        border: 0;
                                        padding: 0 0;
                        
                                        ul {
                                            padding: 0 100px 0 0;
                                            margin: 0 0;
                                            @include border-radius(0);
                                            border: 0;
                                            color: $black;
                                            background: transparent;
                                            @include vendor('transition', ('all 0.5s',));
                                            overflow-x: auto;
                                            white-space: nowrap;
                                            -webkit-overflow-scrolling: touch;
                        
                                            li {
                                                border-color: $gray-light;
                                                background: transparent;
                                                margin: 0 4px 0 0;
                                                @include vendor('transition', ('all 0.5s',));
                                                letter-spacing: 1px;
                                                border-bottom: 0;
                                                top: 1px;
                        
                                                a {
                                                    padding: 8px 16px;	
                                                    margin: 0 0;
                                                    font-size: 15px;
                                                    line-height: 24px;
                                                    letter-spacing: 1px;
                                                    font-weight: 400;
                                                    color: $black;
                                                    @include vendor('transition', ('all 0.5s',));
                                                    display: block;
                                                    width: 100%;
                                                    @include vendor('transition', ('all 0.5s',));
        
                                                    i.icon {
                                                        width: 24px;
                                                        height: 24px;
                                                        padding: 0 0;
                                                        @include vendor('transition', ('all 0.5s',));
        
                                                        &:before {
                                                            color: $black;
                                                            @include vendor('transition', ('all 0.5s',));
                                                        }
                                                    }
        
                                                    span {
                                                        display: inline-block;
                                                        margin-right: 4px;
                                                        @include vendor('transition', ('all 0.5s',));
                                                    }
                                                }
                        
                                                &:hover,
                                                &.ui-tabs-active,
                                                &.ui-state-active {
                                                    background: rgba($primary, 1);
                                                    color: $white;
                                                    border-color: rgba($primary, 1);
                                                    cursor: pointer;
                                                    border-bottom: 0;
                        
                                                    a {
                                                        color: $white;
        
                                                        i.icon {
        
                                                            &:before {
                                                                color: $white;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
        
                                            a.btn.primary {
                                                position: absolute;
                                                top: 2px;
                                                right: 0;
                                                width: 100px;
                                                height: 34px;
                                                padding: 5px 0;
                                                margin-bottom: 0;
                                                z-index: 2;
                                                @include set-border-radius(3px, 0, 3px, 0);
        
                                                span {
                                                    line-height: 24px;
                                                    text-align: center;
                                                }
                                            }

                                            &:after {
                                                position: absolute;
                                                top: 0;
                                                right: 0px;
                                                width: 100%;
                                                height: 33px;
                                                background: $white;
                                                content: '';
                                                display: none;
                                            }
                                        }
                        
                                        .c-filter {
                                            position: relative;
                                            display: block;
                                            width: 100%;
                                            @include set-border-radius(0, 3px, 0, 3px);
                                            border: 1px solid $gray-light;
                                            padding: 24px;
                                            @include vendor('transition', ('all 0.5s',));
                        
                                            .content-filter {
                                                position: relative;
                                                @include flex($flex-wrap: wrap, $align: stretch);
                                                margin: -8px -8px 0 -8px;
                        
                                                .box {
                                                    width: calc(100% / 2);
                                                    padding: 8px 8px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
        
                            .side-promo {
                                position: relative;
                                width: 100%;
                                margin-bottom: 32px;
        
                                .wrapper-side-promo {
                                    position: relative;
                                    width: 100%;
                                    @include border-radius(5px);
                                    overflow: hidden;
        
                                    a {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        color: transparent;
                                        border: 0;
        
                                        img {
                                            width: 100%;
                                            height: auto;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //side content
                    .side-content {
                        float: left;
                        width: calc(100% - 300px);
                        padding-left: 16px;

                        .w-side {
                            position: relative;
                            width: 100%;

                            .page-descriptions {
                                position: relative;
                                width: 100%;
                                margin-bottom: 32px;

                                h3, h4 {
                                    position: relative;
                                    width: 100%;
                                    margin-bottom: 16px;
                                    text-align: left;
                                }

                                p {
                                    margin: 0 0;
                                    position: relative;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            //register or login
            .register {
                position: relative;
                width: 100%;
                
                .container {
        
                    .w-register {
                        position: relative;
                        width: 100%;
                        @include flex(wrap, row, center, center);
        
                        .c-register {
                            width: 50%;
                            @include flex(wrap, column, center, center);
                            padding-right: 16px;
        
                            h3 {
                                text-transform: none;
                                text-align: left;
                                width: 100%;
                            }
        
                            P {
                                margin: 24px 0 0 0;
                            }
                        }
        
                        .form-register {
                            width: 50%;
                            padding-left: 16px;
        
                            p.info {
                                margin: 0 0 24px 0;
                                text-align: right;
        
                                a {
                                    color: rgba($primary, 1);
                                    text-decoration: none;
                                }
                            }
        
                            form {
                                position: relative;
                                width: 100%;
                                padding: 16px;
                                @include border-radius(5px);
                                border: 1px solid $gray-light;
        
                                .row {
                                    margin: 0 0;
        
                                    .box {
                                        padding: 0 0;
                                        margin-bottom: 16px;
        
                                        .btn {
                                            margin: 0 8px;
        
                                            span {
                                                font-weight: 500;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                }
        
                            }
        
                            .social-register {
                                margin: 32px 0 0 0;
                                position: relative;
                                display: inline-block;
                                text-align: center;
                                width: 100%;
        
                                .info-register {
                                    position: relative;
                                    text-align: center;
                                    width: 100%;
                                    margin-bottom: 24px;
        
                                    span {
                                        display: inline-block;
                                        padding-top: 16px;
                                        position: relative;
                                        width: auto;
                                    }
                                }
        
                                a.btn {
                                    margin: 0 8px;
        
                                    span {
                                        color: $white;
                                    }
        
                                    i.icon {
        
                                        &:before {
                                            color: $white;
                                        }
                                    }
        
                                    &.facebook {
                                        background-color: $facebook;
                                    }
        
                                    &.twitter {
                                        background-color: $twitter;
                                    }
                                }
                            }
                        }
                    }
                }

                .block-content {
                    position: relative;
                    width: 100%;
                    min-height: calc(100vh - 104px);
                    @include flex($align: stretch);

                    .left-content {
                        position: relative;
                        width: 50%;

                        .img-content {
                            position: relative;
                            width: 100%;
                            height: 100%;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .right-content {
                        position: relative;
                        width: 50%;
                        @include flex($align: center);

                        .block-form {
                            position: relative;
                            width: 100%;
                            text-align: left;
                            padding: 32px 0 32px 32px;

                            .c-register {
                                width: calc((1400px / 2) - 64px);
                                position: relative;
                                display: inline-block;

                                h3 {
                                    position: relative;
                                    width: 100%;
                                    text-align: left;
                                    margin: 0 0;
                                }

                                p {
                                    margin: 16px 0 0 0;
                                    text-align: left;
                                    letter-spacing: 0.5px;
                                }

                                form {
                                    position: relative;
                                    width: 100%;
                                    margin: 32px 0 0px 0;

                                    .row {

                                        .box {

                                            .btn {
                                                width: 100%;
                                                margin-bottom: 0;

                                                span {
                                                    text-transform: uppercase;
                                                    font-weight: 500;
                                                }
                                            }
                                        }
                                    }
                                }

                                .social-register {
                                    position: relative;
                                    width: 100%;
                                    text-align: center;

                                    .info-register {
                                        position: relative;
                                        width: 100%;
                                        height: auto;
                                        text-align: center;
                                        margin-bottom: 16px;

                                        &:before {
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            width: 100%;
                                            height: 1px;
                                            background-color: $gray-light;
                                            content: '';
                                        }

                                        span {
                                            display: inline-block;
                                            width: auto;
                                            padding: 0 32px;
                                            background-color: $white;
                                            position: relative;
                                            z-index: 2;
                                        }
                                    }

                                    a.btn {
                                        width: calc(50% - 8px);
                                        margin-bottom: 16px;

                                        i.icon {
                                            margin-right: 8px;
                                            
                                            &:before {
                                                color: $white;
                                                font-size: 18px;
                                            }
                                        }

                                        span {
                                            color: $white;
                                            text-transform: uppercase;
                                        }

                                        &.facebook {
                                            background-color: $facebook;
                                            margin-right: 8px;
                                        }

                                        &.twitter {
                                            background-color: $twitter;
                                            margin-left: 8px;
                                        }

                                        &.google {
                                            background-color: $gray-light;

                                            i.icon {
                                                
                                                &:before {
                                                    color: $black;
                                                }
                                            }

                                            span {
                                                color: $black;
                                            }
                                        }
                                    }

                                    p {
                                        position: relative;
                                        width: 100%;
                                        text-align: center;
                                        margin: 24px 0 0 0;

                                        a {
                                            color: rgba($primary, 1);
                                            font-weight: 500;
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .container {

                //page with side
                .page-with-side {
                    position: relative;
                    width: 100%;
                    @include float-pos;
                    padding-top: 32px;

                    .left-side {
                        float: left;
                        width: 300px;

                        .account {
                            position: relative;
                            width: 100%;
                            @include border-radius(5px);
                            border: 1px solid $gray-light;
                            margin-bottom: 32px;
                            background: $white;
                            box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;

                            .w-account {
                                position: relative;
                                width: 100%;
                                
                                .header-account {
                                    padding: 8px 8px 12px 8px;
                                    border-bottom: 1px solid rgb(229,231,233);
                                    position: relative;
                                    width: 100%;

                                    a {
                                        @include flex(wrap, row, center, flex-start);
                                        width: 100%;
                                        height: auto;
                                        color: $black;

                                        .photo {
                                            width: 48px;
                                            height: 48px;
                                            display: inline-block;
                                            margin-right: 8px;
                                            @include border-radius(3px);
                                            overflow: hidden;

                                            img {
                                                display: block;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }

                                        span {
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 24px;
                                            text-align: left;
                                            display: inline-block;
                                            width: calc(100% - 56px);
                                        }
                                    }
                                }

                                .point-account {
                                    position: relative;
                                    width: 100%;
                                    padding: 12px 8px 8px 8px;

                                    .title-point {
                                        position: relative;
                                        width: 100%;
                                        text-align: left;
                                        margin-bottom: 16px;

                                        i.point {
                                            display: inline-block;
                                            width: 40px;
                                            height: 24px;
                                            background: url('../img/ico-coupon-gray.png') no-repeat;
                                            background-size: 100% 100%;
                                            margin-right: 8px;
                                            vertical-align: top;
                                        }

                                        span {
                                            font-weight: 600;
                                            line-height: 24px;
                                            font-size: 14px;
                                            color: black;
                                            display: inline-block;
                                            width: calc(100% - 48px);
                                            text-align: left;
                                            vertical-align: top;
                                        }
                                    }

                                    .info-point {
                                        position: relative;
                                        width: 100%;

                                        a {
                                            display: block;
                                            width: 100%;
                                            @include float-pos;
                                            color: black;

                                            .left {
                                                float: left;
                                                width: auto;
                                            }

                                            .right {
                                                float: right;
                                                text-align: right;
                                                width: auto;
                                                font-weight: 600;
                                            }

                                            &:hover {

                                                color: rgba($primary, 1);
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .search {
                            position: relative;
                            width: 100%;
                            padding: 8px;
                            background-color: rgba($primary, 1);
                            @include border-radius(5px);
                            box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;

                            .w-search {
                                position: relative;
                                width: 100%;

                                h4 {
                                    color: $white;
                                    text-align: left;
                                    width: 100%;
                                    margin-bottom: 16px;
                                }

                                form,
                                .form {
                                    position: relative;
                                    width: 100%;

                                    .row {

                                        .box {
                                            label {
                                                font-size: 14px;
                                                color: $white;
                                            }

                                            .btn {
                                                width: 100%;
                                                margin-bottom: 0;
                                                background-color: $white;

                                                span {
                                                    color: rgba($primary, 1);
                                                    text-transform: uppercase;
                                                    font-weight: 600;
                                                }
                                            }
                                            
                                            &.has-icon {
                                                input[type="text"],
                                                input[type="password"],
                                                input[type="email"],
                                                input[type="tel"],
                                                input[type="number"] {
                                                    padding-left: 48px;
                                                    padding-right: 16px;
                                                }

                                                i.icon {
                                                    position: absolute;
                                                    top: 28px;

                                                    &:before {
                                                        color: $primary;
                                                    }
                                                }
                                            }

                                            &.half {
                                                width: 50%;
                                            }

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }
                                        }    
                                    }
                                }
                            }
                        }

                        .promo {
                            position: relative;
                            width: 100%;
                            margin-top: 32px;
                            @include border-radius(5px);
                            overflow: hidden;

                            .w-promo {
                                position: relative;
                                width: 100%;

                                a {
                                    display: block;
                                    width: 100%;
                                    height: auto;
                                    color: transparent;
                                    border: 0;
                                    outline: none;
                                    text-decoration: none;

                                    img {
                                        width: 100%;
                                        height: auto;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .right-side {
                        float: left;
                        width: calc(100% - 300px);
                        padding-left: 32px;

                        h4 {
                            position: relative;
                            display: inline-block;
                            margin-bottom: 32px;
                            text-align: left;
                            color: #707070;

                            b {
                                color: rgba($black, 1);
                            }
                        }

                        .c-message {
                            position: relative;
                            width: 100%;
                            padding: 16px;
                            @include border-radius(3px);
                            overflow: hidden;
                            margin-bottom: 24px;
                            display: inline-block;
                            box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;

                            .w-message {
                                position: relative;
                                width: 100%;
                                padding-right: 24px;

                                p {
                                    display: block;
                                    text-align: left;
                                    color: $white;
                                    margin: 0 0;

                                    a {
                                        display: inline-block;
                                        font-weight: 600;
                                        color: $white;
                                        text-decoration: underline;
                                    }
                                }

                                a.act {
                                    position: absolute;
                                    top: 0px;
                                    right: 0px;
                                    width: 24px;
                                    height: 24px;
                                    padding: 0 0;

                                    i.icon {
                                        width: 24px;
                                        height: 24px;
                                        padding: 0 0;

                                        &:before {
                                            color: $white;
                                        }

                                        &.close {

                                            &:before {
                                                font-size: 22px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.success {
                                background-color: $success;
                            }

                            &.warning {
                                background-color: $danger;
                            }
                        }

                        .c-info {
                            position: relative;
                            width: 100%;
                            margin-bottom: 32px;
                            display: inline-block;

                            .w-info {
                                position: relative;
                                width: 100%;
                                border: 1px solid rgb(229,231,233);
                                box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;
                                
                                .row {
                                    margin: 0 0;

                                    .info {
                                        position: relative;
                                        width: 33.33%;
                                        padding: 0 0;

                                        .the-info {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                            padding: 16px;
                                            background-color: $white;
                                            @include flex(wrap, column, center, flex-start);
                                            @include vendor('transition', ('all 0.5s'));

                                            i.icon {
                                                width: 48px;
                                                height: 48px;
                                                @include border-radius(50%);
                                                background-color: rgba($primary, 1);
                                                @include vendor('transition', ('all 0.5s'));

                                                &:before {
                                                    color: $white;
                                                    font-size: 24px;
                                                    line-height: 24px;
                                                }
                                            }

                                            h5 {
                                                text-align: center;
                                                width: 100%;
                                                margin-top: 16px;
                                                margin-bottom: 8px;
                                                @include vendor('transition', ('all 0.5s'));
                                            }

                                            p {
                                                margin: 0 0;
                                                width: 100%;
                                                text-align: center;
                                                @include vendor('transition', ('all 0.5s'));
                                            }
                                        }

                                        &:hover {
                                            cursor: pointer;

                                            .the-info {
                                                background-color: rgba($primary, 1);

                                                i.icon {
                                                    background-color: $white;
                                                    &:before {
                                                        color: $primary;
                                                    }
                                                }

                                                h5,
                                                p {
                                                    color: $white;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }

                        .c-dashboard {
                            position: relative;
                            width: 100%;
                            margin-bottom: 32px;
                            display: inline-block;
                            
                            .c-title {
                                position: relative;
                                width: 100%;
                                background-color: $gray-light;
                                padding: 16px;

                                .w-title {
                                    position: relative;
                                    width: 100%;

                                    h3 {
                                        position: relative;
                                        width: 100%;
                                        margin-bottom: 8px;
                                    }

                                    p {
                                        width: 100%;
                                        text-align: left;
                                        margin: 0 0;
                                    }
                                }
                            }

                            .w-dashboard {
                                position: relative;
                                width: 100%;
                                @include set-border-radius(0, 3px, 0, 3px);
                                border: 1px solid $gray-light;
                                padding: 16px 14px;

                                .form,
                                form {
                                    position: relative;
                                    width: 100%;

                                    .row {

                                        .box {
                                            padding-left: 250px;
                                            position: relative;

                                            label {
                                                position: absolute;
                                                top: 12px;
                                                left: 0;
                                                width: calc(250px - 16px);
                                                font-size: 14px;
                                            }

                                            p.info {
                                                margin-top: 8px;
                                                vertical-align: middle;

                                                i.icon {
                                                    width: 24px;
                                                    height: 24px;
                                                    padding: 0 0;
                                                    margin-right: 8px;

                                                    &:hover {
                                                        cursor: pointer;
                                                    }
                                                }

                                                a {
                                                    display: inline-block;
                                                    color: $primary;
                                                    font-weight: 600;
                                                    text-decoration: underline;
                                                }
                                            }

                                            .select-box {
                                                width: 32.89%;
                                                padding: 0 4px;
                                            }

                                            .btn {
                                                width: 200px;
                                                margin-bottom: 0;

                                                span {
                                                    font-weight: 600;
                                                    font-size: 14px;
                                                    text-transform: uppercase;
                                                }
                                            }

                                            .box-images {
                                                position: relative;
                                                width: 100%;

                                                input[type=file] {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    right: 0;
                                                    bottom: 0;
                                                    width: 100%;
                                                    height: 100%;
                                                    opacity: 0;
                                                    z-index: 2;
                                                }

                                                .file-dumy {
                                                    position: relative;
                                                    width: 100%;
                                                    padding: 32px;
                                                    background: $gray-light;
                                                    text-align: center;
                                                    @include border-radius(3px);
                                                    @include vendor('transition', ('background 0.3s ease-in-out'));

                                                    .msg-success {
                                                        display: none;
                                                        color: $white;
                                                    }

                                                    .msg-default {
                                                        color: $black;
                                                        display: inline-block;

                                                        i.icon {
                                                            padding: 0 0;

                                                            &:before {
                                                                width: 48px;
                                                                height: 48px;
                                                                font-size: 48px;
                                                                line-height: 48px;
                                                            }
                                                        }
                                                    }

                                                    &:hover {
                                                        cursor: pointer;
                                                    }
                                                }

                                                input[type=file]:valid + .file-dumy {
                                                    background: $success;

                                                    .msg-default {display: none;}
                                                    .msg-success {display: inline-block;}
                                                }
                                            }

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }

                                            &.has-error,
                                            &.has-success {

                                                input[type=text],
                                                input[type=email],
                                                input[type=number] {
                                                    padding-right: 16px;
                                                }
                                                
                                                &:after {display: none;}
                                            }
                                        }
                                    }
                                }

                                .box-question {
                                    position: relative;
                                    width: 100%;
                                    @include vendor('transition', ('all 0.3s ease-in-out'));

                                    .question {
                                        position: relative;
                                        width: 100%;
                                        background: $white;
                                        border-bottom: 1px solid $gray-light;
                                        border-left: 1px solid $gray-light;
                                        border-right: 1px solid $gray-light;
                                        padding: 8px;
                                        font-weight: 600;
                                        letter-spacing: 0;
                                        @include vendor('transition', ('all 0.3s ease-in-out'));
                                        padding-right: 24px;

                                    }

                                    .answer {
                                        display: none;
                                        width: 100%;
                                        height: 0;
                                        position: relative;
                                        padding: 16px;
                                        @include vendor('transition', ('all 0.5s ease-in-out'));
                                        border: 1px solid $gray-light;

                                        p {
                                            margin: 0 0 16px 0;
                                            position: relative;
                                            text-align: left;
                                            
                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }

                                    &:hover {
                                        cursor: pointer;
                                    }

                                    &:first-of-type {

                                        .question {
                                            border-top: 1px solid $gray-light;
                                        }
                                    }

                                    //active
                                    &.active {

                                        .question {
                                            color: $primary;
                                            border-bottom-color: $white;
                                        }

                                        .answer {
                                            display: block;
                                            height: auto;
                                        }
                                    }

                                }
                            }

                            &:first-of-type {
                                margin-top: 0;
                            }
                        }
                    
                        .banner-promo {
                            position: relative;
                            width: 100%;
                            display: inline-block;
                            margin-bottom: 32px;

                            .row {
                                margin: 0 -16px;

                                .c-banner-promo {
                                    position: relative;
                                    width: 50%;
                                    padding: 16px;

                                    .w-promo {
                                        position: relative;
                                        width: 100%;
                                        height: 100%;
                                        padding: 16px;
                                        @include flex(wrap, row, center, center);
                                        border: 1px solid rgba(49,53,59,0.12);
                                        box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;

                                        .thumb {
                                            position: relative;
                                            width: 100px;
                                            height: 100px;
                                            @include border-radius(3px);

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                position: relative;
                                                display: block;
                                            }
                                        }

                                        .desc {
                                            position: relative;
                                            width: calc(100% - 100px - 24px);
                                            margin-left: 24px;
                                            
                                            h5 {
                                                position: relative;
                                                text-align: left;
                                                width: 100%;
                                                margin-bottom: 8px;
                                            }

                                            p {
                                                display: block;
                                                width: 100%;
                                                margin: 0 0;
                                                text-align: left;

                                                a {
                                                    color: rgba($black, .5);
                                                    text-align: left;
                                                    max-height: calc(24px * 2);
                                                    overflow: hidden;
                                                    display: block;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .c-card-point {
                            position: relative;
                            width: 500px;
                            display: block;
                            margin: 0 auto;
                            background: url('../img/bg-flat.jpg') no-repeat;
                            background: cover;
                            background-position: right top;
                            @include border-radius(5px);
                            overflow: hidden;
                            padding: 32px;

                            .w-card-point {
                                position: relative;
                                width: 100%;
                                height: auto;

                                span {
                                    display: block;
                                    width: 100%;
                                    text-align: left;
                                    font-weight: 600;
                                    letter-spacing: 1px;
                                    color: $white;
                                    font-size: 24px;
                                    line-height: 34px;
                                }

                                .the-point {
                                    position: relative;
                                    width: 100%;
                                    padding: 8px;
                                    background: $white;
                                    @include border-radius(5px);
                                    margin-top: 48px;
                                    display: block;
                                    text-align: left;

                                    i.point {
                                        display: block;
                                        width: 24px;
                                        height: 24px;
                                        background: url('../img/icon-points.png') no-repeat;
                                        background-size: 100% 100%;
                                        position: absolute;
                                        top: 8px;
                                        left: 8px;
                                    }

                                    span {
                                        display: block;
                                        margin: 0 0;
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: $black;
                                        width: auto;
                                        position: absolute;
                                        top: 8px;
                                        left: 40px;
                                        height: 24px;
                                    }

                                    p {
                                        display: block;
                                        width: 100%;
                                        margin: 24px 0 0 0;
                                        font-weight: 600;
                                        color: rgba($primary, 1);
                                        padding-left: 32px;
                                    }
                                }
                            }
                        }

                        .c-coupon {
                            position: relative;
                            width: 100%;
                            display: inline-block;
                            margin: 0 0;

                            .w-coupon {
                                position: relative;
                                width: 100%;

                                h4 {
                                    width: 100%;
                                    text-align: left;
                                    margin: 0 0;
                                }

                                p {
                                    margin: 0 0 24px 0;
                                    width: 100%;
                                    text-align: left;
                                }

                                .row {
                                    margin: 0 -8px;

                                    .coupon {
                                        position: relative;
                                        width: 33.33%;
                                        padding: 0 8px;
                                        margin-bottom: 24px;

                                        .content-coupon {
                                            position: relative;
                                            width: 100%;

                                            .thumb {
                                                position: relative;
                                                width: 100%;
                                                padding-top: calc((1/2) * 100%);

                                                a {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    width: 100%;
                                                    height: 100%;
                                                    text-decoration: none;
                                                    color: transparent;
                                                    outline: none;

                                                    img {
                                                        display: block;
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                        object-position: center;
                                                    }
                                                }
                                            }

                                            .time {
                                                position: relative;
                                                width: 100%;
                                                padding: 16px 16px 16px calc(16px + 48px);
                                                border-left: 1px solid rgba(49,53,59,0.12);
                                                border-right: 1px solid rgba(49,53,59,0.12);
                                                border-bottom: 1px solid rgba(49,53,59,0.12);
                                                @include set-border-radius(0, 3px, 0, 3px);

                                                i.icon {
                                                    position: absolute;
                                                    top: 16px;
                                                    left: 16px;

                                                    &:before {
                                                        font-size: 24px;
                                                    }
                                                }

                                                span {
                                                    display: block;
                                                    width: 100%;
                                                    text-align: left;
                                                    color: $black;
                                                }

                                                p {
                                                    position: relative;
                                                    width: 100%;
                                                    text-align: left;
                                                    color: rgba($primary, 1);
                                                    margin: 0 0;
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .c-booking {
                            position: relative;
                            width: 100%;
                            display: inline-block;
                            margin: 0 0;

                            .search-transaction {
                                position: relative;
                                width: 100%;
                                margin-bottom: 32px;

                                .w-search-transaction {
                                    position: relative;
                                    width: 100%;

                                    .form,
                                    form {
                                        display: block;
                                        width: 100%;

                                        .row {
                                            margin: 0 -8px;
                                            border: 0;
                                            box-shadow: none;

                                            .box {
                                                padding: 0 8px;
                                                width: 20%;
                                            }
                                        }
                                    }
                                }
                            }

                            .row {
                                margin: 0 0;
                                border: 1px solid rgba(49,53,59,0.12);
                                box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;

                                .booking {
                                    position: relative;
                                    width: 100%;
                                    padding: 0 0;

                                    .w-booking {
                                        position: relative;
                                        width: 100%;
                                        border-bottom: 1px solid rgba(49,53,59,0.12);
                                        padding: 16px;
                                        @include flex(wrap, row);

                                        .booking-personal {
                                            position: relative;
                                            width: 20%;
                                            @include flex(wrap, column, center, center);
                                            border-right: 1px solid rgba(49,53,59,0.12);
                                            padding-right: 8px;

                                            .name {
                                                position: relative;
                                                width: 100%;
                                                text-align: left;
                                                font-size: 18px;
                                                line-height: 24px;
                                                font-weight: 500;
                                            }

                                            .property {
                                                position: relative;
                                                width: 100%;
                                            }
                                        }

                                        .booking-info {
                                            position: relative;
                                            width: 60%;
                                            padding: 0 8px;
                                            @include flex(wrap);

                                            .c-booking-info {
                                                position: relative;
                                                width: calc(100% / 3);
                                                padding: 0 8px;
                                                font-size: 14px;
                                                line-height: 24px;
                                                letter-spacing: 0;
                                                color: rgba($black, 1);
                                                font-weight: 600;

                                                span {
                                                    position: relative;
                                                    width: 100%;
                                                    display: block;
                                                    font-size: 12px;
                                                    line-height: 22px;
                                                    text-transform: uppercase;
                                                    font-weight: 600;
                                                    color: rgba($black, .5);
                                                }
                                            }
                                        }

                                        .booking-status {
                                            position: relative;
                                            width: 20%;
                                            @include flex(wrap, column, center, flex-start);
                                            border-left: 1px solid rgba(49,53,59,0.12);
                                            padding-left: 8px;

                                            span {
                                                position: relative;
                                                width: 100%;
                                                display: block;
                                                font-size: 12px;
                                                line-height: 22px;
                                                text-transform: uppercase;
                                                font-weight: 600;
                                                color: rgba($black, .5);
                                                text-align: center;
                                            }

                                            p {
                                                margin: 0 0;
                                                text-align: center;
                                                display: block;
                                                width: 100%;

                                                &.status {
                                                    height: 32px;
                                                    line-height: 32px;
                                                    @include border-radius(3px);
                                                    color: $white;
                                                    font-weight: 600;
                                                    letter-spacing: 0;
                                                    margin-top: 24px;
                                                    font-size: 14px;

                                                    &.approve {
                                                        background: $green;
                                                    }

                                                    &.danger {
                                                        background: $danger;
                                                    }

                                                    &.pending {
                                                        background: $orange;
                                                    }
                                                }
                                            }

                                            a {
                                                display: inline-block;
                                                width: auto;
                                                color: rgba($primary, 1);
                                                font-size: 12px;
                                                line-height: 24px;
                                                font-weight: 600;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }

                                    &:last-of-type {
                                        border-bottom: 0;
                                    }
                                }
                            }

                            .pagination {
                                position: relative;
                                width: 100%;
                                margin-top: 24px;

                                ul {
                                    padding: 0 0;
                                    margin: 0 0;
                                    text-align: center;
                                    width: 100%;
                                    display: block;

                                    li {
                                        width: 24px;
                                        height: 24px;
                                        @include border-radius(3px);
                                        border: 1px solid rgba(49,53,59,0.12);
                                        overflow: hidden;
                                        margin: 0 2px 0 0;
                                        display: inline-block;

                                        a {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            color: $black;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 24px;
                                            text-transform: uppercase;
                                            padding: 0 0;
                                            margin: 0 0;
                                        }

                                        &.active {
                                            background: rgba($primary, 1);
                                            border-color: rgba($primary, 1);

                                            a {
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //loader
        .loader {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 2555, 255,.8);
            display: none;
            z-index: 105;
    
            .lds-ring {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 64px;
                height: 64px;
                margin-top: -32px;
                margin-left: -32px;
    
                .lsd-rotate {
                    box-sizing: border-box;
                    display: block;
                    position: absolute;
                    width: 51px;
                    height: 51px;
                    margin: 6px;
                    border: 6px solid $blue;
                    border-radius: 50%;
                    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    border-color: $blue transparent transparent transparent;
            
                    &:nth-child(1) {
                        animation-delay: -0.45s;
                    }
                
                    &:nth-child(2) {
                        animation-delay: -0.3s;
                    }
                
                    &:nth-child(3) {
                        animation-delay: -0.15s;
                    }
            
                    @keyframes lds-ring {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
        }

        // title with span small
        .title-with-small-span {
            position: relative;
            width: 100%;
            text-align: center;
            margin-bottom: 48px;

            span {
                display: inline-block;
                width: auto;
                height: auto;
                color: $primary;
                font-size: 12px;
                line-height: 22px;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-bottom: 0px;
                font-family: $roboto;
                font-weight: 500;
            }

            h2 {
                position: relative;
                width: 100%;
                text-align: center;
                color: $black;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

        /*  
            ============================================================= 
                MAIN TITLE

                main title with image
            ============================================================= 
        */
        .main-title {
            position: relative;
            width: 100%;
            margin-bottom: 48px;

            .bg {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;
                background-size: cover;
                background-position: center;
            }
            
            .w-main-title {
                position: relative;
                width: 100%;
                min-height: 350px;
                @include flex(wrap, column, flex-start, center);
                background-color: rgba($primary, .2);;

                .container {

                    //title
                    h1, h2 {
                        margin-bottom: 0px;
                        color: $white;
                        width: 100%;
                        text-align: left;
                    }

                    //breadcumb
                    .breadcumb {
                        position: relative;
                        width: 100%;
                        padding:0 0;

                        ul {
                            width: 100%;
                            padding: 0 0;
                            margin: 0 0;
                            list-style: none;
                            @include float-pos;

                            li {
                                float: left;
                                width: auto;
                                margin: 0 0;
                                padding: 0 0;

                                a {
                                    display: block;
                                    width: 100%;
                                    height: auto;
                                    color: $white;
                                    text-transform: uppercase;
                                    font-size: 14px;
                                    line-height: 24px;
                                    letter-spacing: 0px;
                                    font-family: $roboto;
                                    font-weight: 500;
                                    letter-spacing: .5px;
                                    position: relative;
                                    padding: 0 24px 0 0;

                                    &:after {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 24px;
                                        height: 24px;
                                        font-size: 14px;
                                        line-height: 24px;
                                        text-align: center;
                                        color: $white;
                                        content: "/";
                                    }
                                }

                                &:last-of-type {
                                    margin-right: 0;

                                    a {
                                        color: $primary;
                                        padding-right: 0;

                                        &:after {display: none;}
                                    }
                                }
                            }
                        }
                    }
                }

                &.center {
                    @include flex($align: center, $justify: flex-start);

                    .container {

                        h2 {text-align: center;}

                        .breadcumb {

                            ul {
                                text-align: center;
                                
                                li {
                                    display: inline-block;
                                    float: none;
                                }
                            }
                        }
                    }
                }
            }

            &.with-bg {

                .w-main-title {
                    background-color: #f7f4eb;
                    min-height: 200px;
                    @include flex(wrap, column, center, center);

                    .container {
                        
                        h2 {
                            color: $black;
                            text-align: center;
                            z-index: 2;
                        }
                    }

                    span {
                        position: absolute;
                        bottom: 16px;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        color: rgba(0, 0, 0, .04);
                        font-family: $libre;
                        font-size: 100px;
                        line-height: .8;
                        letter-spacing: 0;
                        text-transform: uppercase;
                        white-space: nowrap;
                        z-index: 1;
                    }
                }
            }
        }

        // padding global
        .w-padding {
            padding-top: 56px;
            padding-bottom: 56px;
        }

        // title page 
        .s-title {
            position: relative;
            width: 100%;

            .w-title {
                position: relative;
                width: 100%;

                .container {

                    h1, 
                    h2 {
                        position: relative;
                        width: 100%;
                        text-align: left;
                        color: $black;
                        margin: 0 0;
                    }

                    p {
                        position: relative;
                        width: 100%;
                        text-align: left;
                        margin: 8px 0 0 0;
                        color: rgba($black, .8);
                    }
                }
            }
        }

        // search form 
        .best-deal {
            position: relative;
            width: 100%;
            padding: 64px 0;
            background-color: $white;

            .container {

                .wrapper-best-deal {
                    position: relative;
                    width: 100%;

                    h2 {
                        text-align: left;
                        margin-bottom: 4px;
                    }

                    p {
                        font-size: 18px;
                        line-height: 28px;
                        text-align: left;
                        margin: 0 0 32px 0;
                        color: rgba($black, .5);
                    }

                    .form,
                    form {
                        position: relative;
                        width: 100%;

                        .row {
                            margin-left: -4px;
                            margin-right: -4px;

                            .box {
                                width: 15%;
                                padding: 0 4px;

                                input[type="text"] {
                                    height: 56px;
                                    padding: 16px 16px 16px 48px;
                                    border: 1px solid #dee0e2;
                                    background: $white;
                                    border-radius: 5px;	
                                    color: #80868e;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-family: $barlow;
                                    font-weight: 300;
                                    letter-spacing: 1px;

                                    &::-webkit-input-placeholder {
                                        padding: 16px 0px;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: rgba($black, .5);
                                        font-family: $barlow;
                                        font-weight: 300;
                                        letter-spacing: 1px;
                                    }
                                
                                    &:-moz-placeholder {
                                        padding: 16px 0px;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: rgba($black, .5);
                                        font-family: $barlow;
                                        font-weight: 300;
                                        letter-spacing: 1px;
                                    }
                                
                                    &::-moz-placeholder {
                                        padding: 16px 0px;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: rgba($black, .5);
                                        font-family: $barlow;
                                        font-weight: 300;
                                        letter-spacing: 1px;
                                    }
                                
                                    &:-ms-input-placeholder {
                                        padding: 16px 0px;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: rgba($black, .5);
                                        font-family: $barlow;
                                        font-weight: 300;
                                        letter-spacing: 1px;
                                    }
                                }

                                .btn {
                                    height: 56px;
                                    margin-bottom: 0;
                                    width: 100%;

                                    span {
                                        font-size: 16px;
                                        text-transform: uppercase;

                                    }
                                }

                                &.has-icon {
                                    
                                    input[type="text"] {
                                        padding-left: 48px;
                                    }

                                    i.icon {
                                        position: absolute;
                                        top: 8px;
                                        left: 4px;
            
                                        &:before {
                                            color: $primary;
                                        }
                                    }

                                    &:after {display: none;}
                                }

                                &:first-of-type {
                                    width: 40%;
                                }
                            }
                        }
                    }
                }
            }

            &.abs-pos {
                padding: 0 0;
                z-index: 2;
                background-color: transparent;

                .container {
                    min-height: 69px;

                    .wrapper-best-deal {
                        position: absolute;
                        left: 0;
                        top:  -69px;
                        width: 100%;
                        background-color: $white;
                        padding: 8px;

                        h2 {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }

        .form-search {
            position: relative;
            width: 100%;
            background-color: $white;

            .form,
            form {
                position: relative;
                width: 100%;

                .row {
                    margin-left: -4px;
                    margin-right: -4px;

                    .box {
                        width: 25%;
                        padding: 0 4px;

                        input[type="text"] {
                            height: 56px;
                            padding: 16px 16px 16px 48px;
                            border: 1px solid #dee0e2;
                            background: $white;
                            border-radius: 5px;	
                            color: #80868e;
                            font-size: 16px;
                            line-height: 24px;
                            font-family: $barlow;
                            font-weight: 300;
                            letter-spacing: 1px;

                            &::-webkit-input-placeholder {
                                padding: 16px 0px;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba($black, .5);
                                font-family: $barlow;
                                font-weight: 300;
                                letter-spacing: 1px;
                            }
                        
                            &:-moz-placeholder {
                                padding: 16px 0px;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba($black, .5);
                                font-family: $barlow;
                                font-weight: 300;
                                letter-spacing: 1px;
                            }
                        
                            &::-moz-placeholder {
                                padding: 16px 0px;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba($black, .5);
                                font-family: $barlow;
                                font-weight: 300;
                                letter-spacing: 1px;
                            }
                        
                            &:-ms-input-placeholder {
                                padding: 16px 0px;
                                font-size: 16px;
                                line-height: 24px;
                                color: rgba($black, .5);
                                font-family: $barlow;
                                font-weight: 300;
                                letter-spacing: 1px;
                            }
                        }

                        .btn {
                            height: 56px;
                            margin-bottom: 0;
                            width: 100%;

                            span {
                                font-size: 16px;
                                text-transform: uppercase;

                            }
                        }

                        &.has-icon {
                            
                            input[type="text"] {
                                padding-left: 48px;
                            }

                            i.icon {
                                position: absolute;
                                top: 8px;
                                left: 4px;

                                &:before {
                                    color: $primary;
                                }
                            }

                            &:after {display: none;}
                        }
                    }
                }
            }
        }

        //float 
        .float-left {
            float: left;
        }
            
	}

    //un home
    &.un-home {
        padding-top: 0;
    }

    //set padding wrapper when scrool fixed nav
    &.fixed {
        padding-top: 74px;
    }

    @include breakpoint('<=xlarge') {

        #main {

            //pages
            &.p-pages {

                .s-pages {

                    .container {
        
                        .side-nav {
        
                            .w-side {
        
                                .side-search {
        
                                    .form,
                                    form {
        
                                        .row {
        
                                            .box {
        
                                                input[type="text"] {
                                                    height: 48px;
                                                    padding: 12px 12px 12px 48px;
                                                    font-size: 15px;
        
                                                    &::-webkit-input-placeholder {
                                                        padding: 12px 0px;
                                                        font-size: 15px;
                                                        line-height: 24px;
                                                    }
                                                
                                                    &:-moz-placeholder {
                                                        padding: 12px 0px;
                                                        font-size: 15px;
                                                        line-height: 24px;
                                                    }
                                                
                                                    &::-moz-placeholder {
                                                        padding: 12px 0px;
                                                        font-size: 15px;
                                                        line-height: 24px;
                                                    }
                                                
                                                    &:-ms-input-placeholder {
                                                        padding: 12px 0px;
                                                        font-size: 15px;
                                                        line-height: 24px;
                                                    }
                                                }
        
                                                .select-box {
                                            
                                                    .selectric-wrapper {
                                                        
                                                        .selectric {    
                                                            height: 48px;
                                                            padding: 12px 48px 12px 16px;
                                            
                                                            .label {
                                                                font-size: 14px;
                                                                height: auto;
                                                            }
                                            
                                                            .button {
                                                                width: 48px;
                                                                height: 48px;
                                                                padding: 12px;
                                                                top: 0px;
                                            
                                                                &:after {
                                                                    font-size: 14px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
        
                                                .btn.primary {
                                                    height: 48px;
        
                                                    span {
                                                        font-size: 15px;
        
                                                    }
                                                }
        
                                                &.has-icon {
        
                                                    i.icon {
                                                        top: 0px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                //register or login
                .register {

                    .block-content {

                        .right-content {
                            width: 50%;

                            .block-form {
                                padding: 32px 32px;

                                .c-register {
                                    width: 100%;

                                    .social-register {

                                        a.btn {
                                            width: calc(50% - 8px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        &.scroll-fixed {
            padding-top: 48px;	
        }

        // padding global
        .w-padding {
            padding-top: 48px;
            padding-bottom: 48px;
        }

        // search form 
	    .best-deal {
            padding: 56px 0;

			.container {

				.wrapper-best-deal {

					p {
						font-size: 16px;
						line-height: 26px;
					}

					.form,
					form {

						.row {

							.box {
								width: 25%;

								input[type="text"] {
									height: 48px;
									padding: 12px 12px 12px 48px;
									font-size: 15px;

									&::-webkit-input-placeholder {
										padding: 12px 0px;
										font-size: 15px;
										line-height: 24px;
									}
								
									&:-moz-placeholder {
										padding: 12px 0px;
										font-size: 15px;
										line-height: 24px;
									}
								
									&::-moz-placeholder {
										padding: 12px 0px;
										font-size: 15px;
										line-height: 24px;
									}
								
									&:-ms-input-placeholder {
										padding: 12px 0px;
										font-size: 15px;
										line-height: 24px;
									}
								}

								.btn {
									height: 48px;

									span {
										font-size: 15px;

									}
								}

								&.has-icon {

									i.icon {
										top: 0px;
									}
								}
							}
						}
					}
				}
			} 

            &.abs-pos {

                .container {
                    min-height: 69px;
                    width: 90%;

                    .wrapper-best-deal {
                        position: absolute;
                        left: 0;
                        top:  -69px;
                        width: 100%;
                        background-color: $white;
                        padding: 8px;

                        h2 {
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }

        .form-search {

            .form,
			form {

				.row {

					.box {
						width: 25%;

						input[type="text"] {
							height: 48px;
							padding: 12px 12px 12px 48px;
							font-size: 15px;

							&::-webkit-input-placeholder {
								padding: 12px 0px;
								font-size: 15px;
								line-height: 24px;
							}
						
							&:-moz-placeholder {
								padding: 12px 0px;
								font-size: 15px;
								line-height: 24px;
							}
						
							&::-moz-placeholder {
								padding: 12px 0px;
								font-size: 15px;
								line-height: 24px;
							}
						
							&:-ms-input-placeholder {
								padding: 12px 0px;
								font-size: 15px;
								line-height: 24px;
							}
						}

						.btn {
							height: 48px;

							span {
								font-size: 15px;

							}
						}

						&.has-icon {

							i.icon {
								top: 0px;
							}
						}
					}
				}
			}
        } 
    }

    @include breakpoint('<=large') {

        #main {

            &.p-pages {

                .s-pages {

                    .container {

                        //villa list by detail
                        .list-villa {

                            .row {

                                .villa {
                                    width: calc(100% /2);
                                }
                            }
                        }
                    }
                }

                //register or login
                .register {

                    .block-content {

                        .right-content {

                            .block-form {
                                padding: 24px;

                                .c-register {

                                    .social-register {

                                        a.btn {
                                            width: calc(50% - 8px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .container {
    
                    //page with side
                    .page-with-side {
                        padding-top: 24px;
    
                        .left-side {
                            width: 300px;
    
                            .search {
                                padding: 8px;
                                
                                .w-search {
                                    
                                    h4 {
                                        margin-bottom: 16px;
                                    }
    
                                    form,
                                    .form {
    
                                        .row {
    
                                            .box {
    
                                                .btn {
                                                    width: 100%;
                                                }
                                                
                                                &.has-icon {
                                                    input[type="text"],
                                                    input[type="password"],
                                                    input[type="email"],
                                                    input[type="tel"],
                                                    input[type="number"] {
                                                        padding-left: 48px;
                                                    }
    
                                                    i.icon {
                                                        top: 28px;
                                                    }
                                                }
    
                                                &.half {
                                                    width: 50%;
                                                }
    
                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            }    
                                        }
                                    }
                                }
                            }
    
                            .promo {
                                margin-top: 24px;
                            }
                        }
    
                        .right-side {
                            width: calc(100% - 300px);
                            padding-left: 16px;
    
                            h4 {
                                margin-bottom: 24px;
                            }
    
                            .c-message {
                                padding: 16px;
                                margin-bottom: 24px;
                            }
    
                            .c-info {
                                margin-bottom: 24px;
    
                                .w-info {

                                    .row {
                                        
                                        .info {
                                            width: 33.33%;
                                            
                                            .the-info {
                                                padding: 16px 8px;
    
                                                h5 {
                                                    margin-top: 16px;
                                                    margin-bottom: 8px;
                                                }
                                            }
                                        }
                                    }
    
                                }
                            }
    
                            .c-dashboard {
                                margin-bottom: 24px;
                                
                                .c-title {
                                    padding: 16px;
                                }
    
                                .w-dashboard {
                                    padding: 16px 14px;
    
                                    .form,
                                    form {
                                        position: relative;
                                        width: 100%;
    
                                        .row {
    
                                            .box {
                                                padding-left: 200px;
    
                                                label {
                                                    top: 12px;
                                                    width: calc(200px - 16px);
                                                }
    
                                                .select-box {
                                                    width: 100%;
                                                    margin-bottom: 16px;
                                                    padding: 0 0;

                                                    &:last-of-type {margin-bottom: 0;}
                                                }
    
                                                .btn {
                                                    width: 200px;
                                                }
                                            }
                                        }
                                    }
    
                                    .box-question {
                                        position: relative;
                                        width: 100%;
                                        @include vendor('transition', ('all 0.3s ease-in-out'));
    
                                        .question {
                                            position: relative;
                                            width: 100%;
                                            background: $white;
                                            border-bottom: 1px solid $gray-light;
                                            border-left: 1px solid $gray-light;
                                            border-right: 1px solid $gray-light;
                                            padding: 8px;
                                            font-weight: 600;
                                            letter-spacing: 0;
                                            @include vendor('transition', ('all 0.3s ease-in-out'));
                                            padding-right: 24px;
    
                                        }
    
                                        .answer {
                                            display: none;
                                            width: 100%;
                                            height: 0;
                                            position: relative;
                                            padding: 16px;
                                            @include vendor('transition', ('all 0.5s ease-in-out'));
                                            border: 1px solid $gray-light;
    
                                            p {
                                                margin: 0 0 16px 0;
                                                position: relative;
                                                text-align: left;
                                                
                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
    
                                        &:hover {
                                            cursor: pointer;
                                        }
    
                                        &:first-of-type {
    
                                            .question {
                                                border-top: 1px solid $gray-light;
                                            }
                                        }
    
                                        //active
                                        &.active {
    
                                            .question {
                                                color: $primary;
                                                border-bottom-color: $white;
                                            }
    
                                            .answer {
                                                display: block;
                                                height: auto;
                                            }
                                        }
    
                                    }
                                }
    
                                &:first-of-type {
                                    margin-top: 0;
                                }
                            }
                        
                            .banner-promo {
                                margin-bottom: 24px;
    
                                .row {
                                    margin: 0 -16px;
    
                                    .c-banner-promo {
                                        width: 100%;
                                    }
                                }
                            }

                            .c-booking {

                                .search-transaction {
                                    margin-bottom: 32px;

                                    .w-search-transaction {
                                        
                                        .form,
                                        form {

                                            .row {

                                                .box {
                                                    width: 33.33%;
                                                }
                                            }
                                        }
                                    }
                                }

                                .row {

                                    .booking {

                                        .w-booking {
                                            
                                            .booking-personal {
                                                width: 20%;
                                            }

                                            .booking-info {
                                                width: 60%;
                                                .c-booking-info {
                                                    width: calc(100% / 2);
                                                }
                                            }

                                            .booking-status {
                                                width: 20%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .best-deal {

                &.abs-pos {
    
                    .container {
                        min-height: 67px;
                        width: calc(100% - 48px);
    
                        .wrapper-best-deal {
                            top:  -67px;
                        }
                    }
                }
            } 
            
            .main-title {
    
                &.with-bg {
    
                    .w-main-title {
    
                        span {
                            bottom: 32px;
                            font-size: 80px;
                        }
                    }
                }
            }
        }
        
        &.fixed {
            padding-top: 58px;
        }
    }

    @include breakpoint('<=medium') {

        //main
        #main {

            //pages
            &.p-pages {
                
                .s-pages {

                    .container {
        
                        .side-nav {
                            width: 100%;
                            padding-right: 0;
                            margin-bottom: 32px;
                            float: none;

                            .w-side {
        
                                .side-search {
                                    padding: 8px;
        
                                    .form,
                                    form {
        
                                        .row {
        
                                            .box {
                                                width: calc(100% / 2);
                                                margin-bottom: 16px;
        
                                                input[type="text"] {
                                                    height: 40px;
                                                    padding: 4px 8px 4px 40px;
                                                    font-size: 14px;
        
                                                    &::-webkit-input-placeholder {
                                                        padding: 4px 0px;
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                    }
                                                
                                                    &:-moz-placeholder {
                                                        padding: 4px 0px;
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                    }
                                                
                                                    &::-moz-placeholder {
                                                        padding: 4px 0px;
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                    }
                                                
                                                    &:-ms-input-placeholder {
                                                        padding: 4px 0px;
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                    }
                                                }
        
                                                .select-box {
                                            
                                                    .selectric-wrapper {
                                                        
                                                        .selectric {    
                                                            height: 40px;
                                                            padding: 8px 48px 8px 16px;
                                            
                                                            .button {
                                                                width: 40px;
                                                                height: 40px;
                                                                padding: 8px;
                                                            }
                                                        }
                                                    }
                                                }
        
                                                .btn.primary {
                                                    height: 40px;
                                                    padding-top: 4px;
                                                    padding-bottom: 4px;
        
                                                    span {
                                                        font-size: 14px;
        
                                                    }
                                                }
        
                                                &.has-icon {
        
                                                    i.icon {
                                                        width: 40px;
                                                        height: 40px;
                                                        padding: 4px;
                                                        top: 0px;
                                                    }
                                                }
        
                                                &:first-of-type {
                                                    width: 100%;
                                                    margin-bottom: 16px;
                                                }
        
                                                &:last-of-type {
                                                    width: 100%;
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                    }
                                }
        
                                .side-filter {
                                    display: none;
                                }
        
                                .filter {
                                    display: block;
                        
                                    .w-filter {
                            
                                        .tabs-filter {
                            
                                            ul {
                                                padding-right: 110px;

                                                li {
                                                    float: none;
                                                    display: inline-block;
                                                    margin: 0 4px 0 0;

                                                    a {
                                                        padding: 4px 8px;
                                                        font-size: 14px;
            
                                                        span {
                                                            display: block;
                                                        }
                                                    }
                            
                                                    &:hover,
                                                    &.ui-tabs-active,
                                                    &.ui-state-active {
                            
                                                        a {
        
                                                            i.icon {
        
                                                                &:before {
                                                                    color: $white;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:last-of-type {margin-right: 110px;}
                                                }

                                                &:after {
                                                    width: 110px;
                                                }
                                            }
                            
                                            .c-filter {
                            
                                                .content-filter {
                            
                                                    .box {
                                                        width: calc(100% / 2);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
        
                                .side-promo {
                                    display: none;
                                }
                            }
                        }
        
                        .side-content {
                            width: 100%;
                            padding-left: 0;
                            float: none;
                        }
                    }
                }
        
                .register {
            
                    .container {
            
                        .w-register {
            
                            .c-register {
                                width: 100%;
                                display: block;
                                padding-right: 0;
                                margin-bottom: 32px;
                            }
            
                            .form-register {
                                width: 500px;
                                display: block;
                                margin: 0 auto;
                                padding-left: 0;
            
                                p.info {
                                    margin: 0 0 24px 0;
                                    text-align: center;
                                }
        
                                form {
            
                                    .row {
            
                                        .box {
        
                                            &.center {
                                                text-align: center;
                                            }
            
                                            .btn {
                                                width: auto;
                                            }
                                        }
                                    }
            
                                }
            
                                .social-register {
            
                                    a.btn {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }

                    .block-content {
                        display: block;
                        height: auto;
                        min-height: 100%;

                        .left-content {display: none;}
                        
                        .right-content {
                            width: 500px;
                            display: block;
                            margin: 0 auto;

                            .block-form {
                                padding: 48px 16px;

                                .c-register {

                                    .social-register {

                                        a.btn {
                                            width: calc(50% - 8px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .container {
    
                    //page with side
                    .page-with-side {
                        padding-top: 24px;
    
                        .left-side {
                            display: none;
    
                            .search {
                                padding: 8px;
                                
                                .w-search {
                                    
                                    h4 {
                                        margin-bottom: 16px;
                                    }
    
                                    form,
                                    .form {
    
                                        .row {
    
                                            .box {
    
                                                .btn {
                                                    width: 100%;
                                                }
                                                
                                                &.has-icon {
                                                    input[type="text"],
                                                    input[type="password"],
                                                    input[type="email"],
                                                    input[type="tel"],
                                                    input[type="number"] {
                                                        padding-left: 48px;
                                                    }
    
                                                    i.icon {
                                                        top: 28px;
                                                    }
                                                }
    
                                                &.half {
                                                    width: 50%;
                                                }
    
                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            }    
                                        }
                                    }
                                }
                            }
    
                            .promo {
                                margin-top: 24px;
                            }
                        }
    
                        .right-side {
                            width: 100%;
                            padding-left: 0;
    
                            h4 {
                                margin-bottom: 24px;
                            }
    
                            .c-message {
                                padding: 16px;
                                margin-bottom: 24px;
                            }
    
                            .c-info {
                                margin-bottom: 24px;
    
                                .w-info {

                                    .row {
                                        
                                        .info {
                                            width: 100%;
                                            
                                            .the-info {
                                                padding: 24px;
    
                                                h5 {
                                                    margin-top: 16px;
                                                    margin-bottom: 8px;
                                                }
                                            }
                                        }
                                    }
    
                                }
                            }
    
                            .c-dashboard {
                                margin-bottom: 24px;
                                
                                .c-title {
                                    padding: 16px;
                                }
    
                                .w-dashboard {
                                    padding: 16px 14px;
    
                                    .form,
                                    form {
                                        position: relative;
                                        width: 100%;
    
                                        .row {
    
                                            .box {
                                                padding-left: 200px;
    
                                                label {
                                                    top: 12px;
                                                    width: calc(200px - 16px);
                                                }
    
                                                .select-box {
                                                    width: 100%;
                                                    margin-bottom: 16px;
                                                    padding: 0 0;

                                                    &:last-of-type {margin-bottom: 0;}
                                                }
    
                                                .btn {
                                                    width: 200px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //title with span small
            .title-with-small-span {
                margin-bottom: 32px;
            }

            // padding global
            .w-padding {
                padding-top: 32px;
                padding-bottom: 32px;
            }
        
            // search form 
            .best-deal {
                padding: 48px 0;
			
                .container {

                    .wrapper-best-deal {

                        p {
                            font-size: 15px;
                            margin-bottom: 24px;
                        }

                        .form,
                        form {

                            .row {

                                .box {
                                    width: 25%;
                                    margin-bottom: 16px;

                                    input[type="text"] {
                                        height: 40px;
                                        padding: 4px 8px 4px 40px;
                                        font-size: 14px;

                                        &::-webkit-input-placeholder {
                                            padding: 4px 0px;
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    
                                        &:-moz-placeholder {
                                            padding: 4px 0px;
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    
                                        &::-moz-placeholder {
                                            padding: 4px 0px;
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    
                                        &:-ms-input-placeholder {
                                            padding: 4px 0px;
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    }

                                    .btn {
                                        height: 40px;
                                        padding-top: 4px;
                                        padding-bottom: 4px;

                                        span {
                                            font-size: 14px;

                                        }
                                    }

                                    &.has-icon {

                                        i.icon {
                                            width: 40px;
                                            height: 40px;
                                            padding: 8px;
                                            top: 0px;
                                        }
                                    }
                                    
                                    &:first-of-type {
                                        width: 100%;
                                    }

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                } 
                
                &.abs-pos {
                    padding: 48px 0;
    
                    .container {
                        min-height: 100%;
                        width: 100%;
                        
                        
                        .wrapper-best-deal {
                            position: relative;
                            top: 0;
                        }
                    }
                }
            }

            .form-search {

                .form,
                form {

                    .row {

                        .box {
                            width: 100%;
                            margin-bottom: 16px;

                            input[type="text"] {
                                height: 40px;
                                padding: 4px 8px 4px 40px;
                                font-size: 14px;

                                &::-webkit-input-placeholder {
                                    padding: 4px 0px;
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            
                                &:-moz-placeholder {
                                    padding: 4px 0px;
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            
                                &::-moz-placeholder {
                                    padding: 4px 0px;
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            
                                &:-ms-input-placeholder {
                                    padding: 4px 0px;
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            }

                            .btn {
                                height: 40px;
                                padding-top: 4px;
                                padding-bottom: 4px;

                                span {
                                    font-size: 14px;

                                }
                            }

                            &.has-icon {

                                i.icon {
                                    width: 40px;
                                    height: 40px;
                                    padding: 8px;
                                    top: 0px;
                                }
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            .float-left {
                float: none;
            }
            
            .main-title {
    
                &.with-bg {
    
                    .w-main-title {
    
                        span {
                            bottom: 40px;
                            font-size: 70px;
                        }
                    }
                }
            }
        }
        
        &.padd-header {
            padding-top: 64px;
        }

        &.fixed {
            padding-top: 72px;
        }
    }

    @include breakpoint('<=small') {
        
        //main
        #main {

            .main-title {
                margin-bottom: 24px;

                .w-main-title {
                    min-height: 300px;

                    .container {

                        h2 {margin-bottom: 0;}
                        .breadcumb {padding: 0 0;}
                    }
                }
    
                &.with-bg {
    
                    .w-main-title {
    
                        span {
                            bottom: 60px;
                            font-size: 50px;
                        }
                    }
                }
            }

            &.p-pages {
        
                .register {
            
                    .container {
            
                        .w-register {
            
                            .c-register {
                                width: 100%;
                                display: block;
                                padding-right: 0;
                                margin-bottom: 32px;
                            }
            
                            .form-register {
                                width: 100%;
                                display: block;
                                margin: 0 auto;
                                padding-left: 0;
            
                                p.info {
                                    margin: 0 0 24px 0;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .block-content {

                        .left-content {display: none;}
                        
                        .right-content {
                            width: 100%;

                            .block-form {
                                padding: 48px 16px;

                                .c-register {

                                    .social-register {

                                        a.btn {
                                            width: 100%;
                                            
                                            &.facebook {margin-right: 0; margin-bottom: 24px;}
                                            &.twitter {margin-left: 0;}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .container {
    
                    //page with side
                    .page-with-side {
                        padding-top: 24px;
    
                        .left-side {
                            display: none;
    
                            .search {
                                padding: 8px;
                                
                                .w-search {
                                    
                                    h4 {
                                        margin-bottom: 16px;
                                    }
    
                                    form,
                                    .form {
    
                                        .row {
    
                                            .box {
    
                                                .btn {
                                                    width: 100%;
                                                }
                                                
                                                &.has-icon {
                                                    input[type="text"],
                                                    input[type="password"],
                                                    input[type="email"],
                                                    input[type="tel"],
                                                    input[type="number"] {
                                                        padding-left: 48px;
                                                    }
    
                                                    i.icon {
                                                        top: 28px;
                                                    }
                                                }
    
                                                &.half {
                                                    width: 50%;
                                                }
    
                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            }    
                                        }
                                    }
                                }
                            }
    
                            .promo {
                                margin-top: 24px;
                            }
                        }
    
                        .right-side {
    
                            .c-dashboard {
                                margin-bottom: 24px;
                                
                                .c-title {
                                    padding: 16px;
                                }
    
                                .w-dashboard {
                                    padding: 16px 14px;
    
                                    .form,
                                    form {
                                        position: relative;
                                        width: 100%;
    
                                        .row {
    
                                            .box {
                                                padding-left: 0;
    
                                                label {
                                                    position: relative;
                                                    top: 0;
                                                    width: 100%;
                                                }
    
                                                .select-box {
                                                    width: 100%;
                                                    margin-bottom: 16px;
                                                    padding: 0 0;

                                                    &:last-of-type {margin-bottom: 0;}
                                                }
    
                                                .btn {
                                                    width: 200px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // padding global
            .w-padding {
                padding-top: 24px;
                padding-bottom: 24px;
            }

            
        
            // search form 
            .best-deal {
                padding: 32px 0;
			
                .container {

                    .wrapper-best-deal {

                        p {
                            font-size: 14px;
                            margin-bottom: 24px;
                        }

                        .form,
                        form {

                            .row {

                                .box {
                                    width: 33%;
                                    margin-bottom: 16px;
                                    
                                    &:first-of-type,
                                    &:last-of-type {
                                        width: 100%;
                                    }

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                } 
                
                &.abs-pos {
                    padding: 48px 0;
    
                    .container {
                        min-height: 100%;
                        width: 100%;
                        
                        
                        .wrapper-best-deal {
                            position: relative;
                            top: 0;
                        }
                    }
                }
            }

            .form-search {

                .form,
                form {

                    .row {

                        .box {
                            width: 33.33%;
                            
                            &:first-of-type,
                            &:last-of-type {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &.fixed {
            padding-top: 56px;
        }
    }

    @include breakpoint('<=xsmall') {
        padding-top: 0;

        //main
        #main {
            &.p-pages {
                .s-pages {

                    .container {
                        .side-nav {
                            width: 100%;
        
                            .w-side {
        
                                .side-search {
        
                                    .form,
                                    form {
        
                                        .row {
        
                                            .box {
                                                width: 100%;
                                                margin-bottom: 16px;
        
                                                &:last-of-type {
                                                    margin-bottom: 0px;
                                                }
                                            }
                                        }
                                    }
                                }
        
                                .filter {
                                    margin-bottom: 0;
                            
                                    .w-filter {
                            
                                        .tabs-filter {
                            
                                            .c-filter {
                            
                                                .content-filter {
                            
                                                    .box {
                                                        width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
        
                        .side-content {
        
                            .w-side {
        
                                .list-area {
        
                                    .row {
        
                                        .area {
                                            width: 100%;
                                        }
                                    }
                                } 
                            }
                        }
                    }
                }

                .container {
    
                    //page with side
                    .page-with-side {
    
                        .right-side {

                            .c-booking {

                                .search-transaction {
                                    margin-bottom: 32px;

                                    .w-search-transaction {
                                        
                                        .form,
                                        form {

                                            .row {

                                                .box {
                                                    width: 33.33%;
                                                }
                                            }
                                        }
                                    }
                                }

                                .row {

                                    .booking {

                                        .w-booking {
                                            
                                            .booking-personal {
                                                width: 100%;
                                                padding-right: 0;
                                                border-right: 0;
                                                padding-bottom: 8px;
                                                border-bottom: 1px solid rgba(49,53,59,0.12);
                                            }

                                            .booking-info {
                                                width: 100%;
                                                padding: 8px 0;

                                                .c-booking-info {
                                                    width: calc(100% / 2);
                                                    margin: 4px 0;
                                                }
                                            }

                                            .booking-status {
                                                width: 100%;
                                                padding-left: 0;
                                                border-left: 0;
                                                padding-top: 8px;
                                                border-top: 1px solid rgba(49,53,59,0.12);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //title with span small
            .title-with-small-span {
                margin-bottom: 24px;

                h2 {margin-bottom: 0;}
            }

            // search form 
            .best-deal {
                padding: 24px 0;

                .container {

                    .wrapper-best-deal {

                        .form,
                        form {

                            .row {

                                .box {

                                   width: 100%;
                                }
                            }
                        }
                    }
                }
                
                &.abs-pos {
                    padding: 24px 0;
                }
            }

            .main-title {
    
                &.with-bg {
    
                    .w-main-title {
    
                        span {
                            bottom: 70px;
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
}

/*  
    ============================================================= 
        BODY LOCK
        
        css for turn off scroll when nav mobile is active
    ============================================================= 
*/

body.lock {
    @include position('fixed', $top: 0, $right: 0, $bottom: 0, $left: 0);
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
}

#wrapper.user-dashboard {

    &.fixed {
        padding-top: 112px;
    }

    @include breakpoint('<=medium') {
        padding-top: 65px;

        &.fixed {
            padding-top: 65px;
        }
    }
}