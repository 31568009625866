#wrapper {

    #main {

        .s-pages {

            .container {

                .list-area {
                    position: relative;
                    width: 100%;

                    .row {
                        margin: 0 -8px;

                        .area {
                            width: calc(100% / 3);
                            padding: 0 8px;
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }

        @include breakpoint('<=small') {

            .s-pages {

                .container {

                    .list-area {
    
                        .row {

                            .area {
                                width: calc(100% / 2);
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=xsmall') {

            .s-pages {

                .container {

                    .list-area {

                        .row {

                            .area {
                                width: calc(100% / 1);
                            }
                        }
                    }
                }
            }
        }
    }
}