#wrapper {

    #main {

        .s-pages.activities-detail {

            .container {

                .sc-activities-detail {
                    position: relative;
                    width: 100%;
                    float: left;

                    .heroes-activities {
                        position: relative;
                        width: 100%;

                        .swiper-container {

                            .swiper-wrapper {

                                .swiper-slide {

                                    .img-heroes {
                                        position: relative;
                                        width: 100%;
                                        padding-top: calc((1 / 2) * 100%);
                                        overflow: hidden;
                                        @include border-radius(5px);

                                        img {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }
                            }

                            .btn-prev,
                            .btn-next {
                                position: absolute;
                                top: 50%;
                                width: 56px;
                                height: 56px;
                                z-index: 2;
                                display: block;
                                background: rgba(255, 255, 255, .6);
                                @include border-radius(50%);
                                overflow: hidden;
                                @include vendor('transition', ('all 0.5s'));
                                @include vendor('transform', 'translate(0, -50%)');

                                i.icon {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                    padding: 12px;

                                    &:before {
                                        color: $primary;
                                        font-size: 32px;
                                        line-height: 32px;
                                        width: 32px;
                                        height: 32px;
                                    }
                                }

                                &:hover {
                                    cursor: pointer;
                                    background: $primary;

                                    i.icon {

                                        &:before {
                                            color: $white;
                                        }
                                    }
                                }
                            }

                            .btn-prev {left: 8px;}
                            .btn-next {right: 8px;}
                        }
                    }
                }

                h3 {
                    position: relative;
                    width: 100%;
                    margin-top: 16px;
                    text-align: left;
                }

                .side-nav {
                    float: right;
                    padding-right: 0;
                    padding-left: 16px;

                    .activities-book {
                        position: relative;
                        width: 100%;
                        margin-top: 16px;

                        .wrp-activities-book {
                            position: relative;
                            width: 100%;

                            .price {
                                position: relative;
                                width: 100%;
                                text-align: center;
                                background: $primary;
                                padding: 16px;
                                @include flex(wrap, column, center, center);
                                @include set-border-radius(3px, 0, 3px, 0);

                                span {
                                    color: $white;
                                    position: relative;
                                    display: inline-block;
                                    width: auto;
                                    font-size: 25px;
                                    line-height: 25px;

                                    i.icon {
                                        width: 24px;
                                        height: 24px;
                                        padding: 0 0;
                                    }

                                    &.p-discount {
                                        font-size: 16px;
                                        line-height: 24px;
                                        margin-bottom: 4px;

                                        &:after {
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            width: 100%;
                                            height: 1px;
                                            background: $white;
                                            content: '';
                                            display: block;
                                            @include vendor('transfomr', 'translate(0, -50%)');
                                        }

                                    }
                                }
                            }

                            .form-book {
                                position: relative;
                                width: 100%;
                                padding: 16px;
                                border: 1px solid $gray-light;

                                .form,
                                form {

                                    .box {

                                        .btn {
                                            margin-bottom: 0;

                                            span {font-weight: 600;}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .side-content {
                    float: left;
                    padding-left: 0;
                    padding-right: 16px;

                    h2 {
                        position: relative;
                        display: none;
                        width: 100%;
                        margin-bottom: 8px;
                    }
                    
                    .stars-reviews-activities {
                        position: relative;
                        width: 100%;
                        @include float-pos;
                        margin-top: 16px;

                        .top-stars {
                            float: left;
                            width: auto;
                            margin-right: 8px;
                            @include float-pos;

                            i.icon {
                                width: 24px;
                                height: 24px;
                                padding: 0 0;
                                float: left;
                                margin-right: 0px;

                                &:before {
                                    font-size: 16px;
                                    color: #ffd938;
                                }
                            }
                        }

                        .top-reviews {
                            float: left;
                            position: relative;

                            a {
                                display: block;
                                position: relative;
                                width: auto;
                                color: $primary;
                                font-weight: 600;
                            }
                        }
                    }

                    .activities-information {
                        position: relative;
                        width: 100%;
                        margin-top: 8px;

                        .row {
                            margin-left: -8px;
                            margin-right: -8px;

                            .c-activities-information {
                                padding: 0 8px;
                                margin-bottom: 16px;
                                width: 25%;

                                .wrp-activities-information {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    @include flex(wrap, row, center, flex-start);
                                    padding: 16px 8px;
                                    @include border-radius(3px);
                                    border: 1px solid $gray-light;
                                    padding-left: 32px;

                                    i.icon {
                                        position: absolute;
                                        top: 50%;
                                        left: 8px;
                                        @include vendor('transform', 'translate(0, -50%)');
                                        width: 24px;
                                        height: 24px;
                                        padding: 0 0;

                                        &:before {
                                            color: $primary;
                                        }
                                    }

                                    span {
                                        position: relative;
                                        font-weight: 600;
                                        margin-left: 8px;
                                    }
                                }
                            }
                        }
                    }

                    .activities-desc {
                        position: relative;
                        width: 100%;

                        h4 {
                            position: relative;
                            width: 100%;
                            text-align: left;
                            margin-bottom: 16px;
                        }

                        p {
                            margin: 0 0 16px 0;
                            position: relative;
                            width: 100%;
                        }
                    }

                    .activities-info {
                        position: relative;
                        width: 100%;
                        padding-top: 16px;
                        padding-left: 150px;
                        margin-bottom: 16px;

                        span {
                            position: absolute;
                            top: 16px;
                            left: 0;
                            width: calc(150px - 16px);
                            height: auto;
                            font-weight: 600;
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            padding: 0 0;
                            margin: 0 0;

                            li {
                                position: relative;
                                width: 100%;
                                margin: 0 0 8px 0;
                                list-style: none;

                                &.yes,
                                &.no {
                                    padding-left: 24px;

                                    &:before {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 24px;
                                        height: 24px;
                                        display: block;
                                        font-family: $ico;
                                        font-size: 14px;
                                        line-height: 24px;
                                        font-weight: 600;
                                        text-align: center;
                                        content: '';
                                    }
                                }

                                &.yes {

                                    &:before {
                                        content: '\E816';
                                        color: $green;
                                        font-size: 12px;
                                    }
                                }

                                &.no {

                                    &:before {
                                        content: '\E815';
                                        color: $primary;
                                    }
                                }
                            }
                        }

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: $gray-light;
                            content: '';
                            display: block;
                        }

                        &:after {
                            position: absolute;
                            top: 15px;
                            left: 138px;
                            content: ':';
                            font-size: 14px;
                            line-height: 24px;
                            font-weight: 600;
                            color: $black;
                            display: block;
                        }
                    }

                    .activities-object {
                        position: relative;
                        width: 100%;
                        margin-top: 16px;
                        padding-top: 32px;

                        h4 {
                            position: relative;
                            width: 100%;
                            text-align: left;
                            margin-bottom: 16px;
                        }

                        .c-activities-object {
                            position: relative;
                            width: 100%;
                            margin-bottom: 16px;

                            .wrp-activities-object {
                                position: relative;
                                width: 100%;
                                padding: 8px;
                                @include border-radius(3px);
                                border: 1px solid $gray-light;
                                @include vendor('transition', ('all .3s ease'));

                                p.info {
                                    position: relative;
                                    width: 100%;
                                    text-align: left;
                                    font-weight: 600;
                                    display: block;
                                    margin: 0 0;
                                    padding-right: 24px;

                                    &:after {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        width: 24px;
                                        height: 24px;
                                        font-family: $ico;
                                        font-size: 14px;
                                        line-height: 24px;
                                        text-align: center;
                                        color: $black;
                                        font-weight: 600;
                                        content: '\F107';
                                        @include vendor('transition', ('all .3s ease-in-out'));
                                    }
                                }

                                .object-info {
                                    position: relative;
                                    width: 100%;
                                    max-height: 0px;
                                    margin-top: 8px;
                                    padding-left: 96px;
                                    display: none;
                                    opacity: 0;
                                    @include vendor('transition', ('all .5s ease'));
                                    padding-top: 16px;

                                    .thumbs {
                                        position: absolute;
                                        top: 16px;
                                        left: 0;
                                        width: 80px;
                                        height: 80px;
                                        display: block;
                                        @include border-radius(3px);
                                        overflow: hidden;
                                        @include vendor('transition', ('all .3s ease'));

                                        img {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }
                                    }

                                    .info {
                                        position: relative;
                                        width: 100%;
                                        @include vendor('transition', ('all .3s ease'));

                                        p {
                                            position: relative;
                                            width: 100%;
                                            margin: 0 0 8px 0;

                                            &:last-of-type {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }

                                    &:before {
                                        position: absolute;
                                        top: 0px;
                                        left: 0;
                                        width: 100%;
                                        height: 1px;
                                        background: $gray-light;
                                        content: '';
                                        display: block;
                                    }
                                }

                                &.active {

                                    p.info {

                                        &:after {
                                            @include vendor('transform', 'rotate(0.5turn)');
                                        }
                                    }

                                    .object-info {
                                        display: block;
                                        opacity: 1;
                                        max-height: 100%;
                                    }
                                }
                            }
                        }

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: $gray-light;
                            content: '';
                            display: block;
                        }
                    }

                    .faq {
                        position: relative;
                        margin-top: 32px;
                        width: 100%;

                        h4 {
                            position: relative;
                            width: 100%;
                            text-align: left;
                        }
                    }

                    .c-reviews {
                        position: relative;
                        margin-top: 32px;
                        width: 100%;

                        h4 {
                            position: relative;
                            width: 100%;
                            text-align: left;
                            margin-bottom: 24px;
                        }

                        .form-reviews {
                            position: relative;
                            width: 100%;
                            margin-top: 16px;
                            padding-top: 16px;

                            form,
                            .form {
                                position: relative;
                                width: 100%;

                                .row {

                                    .box {

                                        .rating {
                                            position: relative;
                                            width: 100%;
                                            text-align: left;

                                            i.icon {
                                                width: 24px;
                                                height: 24px;
                                                padding: 0 0;

                                                &:before {
                                                    color: rgba($black, .6);
                                                }
                                            }
                                        }

                                        &.cols-2 {
                                            width: 50%;
                                        }
                                    }
                                }
                            }

                            &:before {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                content: '';
                                background: $gray-light;
                                display: block;
                            }
                        }
                    }
                }
            }

            @include breakpoint('<=large') {

                .container {

                    .side-content {
                        

                        .activities-information {
    
                            .row {
    
                                .c-activities-information {
                                    width: 33.33%;
                                }
                            }
                        }
                    }
                }
            }

            @include breakpoint('<=medium') {

                .container {

                    .sc-activities-detail {
                        float: none;
                    }

                    .side-nav {
                        padding-left: 0;
                        margin-top: 32px;

                        .activities-book {
    
                            .wrp-activities-book {
    
                                .form-book {
    
                                    .form,
                                    form {
    
                                        .box {
                                            width: 50%;

                                            .btn {
                                                margin-bottom: 0;
    
                                                span {font-weight: 600;}
                                            }

                                            &.full-on-mobile {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .side-content {
                        padding-right: 0;

                        h2 {
                            display: block;
                        }

                        .activities-information {
    
                            .row {
    
                                .c-activities-information {
                                    width: 33.33%;
                                }
                            }
                        }
                    }
                }
            }

            @include breakpoint('<=xsmall') {

                .container {

                    .side-nav {

                        .activities-book {
    
                            .wrp-activities-book {
    
                                .form-book {
    
                                    .form,
                                    form {
    
                                        .box {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .side-content {
                        

                        .activities-information {
    
                            .row {
    
                                .c-activities-information {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }

            @include breakpoint('<=xxsmall') {

                .container {

                    .side-content {
                        

                        .activities-information {
    
                            .row {
    
                                .c-activities-information {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}