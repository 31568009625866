//container {
    .container {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 1400px;
        padding: 0 32px;

        @include breakpoint('<=xlarge') {
            max-width: 100%;
        }

        @include breakpoint('<=large') {
            padding: 0 24px;
        }

        @include breakpoint('<=medium') {
            padding: 0 16px;
        }

        @include breakpoint('<=small') {
            padding: 0 8px;
        }
    }