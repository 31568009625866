/* Basic */
// MSIE: Required for IEMobile.
	@-ms-viewport {
		width: device-width;
	}

// MSIE: Prevents scrollbar from overlapping content.
	body {
		-ms-overflow-style: scrollbar;
	}

// Ensures page width is always >=320px.
	@include breakpoint('<=xsmall') {
		html, body {
			min-width: 320px;
		}
	}

// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
	html {
		box-sizing: border-box;
	}

	* {
		box-sizing: border-box;
	}
	
	*:before, *:after {
		box-sizing: inherit;
	}

// Reset.
// Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain)

	html, body, div, span, applet, object,
	iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
	pre, a, abbr, acronym, address, big, cite,
	code, del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var, b,
	u, i, center, dl, dt, dd, ol, ul, li, fieldset,
	form, label, legend, table, caption, tbody,
	tfoot, thead, tr, th, td, article, aside,
	canvas, details, embed, figure, figcaption,
	footer, header, hgroup, menu, nav, output, ruby,
	section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		//font-size: 100%;
		//font: inherit;
		vertical-align: baseline;
	}

	article, aside, details, figcaption, figure,
	footer, header, hgroup, menu, nav, section {
		display: block;
	}

	body {
		font-family: $barlow;
		font-size: 16px;
		line-height: 24px;
		color: $black;

		@include breakpoint('<=xlarge') {
			font-size: 15px;
			line-height: 24px;
		}

		@include breakpoint('<=medium') {
			font-size: 12px;
			line-height: 24px;
		}
	}

	ol, ul {
		list-style:none;
	}

	a {
		text-decoration: none;
		outline: none;
		border: 0;

		&:hover {
			cursor: pointer;
		}
	}

	blockquote,	q {
		quotes: none;

		&:before,
		&:after {
			content: '';
			content: none;
		}
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	body {
		-webkit-text-size-adjust: none;
	}

	mark {
		background-color: transparent;
		color: inherit;
	}

	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	input, select, textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
	}

// Typography
	body {
		color: $black;
		@include fonts($barlow, 300, 16px, 26px, $spacing: 0);

		@include breakpoint('<=xlarge') {
			font-size: 14px;
			line-height: 24px;
		}

		@include breakpoint('<=small') {
			font-size: 12px;
			line-height: 22px;
		}
	}

	a {
		@include vendor('transition', (
			'color .5s ease-in-out',
			'background-color .5s ease-in-out',
			'border-color .5s ease-in-out',
			'box-shadow .5s ease-in-out'
		));
		text-decoration: none;
		word-break: break-word;

		&:hover {
			cursor: pointer;
		}
	}

	strong, b {
		font-weight: bold;
	}

	em, i {
		font-style: italic;
	}

	p {
		text-align: justify;
		@include fonts($barlow, 300, 16px, 24px, justify, normal, 1px, none);
		word-break: break-all;

		@include breakpoint('<=xlarge') {
			font-size: 14px;
			line-height: 24px;
		}

		@include breakpoint('<=small') {
			font-size: 12px;
			line-height: 22px;
		}
	}

	//header size
	h1, h2, h3, h4, h5, h6 {
		font-family: $libre;
		font-weight: 400;
		letter-spacing: .5px;
		text-transform: none;

		a {
			border-bottom: 0;
			color: inherit;
			text-decoration: none;
		}

		em {
			font-style: italic;
		}

		&.hidden {
			width: 0;
			height: 0;
			display: none;
			opacity: 0;
		}
	}

	h1 {
		font-size: 40px;
		line-height: 50px;

		@include breakpoint('<=large') {
			font-size: 32px;
			line-height: 42px;
		}

		@include breakpoint('<=medium') {
			font-size: 24px;
			line-height: 34px;
		}

		@include breakpoint('<=small') {
			font-size: 20px;
			line-height: 30px;
		}
	}

	h2 {
		font-size: 32px;
		line-height: 42px;

		@include breakpoint('<=large') {
			font-size: 28px;
			line-height: 38px;
		}

		@include breakpoint('<=medium') {
			font-size: 20px;
			line-height: 30px;
		}

		@include breakpoint('<=small') {
			font-size: 18px;
			line-height: 28px;
		}
	}

	h3 {
		font-size: 28px;
		line-height: 38px;

		@include breakpoint('<=large') {
			font-size: 22px;
			line-height: 32px;
		}

		@include breakpoint('<=medium') {
			font-size: 18px;
			line-height: 28px;
		}

		@include breakpoint('<=small') {
			font-size: 16px;
			line-height: 26px;
		}
	}

	h4 {
		font-size: 20px;
		line-height: 30px;

		@include breakpoint('<=large') {
			font-size: 18px;
			line-height: 28px;
		}

		@include breakpoint('<=medium') {
			font-size: 16px;
			line-height: 26px;
		}

		@include breakpoint('<=small') {
			font-size: 14px;
			line-height: 20px;
		}
	}

	h5 {
		font-size: 16px;
		line-height: 26px;

		@include breakpoint('<=medium') {
			font-size: 14px;
			line-height: 20px;
		}

		@include breakpoint('<=small') {
			font-size: 12px;
			line-height: 22px;
		}
	}

	h6 {
		font-size: 14px;
		line-height: 24px;

		@include breakpoint('<=small') {
			font-size: 12px;
			line-height: 22px;
		}
	}

	//quote
	blockquote {
		font-size: 20px;
		line-height: 30px;
	}

	//paragraph
	p {
		@include pm('margin', $top: 0, $bottom: 16px);
		@include position('relative');
		width: 100%;
		word-break: break-word;

		&.primary {
			color: $primary;
		}

		&.success {
			color: $success;
		}

		&.warning {
			color: $warning;
		}

		&.danger {
			color: $danger;
		}

		&:last-of-type {
			@include pm('margin', $bottom: 0);
		}
	}

	//label or badge
	.badge {
		@include display(inline-block);
		width: auto;
		height: auto;
		background-color: $black;
		@include pm('margin', $left:0, $right: 8px);
		@include pm('padding', 4px, 8px, 4px, 8px);
		@include border-radius(15px);
		color: $white;
		@include fonts($fw: 600, $fz: 10px, $lh: 20px, $spacing: 0.5px, $tt: uppercase);

		//another class here
		&.primary {
			background-color: $primary;
		}

		&.warning {
			background-color: $warning;
		}

		&.success {
			background-color: $success;
		}

		&.danger {
			background-color: $danger;
		}

		&.info {
			background-color: $info;
		}

		&.neutral {
			background-color: transparent;
			border: 1px solid $black;
			@include pm('padding', $top: 3px, $bottom: 3px);
			color: $black;
		}

		//set last margin-right: 0
		&:last-of-type {
			@include pm('margin', $right: 0);
		}

		@include breakpoint('<=small') {	
			@include pm('padding', $top: 2px, $bottom: 2px);

			&.neutral {
				@include pm('padding', $top: 1px, $bottom: 1px);
			}
		}
	}

	//text-align 
	.left {
		@include fonts($align: left);
	}

	.center {
		@include fonts($align: center);
	}

	.right {
		@include fonts($align: right);
	}

	@include breakpoint('<=small') {

		.left,
		.center,
		.right {
			text-align: left;
		}
	}

	//alert
	.alert {
		@include position('relative');
		width: 100%;
		height: auto;
		min-height: 40px;
		@include pm('padding', 8px, 56px, 8px, 8px);
		@include pm('margin', $bottom: 32px);
		@include border-radius(5px);
		border: 1px solid $gray-light;
		background-color: $white;
		box-shadow: none;
		@include vendor('transition', (
			'background-color .3s',
			'border-color .3s',
			'box-shadow .3s',
		));

		&.success {
			background-color: $success;
			border-color: $success;
			color: $white;

			i {
				&:before {
					color: $white;
				}
			}
		}

		&.info {
			background-color: $info;
			border-color: $info;
			color: $white;

			i {
				&:before {
					color: $white;
				}
			}
		}

		&.warning {
			background-color: $warning;
			border-color: $warning;
			color: $white;

			i {
				&:before {
					color: $white;
				}
			}
		}

		&.danger {
			background-color: $danger;
			border-color: $danger;
			color: $white;

			i {
				&:before {
					color: $white;
				}
			}
		}

		i {
			@include position('absolute', $top: 50%, $right: 8px);
			@include pm('margin', $top: -20px);
		}

		&:hover {
			cursor: pointer;
			@include box-shadow(0, 3px, 8px, 0, $black, .2);

			&.success {
				
				@include box-shadow(0, 3px, 8px, 0, $success, .5);
			}
			
			&.info {
				
				@include box-shadow(0, 3px, 8px, 0, $info, .5);
			}

			&.warning {
				
				@include box-shadow(0, 3px, 8px, 0, $warning, .5);
			}

			&.danger {
				
				@include box-shadow(0, 3px, 8px, 0, $danger, .5);
			}
		}

		@include breakpoint('<=small') {
			@include pm('margin', $bottom: 16px);	
			@include pm('padding', 4px, 48px, 4px, 4px);

			i {
				@include position('absolute', $top: 4px, $right: 4px);
			}
		}
	}

	body {

		background-color: #fff;
	}

	.link-transparent {
		color: transparent;
		border: 0;
		text-decoration: none;
	}

	//image
	.img-cover {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border: 0;
	}