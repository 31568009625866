#wrapper {

    #main {

        .s-booking {
            position: relative;
            width: 100%;
            padding-top: 48px;

            .container {

                .info-booking {
                    @include html-grid(0);
                    margin: 0 0;
                    background-color: transparent;
                    position: relative;

                    .c-info-booking {
                        position: relative;
                        width: 33.33%;
                        padding: 0 0;
                        text-align: center;

                        .no {
                            display: inline-block;
                            position: relative;
                            width: 48px;
                            height: 48px;
                            background-color: $white;
                            margin: 0 0;
                            padding: 8px;
                            @include border-radius(50%);
                            overflow: hidden;
                            vertical-align: top;

                            span {
                                display: block;
                                width: 32px;
                                height: 32px;
                                color: $white;
                                font-size: 18px;
                                line-height: 32px;
                                text-align: center;
                                background-color: rgba($primary, 1);
                                padding: 0 0;
                                @include border-radius(50%);
                            }
                        }

                        span {
                            display: inline-block;
                            width: auto;
                            background-color: $white;
                            padding: 12px 16px;
                            margin: 0 0;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 1px;
                            color: $black;
                            font-weight: 600;
                            vertical-align: top;
                        }

                        &:first-of-type {
                            text-align: left;
                        }

                        &:last-of-type {
                            text-align: right;
                        }
                    }

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: #ededed;
                        content: '';
                    }
                }

                .detail-booking {
                    position: relative;
                    width: 100%;
                    margin-top: 48px;

                    .message-booking {
                        display: block;
                        width: 100%;
                        padding: 8px;
                        background-color: #b0eacd;
                        @include border-radius(5px);
                        margin-bottom: 48px;

                        p {
                            position: relative;
                            width: 100%;
                            margin: 0 0;
                            font-weight: 500;
                            text-align: left;
                        }
                    }

                    .w-detail-booking {
                        position: relative;
                        width: 100%;
                        padding: 16px;
                        @include border-radius(5px);
                        border: 1px solid $gray-light;

                        form {
                            position: relative;
                            width: 100%;

                            .c-booking-time {
                                position: relative;
                                @include float-pos;
                                @include html-grid;
                                margin-left: -16px !important;
                                margin-right: -16px !important;

                                .booking-time {
                                    position: relative;
                                    width: 50%;
                                    padding: 4px 16px;
                                    margin-bottom: 8px;

                                    span {
                                        display: inline-block;
                                        font-weight: 600;
                                        min-width: 150px;
                                        position: relative;
                                        padding-right: 10px;

                                        &:after {
                                            display: block;
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            width: 10px;
                                            height: 100%;
                                            content: ':';
                                            font-weight: 600;
                                            text-align: left;
                                            color: $black;
                                        }
                                    }
                                }
                            }

                            .c-booking-property,
                            .c-booking-information,
                            .c-payment-method {
                                position: relative;
                                width: 100%;
                                margin-top: 32px;

                                h3,
                                h4 {
                                    position: relative;
                                    display: inline-block;
                                    width: 100%;
                                    text-align: left;
                                    margin-bottom: 24px;
                                }

                                .booking-property {
                                    position: relative;
                                    width: 100%;
                                    padding: 24px 16px;
                                    border: 1px solid $gray-light;

                                    .property {
                                        position: relative;
                                        margin-left: 0 !important;
                                        margin-right: 0 !important;
                                        @include html-grid;

                                        .title {
                                            width: 100%;
                                            position: relative;
                                            text-align: left;
                                            font-weight: 600;
                                            margin-bottom: 16px;
                                            padding: 0 8px; 
                                        }

                                        .thumbs {
                                            width: calc(100px + 16px);
                                            height: 80px;
                                            padding: 0 8px;
                                            margin-bottom: 16px;

                                            img {
                                                display: block;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                @include border-radius(3px);
                                            }
                                        }

                                        .rooms {
                                            width: 40%;
                                            padding: 0 8px;
                                            text-align: left;
                                            margin-bottom: 16px;
                                            @include flex($align: center, $justify: flex-start);

                                            .price {
                                                display: none !important;
                                                text-align: left;
                                                padding: 0 8px;
                                                margin-bottom: 0;
                                                display: block;
                                                width: 100%;
                                                font-size: 18px;
                                                line-height: 24px;

                                                i.icon {
                                                    width: 24px;
                                                    height: 24px;
                                                    margin-right: 8px;
                                                    padding: 0 0;

                                                    &:before {
                                                        font-size: 18px;
                                                        line-height: 24px;
                                                    }
                                                }
                                            }
                                        }

                                        .price {
                                            width: calc(100% - 40% - 116px);
                                            position: relative;
                                            padding: 0 8px;
                                            margin-bottom: 16px;
                                            @include flex($align: center, $justify: flex-start);
                                            font-weight: 600;
                                            font-size: 18px;
                                            line-height: 24px;

                                            i.icon {
                                                width: 24px;
                                                height: 24px;
                                                margin-right: 8px;
                                                padding: 0 0;

                                                &:before {
                                                    font-size: 18px;
                                                    line-height: 24px;
                                                }
                                            }
                                        }

                                        .total {
                                            position: relative;
                                            width: calc(40% + 116px);
                                            padding: 16px 8px 0 8px;
                                            @include flex($align: center, $justify: flex-end);
                                            font-size: 18px;
                                            line-height: 32px;
                                            font-weight: 600;
                                            text-transform: uppercase;
                                            border-top: 1px solid $gray-light;
                                            color: rgba($primary, 1);
                                        }

                                        .price-total {
                                            position: relative;
                                            width: calc(100% - 40% - 116px);
                                            padding: 16px 8px 0 8px;
                                            @include flex(wrap, $align: center, $justify: flex-start);
                                            font-weight: 600;
                                            font-size: 24px;
                                            line-height: 32px;
                                            border-top: 1px solid $gray-light;

                                            i.icon {
                                                width: 32px;
                                                height: 32px;
                                                margin-right: 8px;
                                                padding: 4px;

                                                &:before {
                                                    font-size: 24px;
                                                    line-height: 24px;
                                                }
                                            }

                                            a.get-promo {
                                                display: block;
                                                width: 100%;
                                                font-size: 12px;
                                                line-height: 22px;
                                                color: rgba($primary, 1);
                                            }
                                        }

                                        .service {
                                            position: relative;
                                            width: 100%;
                                            margin-top: 32px;

                                            span {
                                                position: relative;
                                                width: auto;
                                                display: inline-block;
                                                font-weight: 600;
                                            }

                                            ul {
                                                padding: 0 0;
                                                margin: 16px 0 0 0 !important;

                                                li {
                                                    position: relative;
                                                    width: 100%;
                                                    padding-left: 32px;
                                                    margin-bottom: 16px;

                                                    &:before {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        width: 24px;
                                                        height: 24px;
                                                        background-color: $gray-light;
                                                        @include border-radius(3px);
                                                        content: '';
                                                    }

                                                    &:after {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        width: 24px;
                                                        height: 24px;
                                                        font-family: $ico;
                                                        font-size: 10px;
                                                        line-height: 24px;
                                                        text-align: center;
                                                        color: $primary;
                                                        content: '\E817';
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .row {

                                    .box {
                                        width: 50%;

                                        &.full {
                                            width: 100%;
                                        }
                                    }
                                }

                                .payment-method {
                                    position: relative;
                                    width: 100%;
                                    padding: 0 0;
                                    border: 0;

                                    .payment-select {
                                        position: relative;
                                        width: 100%;

                                        ul {
                                            padding: 0 0;
                                            margin: 0 0;
                                            border: 0;
                                            background: transparent;

                                            li {
                                                padding: 0 0;
                                                margin: 0 16px 0 0;
                                                background: transparent;
                                                border: 0;
                                                position: relative;

                                                a {
                                                    font-size: 15px;
                                                    line-height: 24px;
                                                    font-weight: 600;
                                                    color: $black;
                                                    letter-spacing: 1;
                                                    padding: 0 0;
                                                }

                                                &:before {
                                                    content: '';
                                                    width: 0;
                                                    height: 0;
                                                    border-style: solid;
                                                    position: absolute;
                                                    border-width: 10px;
                                                    border-color: transparent #fff #fff transparent;
                                                    bottom: -28px;
                                                    left: 50%;
                                                    margin-left: - 14px;
                                                    @include vendor('transform', ('rotate(225deg)'));
                                                    -webkit-box-shadow: 2px 2px 0px $gray-light;
                                                    box-shadow: 2px 2px 0px $gray-light;
                                                    z-index: 2;
                                                    display: none;
                                                    @include vendor('transition', ('all .5s'));
                                                }

                                                &.ui-tabs-active,
                                                &.ui-state-active {

                                                    &:before {
                                                        display: block;
                                                        @include vendor('transition', ('all .5s'));
                                                    }
                                                }
                                            }
                                        }

                                        span {
                                            display: inline-block;
                                            width: auto;
                                            font-weight: 600;
                                        }

                                        .w-payment-select {
                                            position: relative;
                                            width: 100%;
                                            margin-top: 16px;
                                            padding: 16px;
                                            border: 1px solid $gray-light;
                                            @include border-radius(5px);
                                            
                                            .row {
                                                margin-bottom: -24px;

                                                .box {
                                                    width: calc(100% / 4);
                                                    padding-bottom: 24px;

                                                    .check-box {
                                                        position: relative;
                                                        width: 100%;
                                                        
                                                        input[type="radio"] {
                                                    
                                                            & + label {
                                                                padding: 0 0 0 24px;
                                                                height: auto;
                                                                
                                                                p {
                                                                    position: relative;
                                                                    width: 100%;
                                                                    margin-bottom: 0;
                                                                    letter-spacing: 0;
                                                                    line-height: 24px;
                                                                    @include vendor('transition', ('all .5s'));

                                                                    b {
                                                                        @include vendor('transition', ('all .5s'));
                                                                    }

                                                                    span {
                                                                        display: inline-block;
                                                                        width: 100px;
                                                                        text-align: left;
                                                                        color: rgba($black, 1);
                                                                        font-weight: 600;
                                                                        @include vendor('transition', ('all .5s'));
                                                                    }
                                                                }

                                                                &:before {
                                                                    top: 4px;
                                                                    left: 0px;
                                                                    width: 16px;
                                                                    height: 16px;
                                                                }
                                                    
                                                                &:after {
                                                                    top: 7px;
                                                                    left: 3px;
                                                                    width: 10px;
                                                                    height: 10px;
                                                                    background: rgba($primary, .6);
                                                                }
                                                            }
                                                    
                                                            &:checked + label {
                                                    
                                                                &:before {
                                                                    border-color: rgba($primary, 1);
                                                                }
                                                                
                                                                &:after {
                                                                    background: rgba($primary, 1);
                                                                }

                                                                p {

                                                                    b {color: $primary;}
                                                                }
                                                            }
                                                    
                                                            &:disable {
                                                    
                                                                label {
                                                                    color: $gray-light;
                                                    
                                                                    &:before {
                                                                        border-color: $gray-light;
                                                                        color: $gray-light;
                                                                    }
                                                    
                                                                    &:after {
                                                                        background: rgba($primary, .6);
                                                                    }
                                                                }
                                                    
                                                                &:checked + label {
                                                                    color: $gray-light;
                                                                    
                                                                    &:before {
                                                                        border-color: $gray-light;
                                                                        color: $gray-light;
                                                                    }
                                                    
                                                                    &:after {
                                                                        background: rgba($primary, .6);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &.full {
                                                        width: 100%;
                                                        
                                                    }

                                                    .check-box {
                                                        display: inline-block;
                                                        width: auto;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .c-button-act {
                                position: relative;
                                width: 100%;
                                text-align: center;
                                margin-top: 32px;

                                .btn {
                                    min-width: 200px;
                                    background-color: $gray-light;
                                    margin: 0 8px;
                                    text-decoration: none;

                                    span {
                                        color: $primary;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                    }

                                    &.primary {
                                        background-color: $primary;

                                        span {
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .left-booking {
                        position: relative;
                        float: left;
                        width: 350px;

                        .c-booking {
                            position: relative;
                            width: 100%;
                            border: 1px solid #e1f2fb;
                            margin-bottom: 16px;
                            @include set-border-radius(5px, 0, 5px, 0);

                            .header-booking {
                                position: relative;
                                width: 100%;
                                padding: 8px;
                                background-color: #e1f2fb;

                                h4, h5 {
                                    margin: 0 0;
                                    display: block;
                                    width: 100%;
                                    text-align: left;
                                }
                            }

                            .body-booking,
                            .footer-booking {
                                position: relative;
                                width: 100%;
                                padding: 8px;

                                p {
                                    display: block;
                                    width: 100%;
                                    text-align: left;
                                    margin: 0 0 16px 0;

                                    b {
                                        display: block;
                                        width: 100%;
                                    }

                                    a {
                                        display: inline-block;
                                        color: #07c;
                                        letter-spacing: 0;
                                        position: relative;

                                        &:after {
                                            display: none;
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 1px;
                                            background-color: #07c;
                                            content: '';
                                        }
                                    }

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                            .body-booking {
                                border-bottom: 1px solid #e1f2fb;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=large') {
        
        #main {
            .s-booking {
                padding-top: 32px;

                .container {

                    .info-booking {

                        .c-info-booking {

                            .no {
                                width: 48px;
                                height: 48px;
                                padding: 8px;
                                
                                span {
                                    width: 32px;
                                    height: 32px;
                                    font-size: 16px;
                                    line-height: 32px;
                                }
                            }

                            span {
                                padding: 12px 16px;
                                font-size: 15px;
                                line-height: 24px;
                            }
                        }
                    }

                    .detail-booking {
                        margin-top: 32px;

                        .message-booking {
                            padding: 8px;
                            margin-bottom: 32px;
                        }

                        .w-detail-booking {
                            padding: 16px;
                            @include border-radius(5px);

                            form {

                                .c-booking-time {
                                    margin-left: -16px !important;
                                    margin-right: -16px !important;

                                    .booking-time {
                                        width: 50%;
                                        padding: 4px 16px;
                                        margin-bottom: 8px;

                                        span {
                                            display: inline-block;
                                            min-width: 150px;
                                        }
                                    }
                                }

                                .c-booking-property,
                                .c-booking-information,
                                .c-payment-method {
                                    margin-top: 24px;

                                    h3,
                                    h4 {
                                        margin-bottom: 16px;
                                    }

                                    .booking-property {
                                        padding: 24px 16px;

                                        .property {

                                            .title {
                                                padding: 0 8px; 
                                            }

                                            .thumbs {
                                                width: calc(100px + 16px);
                                                height: 80px;
                                            }

                                            .rooms {
                                                width: 40%;
                                            }

                                            .price {
                                                width: calc(100% - 40% - 116px);
                                                font-size: 18px;
                                                line-height: 24px;

                                                i.icon {

                                                    &:before {
                                                        font-size: 18px;
                                                        line-height: 24px;
                                                    }
                                                }
                                            }

                                            .total {
                                                width: calc(40% + 116px);
                                                font-size: 18px;
                                                line-height: 32px;
                                            }

                                            .price-total {
                                                width: calc(100% - 40% - 116px);
                                                padding: 16px 8px 0 8px;
                                                font-size: 24px;
                                                line-height: 32px;
                                            }

                                            .service {
                                                margin-top: 24px;
                                            }
                                        }
                                    }

                                    .row {

                                        .box {
                                            width: 50%;

                                            &.full {
                                                width: 100%;
                                            }
                                        }
                                    }

                                    .payment-method {

                                        .payment-select {

                                            ul {

                                                li {

                                                    a {
                                                        font-size: 15px;
                                                    }
                                                }
                                            }

                                            .w-payment-select {
                                                .row {
                                                    margin-bottom: -24px;

                                                    .box {
                                                        width: calc(100% / 2);
                                                        padding-bottom: 24px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .c-button-act {
                                    margin-top: 24px;

                                    .btn {
                                        min-width: 200px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=medium') {

        #main {
    
            .s-booking {
                padding-top: 32px;

                .container {

                    .info-booking {
                        display: none;
                    }

                    .detail-booking {
                        margin-top: 0px;

                        .message-booking {
                            padding: 8px;
                            margin-bottom: 24px;
                        }

                        .w-detail-booking {
                            padding: 16px;
                            @include border-radius(5px);

                            form {

                                .c-booking-time {
                                    margin-left: -16px !important;
                                    margin-right: -16px !important;

                                    .booking-time {
                                        width: 50%;
                                        padding: 4px 16px;
                                        margin-bottom: 8px;

                                        span {
                                            display: inline-block;
                                            min-width: 110px;
                                        }
                                    }
                                }

                                .c-booking-property,
                                .c-booking-information,
                                .c-payment-method {
                                    margin-top: 24px;

                                    h3,
                                    h4 {
                                        margin-bottom: 16px;
                                    }

                                    .booking-property {
                                        padding: 16px 16px;

                                        .property {

                                            .title {
                                                padding: 0 8px; 
                                            }

                                            .thumbs {
                                                width: calc(100px + 16px);
                                                height: 80px;
                                            }

                                            .rooms {
                                                width: 40%;
                                            }

                                            .price {
                                                width: calc(100% - 40% - 116px);
                                                font-size: 18px;
                                                line-height: 24px;

                                                i.icon {

                                                    &:before {
                                                        font-size: 18px;
                                                        line-height: 24px;
                                                    }
                                                }
                                            }

                                            .total {
                                                width: calc(40% + 116px);
                                                font-size: 18px;
                                                line-height: 32px;
                                            }

                                            .price-total {
                                                width: calc(100% - 40% - 116px);
                                                padding: 16px 8px 0 8px;
                                                font-size: 24px;
                                                line-height: 32px;
                                            }

                                            .service {
                                                margin-top: 24px;
                                            }
                                        }
                                    }

                                    .row {

                                        .box {
                                            width: 50%;

                                            &.full {
                                                width: 100%;
                                            }
                                        }
                                    }

                                    .payment-method {

                                        .payment-select {

                                            ul {

                                                li {

                                                    a {
                                                        font-size: 15px;
                                                    }
                                                }
                                            }

                                            .w-payment-select {
                                                .row {
                                                    margin-bottom: -24px;

                                                    .box {
                                                        width: calc(100% / 2);
                                                        padding-bottom: 24px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .c-button-act {
                                    margin-top: 24px;

                                    .btn {
                                        min-width: 200px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=small') {

        #main {
                
            .s-booking {
                padding-top: 32px;

                .container {

                    .detail-booking {
                        margin-top: 0px;

                        .w-detail-booking {
                            padding: 16px;
                            @include border-radius(5px);

                            form {

                                .c-booking-time {
                                    margin-left: -16px !important;
                                    margin-right: -16px !important;

                                    .booking-time {
                                        width: 100%;
                                        padding: 4px 16px;
                                        margin-bottom: 8px;

                                        span {
                                            display: inline-block;
                                            min-width: 110px;
                                        }
                                    }
                                }

                                .c-booking-property,
                                .c-booking-information,
                                .c-payment-method {
                                    margin-top: 24px;

                                    h3,
                                    h4 {
                                        margin-bottom: 16px;
                                    }

                                    .booking-property {
                                        padding: 16px 16px;

                                        .property {

                                            .title {
                                                padding: 0 8px; 
                                            }

                                            .thumbs {
                                                width: calc(100px + 16px);
                                                height: 80px;
                                            }

                                            .rooms {
                                                width: calc(100% - 116px);
                                                flex-wrap: wrap;
                                                flex-direction: column;
                                                align-items: flex-start;
                                                justify-content: center;

                                                .price {
                                                    display: block !important;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    padding: 0 0;

                                                    i.icon {
                                                        margin-right: 0;

                                                        &:before {
                                                            font-size: 16px;
                                                            line-height: 24px;
                                                            text-align: left;
                                                        }
                                                    }
                                                }
                                            }

                                            .price {
                                                width: 100%;
                                                display: none;
                                            }

                                            .total {
                                                width: 116px;
                                                font-size: 18px;
                                                line-height: 32px;
                                            }

                                            .price-total {
                                                width: calc(100% - 116px);
                                                padding: 16px 8px 0 8px;
                                                font-size: 24px;
                                                line-height: 32px;
                                                justify-content: flex-end;



                                                a.get-promo {
                                                    text-align: right;
                                                }
                                            }

                                            .service {
                                                margin-top: 24px;
                                            }
                                        }
                                    }

                                    .row {

                                        .box {
                                            width: 50%;

                                            &.full {
                                                width: 100%;
                                            }
                                        }
                                    }

                                    .payment-method {

                                        .payment-select {

                                            ul {

                                                li {

                                                    a {
                                                        font-size: 15px;
                                                    }
                                                }
                                            }

                                            .w-payment-select {
                                                .row {
                                                    margin-bottom: -24px;

                                                    .box {
                                                        width: 100%;
                                                        padding-bottom: 24px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .c-button-act {
                                    margin-top: 24px;

                                    .btn {
                                        min-width: 200px;
                                        width: 100%;
                                        margin-bottom: 16px;

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=xsmall') {
        
        #main {
                    
            .s-booking {

                .container {

                    .detail-booking {

                        .w-detail-booking {

                            form {

                                .c-booking-property,
                                .c-booking-information,
                                .c-payment-method {

                                    .row {

                                        .box {
                                            width: 100%;

                                            &.full {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}