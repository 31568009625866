/*  
    ============================================================= 
        LIST VILLA
        
        css for list villa small view (grid minimum 3)
        and large views (1 column).
    ============================================================= 
*/

.villa {
    position: relative;

    .wrapper-villa {
        position: relative;
        width: 100%;
        height: 100%;
        @include border-radius(3px);
        overflow: hidden;
        @include box-shadow(0, 0, 23px, 0, #0a0a0a, 0.08);
        @include vendor('transition', ('all 0.5s'));

        .villa-thumbs {
            position: relative;
            width: 100%;
            padding-top: calc((1/1.5) * 100%);

            a {
                color: transparent;
                border: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                @include vendor('transition', ('all 0.5s'));
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    @include vendor('transition', ('all 0.5s'));
                }

                .discount {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    width: 56px;
                    height: 56px;
                    
                    span {
                        font-family: $roboto;
                        font-size: 12px;
                        line-height: 24px;
                        color: $white;
                        font-weight: 700;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        position: relative;
                        @include vendor('transition', ('all 0.5s'));
                        @include vendor('transform', 'rotate(-42deg)');
                        display: inline-block;
                        padding: 12px 15px 6px 5px;
                        width: 100%;
                        text-align: center;
                    }

                    i.icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        padding: 0 0;
                        @include vendor('transition', ('all 0.5s'));
                        @include vendor('transform', 'rotate(90deg)');

                        &:before {
                            color: rgba($primary, 1);
                            font-size: 56px;
                            line-height: 56px;
                        }
                    }
                    
                }
            }

            &:hover {
                
                a {
                    cursor: pointer;

                    img {
                        @include vendor('transform', 'scale(1.1)')
                    }
                }
            }
        }

        .villa-descriptions {
            position: relative;
            width: 100%;
            padding: 16px;
            background-color: $white;

            .c-title {
                position: relative;
                width: 100%;
                margin-bottom: 8px;

                a {
                    display: block;
                    width: 100%;
                    font-size: 24px;
                    line-height: 34px;
                    text-align: left;
                    letter-spacing: 0;
                    color: $black;
                }
            }

            .c-sort-description {
                position: relative;
                width: 100%;
                margin-bottom: 8px;

                span {
                    position: relative;
                    display: block;
                    width: 100%;
                    text-align: left;
                    font-size: 14px;
                    line-height: 24px;
                    color: $black;
                    font-weight: 600;
                    letter-spacing: 1px;
                    margin-bottom: 16px;

                    i.icon {
                        width: 24px;
                        height: 24px;
                        padding: 0 0;
                        margin-right: 8px;

                        &:before {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }

                .facilites {
                    position: relative;
                    width: 100%;
                    padding: 8px 0;
                    text-align: center;

                    i.icon {
                        width: 32px;
                        height: 32px;
                        padding: 4px;
                        margin: 0 2px;

                        &:before {
                            font-size: 20px;
                            line-height: 24px;
                            color: rgba($black, .7);
                        }
                    }

                    i.tnt {
                        width: 40px;
                        height: 40px;
                        padding: 8px;
                        margin: 0 0;

                        &:before {
                            font-size: 20px;
                            line-height: 24px;
                            color: rgba($black, .7);
                        }
                    }

                    &:before,
                    &:after {
                        position: absolute;
                        left: 0;
                        width: 80%;
                        height: 1px;
                        background-color: $gray-light;
                        content: '';
                        left: 50%;
                        margin-left: -40%;
                        display: block;
                    }

                    &:before {
                        top: 0
                    }

                    &:after {
                        bottom: 0;
                    }
                }

                p {
                    font-size: 15px;
                    line-height: 22px;
                    margin: 0 0;

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical; 
                        height: calc(2 * 22px);
                        overflow: hidden;
                        color: rgba($black, .7);
                        font-size: 15px;
                        line-height: 22px;
                        letter-spacing: .6px;
                        word-break: break-all;
                    }
                }
            }

            .c-reviews-and-price {
                position: relative;
                width: 100%;
                @include float-pos;
                min-height: 51px;
                @include flex($align: center);
                padding-right: 150px;

                .c-reviews {
                    text-align: left;
                    position: relative;
                    width: 100%;

                    i.icon {
                        width: 20px;
                        height: 20px;
                        padding: 0 0;
                        margin-right: 0px;

                        &:before {
                            font-size: 12px;
                            color: $primary;
                        }
                    }

                    span {
                        font-size: 12px;
                        line-height: 20px;
                        color: rgba($black, .5);
                        margin-left: 4px;
                        display: inline-block;
                        margin-right: 0;
                    }
                }

                .c-price {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 150px;

                    .price-real {
                        position: relative;
                        width: 100%;
                        text-align: right;
                        
                        span {
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba($black, .5);
                            display: inline-block;
                            margin: 0 0;
                            position: relative;
                            vertical-align: top;
                            padding-right: 4px;

                            i.icon {
                                width: 20px;
                                height: 24px;
                                padding: 0 0;
                                position: relative;
                                vertical-align: top;
    
                                &:before {
                                    width: 100%;
                                    color: rgba($black, .5);
                                    font-size: 12px;
                                    line-height: 24px;
                                    text-align: center;
                                }
                            }

                            &:after {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 100%;
                                height: 2px;
                                margin-top: -1px;
                                background-color: $primary;
                                content: '';
                            }

                            &.no-discount {

                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .price-discount {
                        position: relative;
                        width: 100%;
                        text-align: right;

                        span {
                            display: inline-block;
                            font-size: 22px;
                            line-height: 24px;
                            font-family: $roboto;
                            color: $primary;
                            font-weight: 700;
                            letter-spacing: 0px;

                            i.icon {
                                width: 24px;
                                height: 24px;
                                padding: 0 0;

                                &:before {
                                    color: $primary;
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }

            .c-action {
                position: relative;
                width: 100%;
                @include float-pos;
                text-align: right;
                padding-top: 16px;
                margin-top: 16px;

                a.btn {
                    float: left;
                    height: auto;
                    padding: 4px 16px;
                    margin: 0 0;

                    span {
                        font-size: 12px;
                        line-height: 24px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }

                    &:hover {
                        padding-right: 16px;
                    }
                }

                a {
                    display: inline-block;
                    position: relative;
                    width: auto;
                    color: rgba($black, .7);
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    font-weight: 600;
                    margin-top: 4.5px;
                    @include vendor('transition', (
                        'all 0.5s',
                    ));
                    padding-right: 24px;

                    i.icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 24px;
                        height: 24px;
                        padding: 0 0;

                        &:before {
                            color: rgba($black, .7);
                        }
                    }

                    &:hover {
                        color: rgba($primary, 1);
                        padding-right: 32px;

                        i.icon {

                            &:before {
                                color: rgba($primary, 1);
                            }
                        }
                    }
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: rgba($primary, .2);
                    content: '';
                }
            }
        }

        a.more {
            width: 100%;
            height: 100%;
            background: rgba($primary, .9);
            color: $white;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            @include flex($align: center, $justify: center);
            padding: 15%;
        }
    }

    &.full {

        .wrapper-villa {
            @include flex($flex-wrap: wrap, $align: stretch, $justify: center);
            height: auto;
            border: 1px solid $gray-light;
            overflow: hidden;

            .villa-thumbs {
                width: 40%;
                padding-top: 0;
                overflow: hidden;
                @include set-border-radius(5px, 5px, 0, 0);

                a {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }

            .villa-descriptions {
                width: 60%;
                padding: 16px 16px 16px 32px;

                .c-desc-bottom {
                    position: relative;
                    width: 100%;
                    @include float-pos;

                    .c-bottom-left {
                        position: relative;
                        float: left;
                        width: calc(100% - 200px);
                        padding-right: 32px;
                           
                        .c-reviews {
                            position: relative;
                            width: 100%;
                            margin-bottom: 8px;

                            i.icon {
                                width: 24px;
                                height: 24px;
                                padding: 0 0;

                                &:before {
                                    color: rgba($primary, 1);
                                }
                            }

                            span {
                                display: inline-block;
                                width: auto;
                                margin-left: 8px;
                                font-size: 12px;
                                line-height: 24px;
                            }
                        }

                        .c-address {
                            position: relative;
                            width: 100%;
                            padding-left: 24px;

                            i.icon {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 24px;
                                height: 24px;
                                padding: 0 0;

                                &:before {
                                    color: rgba($black, .7);
                                }
                            }

                            p {
                                position: relative;
                                display: block;
                                width: 100%;
                                text-align: left;
                                margin: 0 0;
                            }
                        }

                        .c-facilities {
                            position: relative;
                            width: 100%;
                            text-align: left;
                            margin-top: 8px;

                            .box-facilities {
                                display: inline-block;
                                width: auto;
                                margin-left: 16px;

                                i.icon {
                                    width: 24px;
                                    height: 24px;
                                    padding: 0 0;

                                    &:before {
                                        color: rgba($black, .5);
                                    }
                                }

                                span {
                                    display: inline-block;
                                    width: auto;
                                    margin-left: 8px;
                                }

                                &:first-of-type {
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    .c-price {
                        float: left;
                        width: 200px;
                        text-align: right;
                        position: relative;

                        .price {
                            display: inline-block;
                            width: auto;
                            padding-left: 48px;
                            text-align: left;
                            position: relative;

                            i.icon {
                                position: absolute;
                                top: 0;
                                left: 0;
                                padding: 0 0;

                                &:before {
                                    font-size: 48px;
                                    line-height: 48px;
                                    width: 48px;
                                    height: 48px;
                                    text-align: left;
                                    color: $black;
                                }
                            }

                            span {
                                position: relative;

                                &.disc {
                                    display: inline-block;
                                    width: auto;
                                    font-size: 18px;
                                    line-height: 24px;
                                    color: $black;
                                    margin-left: 8px;

                                    &:after {
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        width: 100%;
                                        height: 2px;
                                        background-color: rgba($primary, 1);
                                        content: '';
                                        margin-left: -1px;
                                    }
                                }

                                &.price-real {
                                    display: block;
                                    width: 100%;
                                    font-size: 24px;
                                    line-height: 24px;
                                    font-weight: 500;
                                    margin-left: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=xlarge') {
       
        .wrapper-villa {
    
            .villa-descriptions {
                padding: 16px;
    
                .c-title {
                    margin-bottom: 8px;
    
                    a {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
    
                .c-sort-description {
                    margin-bottom: 8px;
    
                    p {
                        font-size: 14px;
    
                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=large') {


        &.full {

            .wrapper-villa {
               
                .villa-thumbs {
                    width: 40%;
                }
    
                .villa-descriptions {
                    width: 60%;
                    padding: 16px 16px 16px 24px;
    
                    .c-desc-bottom {
    
                        .c-bottom-left {
                            width: 100%;
                            padding-right: 0;
                        }
    
                        .c-price {
                            width: 100%;
                            text-align: center;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=medium') {

        .wrapper-villa {
            
            .villa-thumbs {
                padding-top: calc((1/2) * 100%);
            }
        }

        &.full {

            .wrapper-villa {
               
                .villa-thumbs {
                    width: 100%;
                    @include set-border-radius(5px, 0, 5px, 0);
                    padding-top: calc((9/16) * 100%);

                    a {
                        position: absolute;
                    }
                }
    
                .villa-descriptions {
                    width: 100%;
                    padding: 16px 16px;
    
                    .c-desc-bottom {
    
                        .c-bottom-left {
                            width: 100%;
                            padding-right: 0;
                        }
    
                        .c-price {
                            width: 100%;
                            text-align: center;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=xsmall') {
        
        .wrapper-villa {
           
            .villa-thumbs {
                padding-top: calc((1/2) * 100%);
            }
    
            .villa-descriptions {
                padding: 16px 8px;
    
                .c-title {
                    a {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
    
                .c-sort-description {
                    margin-bottom: 16px;
    
                    p {
                        a {
                            letter-spacing: 0px;
                        }
                    }
                }
    
                .c-reviews-and-price {
                    min-height: auto;
                    display: block;
                    padding-right: 0;
    
                    .c-reviews {
                        display: inline-block;
                        text-align: center;
                        margin-bottom: 8px;
                    }
    
                    .c-price {
                        position: relative;
                        top: auto;
                        right: auto;
                        width: 100%;
                        display: inline-block;
    
                        .price-real {
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                            margin-bottom: 4px;
                        }
    
                        .price-discount {
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

/*  
    ============================================================= 
        LIST VILLA by AREA
        
        css for list villa by area.
    ============================================================= 
*/

.area {
    position: relative;

    .wrapper-area {
        position: relative;
        width: 100%;
        height: 100%;
        @include border-radius(3px);
        overflow: hidden;
        @include box-shadow(0, 0, 23px, 0, #0a0a0a, 0.08);

        .c-area-photo {
            position: relative;
            width: 100%;
            padding-top: calc((2/3) * 100%);
            @include vendor('transition', ('all 0.2s'));

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            .wrapper-area-photo {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                z-index: 2;
                @include vendor('transition', ('transform .2s ease-out'));
                @include vendor('transform', 'translate(0,0)');

                .title {
                    position: relative;
                    width: 100%;
                    text-align: center;
                    color: $white;
                    font-family: $roboto;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    @include vendor('transition', ('transform .2s ease-out', 'padding .2s ease-out'));
                    @include vendor('transform', 'translate(0,-100%)');
                    padding: 0 32px 48px 32px;
                }

                .sort-desc {
                    position: relative;
                    width: 100%;
                    color: $white;
                    padding: 0 32px;
                    @include vendor('transition', ('all 0.2s'));

                    p {
                        margin: 0 0;
                        display: inline-block;
                        text-align: center;
                        color: $white;
                        font-size: 14px;
                        line-height: 22px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical; 
                        height: calc(2 * 22px);
                        overflow: hidden;
                        letter-spacing: .6px;
                        word-break: break-all;
                    }
                }

                .act {
                    position: relative;
                    width: 100%;
                    text-align: center;
                    padding: 16px 32px 0 32px;
                    @include vendor('transition', ('all 0.2s'));

                    a {
                        display: inline-block;
                        width: auto;
                        height: auto;
                        font-size: 12px;
                        line-height: 22px;
                        color: rgba($primary, 1);
                        font-family: $roboto;
                        font-weight: 500;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }
            }

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url('../img/tour-category-item-gradient.png') bottom repeat-x;
                content: '';
            }
        }
    }

    &:hover {

        .wrapper-area {

            .c-area-photo {

                .wrapper-area-photo {
                    top: 50%;
                    bottom: auto;
                    @include vendor('transform', 'translate(0,-50%)');

                    .title {
                        padding-bottom: 8px;
                        padding-top: 0;
                        @include vendor('transform', 'translate(0,0)');
                    }

                    .act {

                        a {cursor: pointer;}
                    }
                }
                
                &:after {
                    background: rgba(#000, .85);
                }
            }
        }
    }

    @include breakpoint('<=xsmall') {
        
        .wrapper-area {

            .c-area-photo {
                padding-top: calc((1/2) * 100%);


                .wrapper-area-photo {

                    .sort-desc {
                        padding: 0 16px;

                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

/*  
    ============================================================= 
        TYPE
        
        list serch by type.
    ============================================================= 
*/
.c-by-type {
    position: relative;

    .w-by-type {
        position: relative;
        width: 100%;
        height: 100%;

        .thumbs {
            position: relative;
            width: 100%;
            padding-top: calc((1/1.5) * 100%);
            overflow: hidden;

            a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @extend .link-transparent;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .desc {
            position: relative;
            width: 100%;
            margin-top: 16px;

            h4 {
                color: $black;
                position: relative;
                width: 100%;
                text-align: left;
            }

            span {
                display: block;
                width: 100%;
                text-align: left;
                color: rgba($black, .7);
                font-size: 14px;
            }
        }
    }
}