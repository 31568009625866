.heroes {
    @include position('relative');
    width: 100%;
    height: calc(100vh - 137px);
    max-height: 100%;
    min-height: 500px;
    
    .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-wrapper {
            @include flex();
            
            .swiper-slide {
                height: auto;
                overflow: hidden;

                .wrapper-heroes {
                    @include position('relative');
                    width: 100%;
                    height: 100%;

                    .img-heroes {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-position: center;
                        
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }

                    .c-heroes {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        .container {
                            height: 100%;
                            @include flex($align: flex-start, $justify: center);
                            flex-wrap: wrap;
                            flex-direction: column;

                            .title {
                                position: relative;
                                width: 100%;
                                font-family: $libre;
                                font-size: 64px;
                                line-height: 84px;
                                text-align: left;
                                color: $white;
                                font-weight: 400;
                                letter-spacing: 1px;
                                word-break: break-word;
                                margin-bottom: 24px;
                            }

                            .act-btn {
                                position: relative;
                                width: 100%;
                                text-align: left;

                                a {
                                    display: inline-block;
                                    width: auto;
                                    color: $white;
                                    font-size: 18px;
                                    line-height: 48px;
                                    font-family: $barlow;
                                    letter-spacing: 1px;
                                    text-transform: uppercase;
                                    @include vendor('transition', 'all .5s');

                                    i.icon {
                                        @include border-radius(50%);
                                        border: 2px solid $white;
                                        margin-right: 8px;
                                        @include vendor('transition', 'all .5s');
                                        background-color: transparent;
                                        
                                        &::before {
                                            color: $white;
                                            @include vendor('transition', 'all .5s');
                                        }
                                    }

                                    &:hover {
                                        cursor: pointer;

                                        i.icon {
                                            background-color: $primary;
                                            border-color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .swiper-custom-pagination {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 1400px;
            padding: 0 32px;
            z-index: 2;
            @include flex(wrap);

            .swiper-pagination-bullet {
                width: auto;
                height: auto;
                padding: 0 0 16px 16px;
                flex: 1;
                background: transparent;
                font-size: 14px;
                line-height: 24px;
                color: $white;
                opacity: .8;
                border-left: 2px solid $white;
                margin: 0;
                border-radius: 0;
                font-family: $barlow;
                font-weight: 400;
                text-transform: uppercase;
                outline: none;
                letter-spacing: 1px;

                span {
                    display: block;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .heroes-search {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flex(wrap, column, center, center);
        z-index: 2;
        
        h2,
        h3 {
            position: relative;
            width: 100%;
            text-align: center;
            color: $white;
            letter-spacing: 1px;
            margin-bottom: 24px;
        }

        .form,
        form {
            display: block;
            max-width: 1200px;
            width: 100%;
            background-color: rgba($white, .6);
            padding: 8px;
            @include border-radius(3px);

            .row {
                margin-left: -4px;
                margin-right: -4px;
                padding-right: 200px;

                .box {
                    width: 20%;
                    padding: 0 4px;

                    input[type="text"] {
                        height: 56px;
                        padding: 16px 16px 16px 48px;
                        border: 1px solid $white;
                        background: $white;
                        border-radius: 5px;	
                        color: #80868e;
                        font-size: 16px;
                        line-height: 24px;
                        font-family: $barlow;
                        font-weight: 300;
                        letter-spacing: 1px;

                        &::-webkit-input-placeholder {
                            padding: 16px 0px;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba($black, .5);
                            font-family: $barlow;
                            font-weight: 300;
                            letter-spacing: 1px;
                        }
                    
                        &:-moz-placeholder {
                            padding: 16px 0px;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba($black, .5);
                            font-family: $barlow;
                            font-weight: 300;
                            letter-spacing: 1px;
                        }
                    
                        &::-moz-placeholder {
                            padding: 16px 0px;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba($black, .5);
                            font-family: $barlow;
                            font-weight: 300;
                            letter-spacing: 1px;
                        }
                    
                        &:-ms-input-placeholder {
                            padding: 16px 0px;
                            font-size: 16px;
                            line-height: 24px;
                            color: rgba($black, .5);
                            font-family: $barlow;
                            font-weight: 300;
                            letter-spacing: 1px;
                        }
                    }

                    .btn {
                        height: 56px;
                        margin-bottom: 0;
                        width: 100%;

                        span {
                            font-size: 16px;
                            text-transform: uppercase;

                        }
                    }

                    &.has-icon {
                        
                        input[type="text"] {
                            padding-left: 48px;
                        }

                        i.icon {
                            position: absolute;
                            top: 8px;
                            left: 4px;

                            &:before {
                                color: $primary;
                            }
                        }

                        &:after {display: none;}
                    }

                    &.abs {
                        position: absolute;
                        top: 8px;
                        right: 4px;
                        width: 200px;
                    }

                    &:first-of-type {
                        width: 40%;
                    }
                }
            }
        }
    }

    @include breakpoint('<=xlarge') {

        .swiper-container {
            
            .swiper-wrapper {
                
                .swiper-slide {
    
                    .wrapper-heroes {
    
                        .c-heroes {
    
                            .container {
    
                                .title {
                                    font-size: 56px;
                                    line-height: 76px;
                                    margin-bottom: 24px;
                                }
    
                                .act-btn {
    
                                    a {
                                        font-size: 18px;
                                        line-height: 48px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .heroes-search {
            
            h2,
            h3 {
                margin-bottom: 24px;
            }
    
            .form,
            form {

                .row {

                    .box {

                        input[type="text"] {
                            height: 48px;
                            padding: 12px 12px 12px 48px;
                            font-size: 15px;

                            &::-webkit-input-placeholder {
                                padding: 12px 0px;
                                font-size: 15px;
                                line-height: 24px;
                            }
                        
                            &:-moz-placeholder {
                                padding: 12px 0px;
                                font-size: 15px;
                                line-height: 24px;
                            }
                        
                            &::-moz-placeholder {
                                padding: 12px 0px;
                                font-size: 15px;
                                line-height: 24px;
                            }
                        
                            &:-ms-input-placeholder {
                                padding: 12px 0px;
                                font-size: 15px;
                                line-height: 24px;
                            }
                        }

                        .select-box {
                    
                            .selectric-wrapper {
                                
                                .selectric {    
                                    height: 48px;
                                    padding: 12px 48px 12px 16px;
                    
                                    .label {
                                        font-size: 14px;
                                        height: auto;
                                    }
                    
                                    .button {
                                        width: 48px;
                                        height: 48px;
                                        padding: 12px;
                                        top: 0px;
                    
                                        &:after {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }

                        .btn.primary {
                            height: 48px;

                            span {
                                font-size: 15px;

                            }
                        }

                        &.has-icon {

                            i.icon {
                                top: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=large') {

        .swiper-container {
            
            .swiper-wrapper {
                
                .swiper-slide {
    
                    .wrapper-heroes {
    
                        .c-heroes {
    
                            .container {
    
                                .title {
                                    font-size: 48px;
                                    line-height: 68px;
                                    margin-bottom: 24px;
                                }
    
                                .act-btn {
    
                                    a {
                                        font-size: 18px;
                                        line-height: 32px;

                                        i.icon {
                                            width: 32px;
                                            height: 32px;
                                            padding: 4px;

                                            &:before {
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .heroes-search {
            
            h2,
            h3 {
                margin-bottom: 24px;
            }
    
            .form,
            form {
                width: 90%;
                max-width: 1200px;
            }
        }
    }

    @include breakpoint('<=medium') {
        
        .swiper-container {
            
            .swiper-wrapper {
                
                .swiper-slide {
    
                    .wrapper-heroes {
    
                        .c-heroes {
    
                            .container {
    
                                .title {
                                    font-size: 40px;
                                    line-height: 50px;
                                    margin-bottom: 20px;
                                }
    
                                .act-btn {
    
                                    a {
                                        font-size: 18px;
                                        line-height: 32px;

                                        i.icon {
                                            width: 32px;
                                            height: 32px;
                                            padding: 4px;

                                            &:before {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .swiper-custom-pagination {
                display: none;
            }
        }

        .heroes-search {
            
            h2,
            h3 {
                margin-bottom: 24px;
            }
    
            .form,
            form {
                width: 90%;
                max-width: 100%;

                .row {
                        padding-right: 0;

                    .box {
                        width: 25%;

                        &.abs {
                            position: relative;
                            top: 0;
                            right: 0;
                            width: 25%;  
                        }

                        &:first-of-type {
                            width: 100%;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=small') {
        
        .swiper-container {
            
            .swiper-wrapper {
                
                .swiper-slide {
    
                    .wrapper-heroes {
    
                        .c-heroes {
    
                            .container {
    
                                .title {
                                    font-size: 32px;
                                    line-height: 42px;
                                    margin-bottom: 16px;
                                }
    
                                .act-btn {
    
                                    a {
                                        font-size: 18px;
                                        line-height: 32px;

                                        i.icon {
                                            width: 32px;
                                            height: 32px;
                                            padding: 4px;

                                            &:before {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=xsmall') {
        min-height: 450px;  

        .swiper-container {
            
            .swiper-wrapper {
                
                .swiper-slide {
    
                    .wrapper-heroes {
    
                        .c-heroes {
    
                            .container {
    
                                .title {
                                    font-size: 30px;
                                    line-height: 40px;
                                    margin-bottom: 16px;
                                }
    
                                .act-btn {
    
                                    a {
                                        font-size: 16px;
                                        line-height: 32px;

                                        i.icon {
                                            width: 32px;
                                            height: 32px;
                                            padding: 4px;

                                            &:before {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .heroes-search {
            
            h2,
            h3 {
                margin-bottom: 24px;
            }
    
            .form,
            form {
                width: 90%;
                max-width: 100%;

                .row {
                        padding-right: 0;

                    .box {
                        width: 100%;
                        margin-bottom: 8px;

                        &.abs {
                            position: relative;
                            top: 0;
                            right: 0;
                            width: 100%;  
                        }

                        &:first-of-type {
                            width: 100%;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}