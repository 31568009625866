#wrapper {

    #main {

        .s-pages.about-us {

            .container {

                h3 {
                    position: relative;
                    text-align: center;
                    margin-bottom: 8px;
                }

                span.qodef-m-text {
                    max-width: 75%;
                    width: 100%;
                    text-align: center;
                    font-size: 21px;
                    line-height: 31px;
                    color: $black;
                    letter-spacing: 1px;
                    margin: 0 auto;
                    display: block;
                    word-break: break-all;
                }
            }

            .youtube {
                position: relative;
                width: 100%;
                margin-top: 48px;

                .container {
                    .youtube-embed {
                        position: relative;
                        width: 100%;
                        padding-top: calc((9/16) * 100%);
                        overflow: hidden;

                        a {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            text-decoration: none;
                            outline: none;
                            border: 0;
                            color: transparent;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }

                            i.icon {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 64px;
                                height: 64px;
                                margin-top: -32px;
                                margin-left: -32px;
                                padding: 8px;
                                @include border-radius(50%);
                                border: 2px solid $white;

                                &:before {
                                    color: $white;
                                    width: 48px;
                                    height: 48px;
                                    font-size: 48px;
                                    line-height: 48px;
                                }
                            }
                        }
                    }
                }

                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    background-color: #FBFBF9;
                    content: '';
                }
            }

            .our-service {
                position: relative;
                width: 100%;
                padding: 48px 0;
                background-color: #FBFBF9;

                .container {

                    .row {
                        margin: 0 -16px;

                        .service {
                            width: 33.33%;
                            padding: 16px 16px;

                            .w-service {
                                position: relative;
                                width: 100%;
                                height: 100%;

                                .the-icon {
                                    position: relative;
                                    width: 100%;
                                    margin-left: 8px;

                                    i.fa {

                                        &:before {
                                            color: rgba($primary, .8);
                                            font-size: 32px;
                                            line-height: 32px;
                                        }
                                    }
                                }

                                h4 {
                                    position: relative;
                                    text-align: left;
                                    margin: 8px 0;
                                }

                                p {
                                    margin: 0 0;
                                }
                            }
                        }
                    }
                }
            }

            .we-do {
                position: relative;
                width: 100%;
                margin-top: 48px;

                .container {

                    h3 {
                        position: relative;
                        text-align: center;
                        margin-bottom: 8px;
                    }
    
                    span.qodef-m-text {
                        max-width: 75%;
                        width: 100%;
                        text-align: center;
                        font-size: 21px;
                        line-height: 31px;
                        color: $black;
                        letter-spacing: 1px;
                        margin: 0 auto;
                        display: block;
                        word-break: break-all;
                    }

                    .list-we-do {
                        position: relative;
                        width: 100%;
                        margin-top: 48px;

                        .row {
                            margin: 0 -16px;

                            .ar-we-do {
                                width: 33.33%;
                                padding: 16px 16px;

                                .wr-we-do {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;

                                    .thumbs {
                                        position: relative;
                                        width: 100%;
                                        padding-top: calc((1/1) * 100%);

                                        a {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            overflow: hidden;
                                            color: transparent;
                                            border: 0;
                                            outline: none;
                                            text-decoration: none;

                                            img {
                                                display: block;
                                                position: relative;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                object-position: center;
                                            }

                                            h4 {
                                                position: absolute;
                                                left: 0;
                                                bottom: 16px;
                                                z-index: 2;
                                                color: $white;
                                                background: rgba($primary, .8);
                                                padding: 16px 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .testimonial {
                position: relative;
                width: 100%;
                padding: 48px 0;

                .container {

                    .row {
                        margin: 0 0;

                        .swiper-container {
                            padding-left: 56px;
                            padding-right: 56px;

                            .swiper-wrapper {

                                .swiper-slide {

                                    .ar-testimonial {
                                        padding: 0 0;
                                        position: relative;
                                        width: 100%;

                                        .wrp-testimonial {
                                            position: relative;
                                            width: 100%;
                                            
                                            h3 {
                                                position: relative;
                                                width: 100%;
                                                text-align: center;
                                                margin-bottom: 8px;
                                            } 
                    
                                            span.qodef-m-text {
                                                width: 100%;
                                                text-align: center;
                                                font-size: 21px;
                                                line-height: 31px;
                                                color: $black;
                                                letter-spacing: 1px;
                                                margin: 0 auto;
                                                display: block;
                                                word-break: break-all;
                                            }

                                            .by {
                                                position: relative;
                                                width: 100%;
                                                text-align: center;
                                                font-weight: bold;
                                                color: $primary;
                                                letter-spacing: 1px;
                                                margin-top: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            .btn-prev,
                            .btn-next {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                width: 48px;
                                height: 100%;
                                //margin-top: -24px;
                                z-index: 2;
                                background: $white;
                                @include flex($align: center, $justify: center);

                                i.icon {
                                    padding: 0 0;

                                    &:before {
                                        width: 100%;
                                        height: 100%;
                                        font-size: 32px;
                                        line-height: 48px;
                                    }
                                }

                                &:hover {
                                    cursor: pointer;

                                    i.icon {

                                        &:before {
                                            color: rgba($primary, 1);
                                        }
                                    }
                                }
                            }

                            .btn-prev {left: 0;}
                            .btn-next {right: 0;}
                        }
                    }
                }
            }
        }

        @include breakpoint('<=medium') {
    
            .s-pages.about-us {

                .container {
    
                    span.qodef-m-text {
                        max-width: 100%;
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
    
                .youtube {
                    margin-top: 32px;
    
                    .container {
                        .youtube-embed {
    
                            a {
    
                                i.icon {
                                    width: 56px;
                                    height: 56px;
                                    margin-top: -23px;
                                    margin-left: -23px;
    
                                    &:before {
                                        color: $white;
                                        width: 48px;
                                        height: 48px;
                                        font-size: 40px;
                                        line-height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
    
                .our-service {
                    padding: 32px 0;
    
                    .container {
    
                        .row {
    
                            .service {
                                width: 33.33%;
                            }
                        }
                    }
                }
    
                .we-do {
                    margin-top: 32px;
    
                    .container {
        
                        span.qodef-m-text {
                            max-width: 100%;
                            font-size: 18px;
                            line-height: 28px;
                        }
    
                        .list-we-do {
                            margin-top: 32px;
    
                            .row {
    
                                .ar-we-do {
                                    width: 33.33%;
                                }
                            }
                        }
                    }
                }
    
                .testimonial {
                    padding: 32px 0;
    
                    .container {
    
                        .row {
    
                            .swiper-container {
                                padding-left: 0px;
                                padding-right: 0px;
    
                                .swiper-wrapper {
    
                                    .swiper-slide {
    
                                        .ar-testimonial {
    
                                            .wrp-testimonial {
                        
                                                span.qodef-m-text {
                                                    font-size: 16px;
                                                    line-height: 26px;
                                                }
                                            }
                                        }
                                    }
                                }
    
                                .btn-prev,
                                .btn-next {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        } 

        @include breakpoint('<=xsmall') {
    
            .s-pages.about-us {

                .container {
    
                    span.qodef-m-text {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
    
                .youtube {
                    margin-top: 32px;
                }
    
                .our-service {
                    padding: 32px 0;
    
                    .container {
    
                        .row {

                            .service {
                                width: 100%;

                                .w-service {

                                    .the-icon,
                                    h4,
                                    p {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
    
                .we-do {
                    margin-top: 32px;
    
                    .container {
        
                        span.qodef-m-text {
                            font-size: 16px;
                            line-height: 26px;
                        }
    
                        .list-we-do {
                            margin-top: 32px;
    
                            .row {
                                margin: 0 -4px;

                                .ar-we-do {
                                    width: 33.33%;
                                    padding-right: 4px;
                                    padding-left: 4px;
                                }
                            }
                        }
                    }
                }
    
                .testimonial {
                    padding: 32px 0;
    
                    .container {
    
                        .row {
    
                            .swiper-container {
                                padding-left: 0px;
                                padding-right: 0px;
    
                                .swiper-wrapper {
    
                                    .swiper-slide {
    
                                        .ar-testimonial {
    
                                            .wrp-testimonial {
                        
                                                span.qodef-m-text {
                                                    font-size: 16px;
                                                    line-height: 26px;
                                                }
                                            }
                                        }
                                    }
                                }
    
                                .btn-prev,
                                .btn-next {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=xxsmall') {
    
            .s-pages.about-us {
    
                .we-do {
    
                    .container {
    
                        .list-we-do {
    
                            .row {

                                .ar-we-do {
                                    width: 100%;

                                    .thumbs {
                                        padding-top: calc((1/2) * 100%) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}