.c-faq {
    position: relative;
    width: 100%;

    .wrp-faq {
        position: relative;
        width: 100%;
        padding: 16px 0;
        @include vendor('transition', ('all .3s ease'));
        

        p.question {
            position: relative;
            width: 100%;
            margin: 0 0;
            padding-right: 24px;

            b {
                font-weight: 600;
            }

            &:after {
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 24px;
                font-family: $ico;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: $black;
                font-weight: 600;
                content: '\F107';
                @include vendor('transition', ('all .3s ease-in-out'));
            }
        }

        .answer {
            position: relative;
            width: 100%;
            max-height: 0;
            margin-top: 16px;
            padding-top: 16px;
            display: none;
            opacity: 0;
            @include vendor('transition', ('all .3s ease'));

            p {
                position: relative;
                width: 100%;
                margin: 0 0 16px 0;
                @include vendor('transition', ('all .3s ease'));

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $gray-light;
                content: '';
            }
        }

        &.active {

            p.question {

                &:after {
                    @include vendor('transform', 'rotate(0.5turn)');
                }
            }

            .answer {
                display: block;
                opacity: 1;
                max-height: 100%;
            }
        }

        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: $gray-light;
            content: '';
            display: block;
        }
    }
}