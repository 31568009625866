//all function you need declare here



//mixin clearfix if we have float position
@mixin clearfix() {
	&::before,
	&::after {
	  display: block;
	  clear: both;
	  content: "";
	}
}

//mixin row if we want make traditional grid
@mixin row($padding) {
  margin-left: $padding;
  margin-right: $padding;
  @include clearfix;
}

//mixin font face
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote(#{$path-font}$path+'.'+$format)) format(quote($format)), comma);
		//$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

//mixin opacity
@mixin opacity($choice, $rgba, $opacity, $border-val: null, $border-type: null) {
	@if $choice == 'background' {

		background : rgba($rgba, $opacity);
	}@else if $choice == 'color' {

		color : rgba($rgba, $opacity);
	}@else if $choice == 'border' {

		border: $border-val $border-type rgba($rgba, $opacity);
	}@else if $choice == 'bacground-color' {

		background-color: rgba($rgba, $opacity);
	}
}

//mixin border opacity
@mixin border-opacity($color, $opacity) {
	$opacity: $opacity / 100;
	
	border-color: $color;

	border-color: rgba($color, $opacity);

	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

//mixin text clamps
@mixin textClamps($height, $line) {

	overflow: hidden;
	//text-overflow: ellipsis;
	max-height: $height;
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
}

@mixin notextClamps() {
	overflow: initial;
	display: block;
	-webkit-line-clamp: initial;
	-webkit-box-orient: initial;
	text-overflow: initial;
	max-height: 100%;

	&:after {display: none;}
}

//mixin clamps
@mixin the-camps($line, $line-height, $bg) {
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;  
	overflow: hidden;  
	text-overflow: ellipsis;
	max-height: calc(#{$line-height} * #{$line});

	&:after {
		position: absolute;
		content: '...';
		text-align: center;
		bottom: 0;
		right: 0;
		display: block;
		width: 11%;
		height: $line-height;
		background: $bg;
	}
}

//display
@mixin display($display) {
    display: $display;    
}

@mixin flex($flex-wrap: null, $flex-direction: null, $align: null, $justify: null) {
    @include vendor('display', 'flex');   
    flex-wrap: $flex-wrap;
    flex-direction: $flex-direction;

    @if ($align)  {
        -webkit-box-align: $align;
        -ms-flex-align: $align;
        -webkit-align-items: $align;
        align-items: $align;
    }

    @if ($justify) {
        -webkit-box-pack: $justify;
        -ms-flex-pack: $justify;
        -webkit-justify-content: $justify;
        justify-content: $justify;
    }   
}

//position
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    
    @if $position == 'relative' {

        position: relative;
    }
    
    @else if $position == 'absolute' {

        position: absolute;
        
        @if ($top)  {
            top: $top;
        }

        @if ($right) {
            right: $right;
        }
        
        @if($bottom) {
            bottom: $bottom;
        }
        
        @if($left) {    
            left: $left;
        }
    }

    @else if($position == 'fixed') {

        position: fixed;
        
        @if ($top)  {
            top: $top;
        }

        @if ($right) {
            right: $right;
        }
        
        @if($bottom) {
            bottom: $bottom;
        }
        
        @if($left) {    
            left: $left;
        }
    }
}

@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

//float
@mixin  float($float) {
    float: $float;
}

@mixin float-pos() {

	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

//padding margin
@mixin pm($pm, $top: null, $right: null, $bottom: null, $left: null) {
    
    //margin
    @if $pm == 'margin' {

        @if ($top)  {
            margin-top: $top;
        }

        @if ($right) {
            margin-right: $right;
        }
        
        @if($bottom) {
            margin-bottom: $bottom;
        }
        
        @if($left) {    
            margin-left: $left;
        }
    }

    //padding
    @else {
        @if ($top)  {
            padding-top: $top;
        }

        @if ($right) {
            padding-right: $right;
        }
        
        @if($bottom) {
            padding-bottom: $bottom;
        }
        
        @if($left) {    
            padding-left: $left;
        }
    }
}

//fonts
@mixin fonts($fonts:null, $fw: null, $fz: null, $lh: null, $align: null, $style: null, $spacing: null, $tt: null) {

    //$fonts : font-family
    @if($fonts) {
        
        font-family: $fonts;
    }

    //$fw : font-weight
    @if($fw) {

        font-weight: $fw;
    }

    //$fz : font-size
    @if($fz) {

        font-size: $fz;
    }

    //$lh : line-height
    @if($lh) {

        line-height: $lh;
    }

    //$align : text-align
    @if($align) {
        
        text-align: $align;
    }

    //$style : font-style
    @if($style) {

        font-style: $style;
    }

    @else {

        font-style: normal;
    }

    //$spacing : letter-spacing
    @if($spacing) {

        letter-spacing: $spacing;
    }

    //$tt : text-transform
    @if($tt) {

        text-transform: $tt;
    }
}

//image position
@mixin img($position: null, $fit: null, $ob: null) {
    
    //set position
    @if($position) {

        //position relative
        @if $position == 'relative' {
            
            position: relative;
        }

        @else {

            position: absolute;
            top: 0;
            left: 0;
        }
    }
    //set object-fit: cover
    @if($fit) {
        
        object-fit: cover;
    }

    //set object-position
    @if($ob) {
        
        object-position: $ob;
    }
}

//box shadow
@mixin box-shadow ($x, $y, $blur, $spread, $rgba: false, $opacity: null) {

    @if $rgba {
        -webkit-box-shadow: $x $y $blur $spread rgba($rgba, $opacity);
        -moz-box-shadow: $x $y $blur $spread rgba($rgba, $opacity);
        box-shadow: $x $y $blur $spread rgba($rgba, $opacity);
    }

    @else {
        box-shadow: none;
    }
}

//clearfix
@mixin clearfix() {
	&::after {
	  display: block;
	  clear: both;
	  content: "";
	}
}

//border radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	   -moz-border-radius: $radius;
		-ms-border-radius: $radius;
			border-radius: $radius;
}

@mixin set-border-radius($top-left, $bottom-left, $top-right, $bottom-right) {

    -webkit-border-top-left-radius: $top-left;
    -moz-border-radius-top-left: $top-left;
    -ms-border-radius-top-left: $top-left;
    border-top-left-radius: $top-left;

    -webkit-border-bottom-left-radius: $bottom-left;
    -moz-border-radius-bottom-left: $bottom-left;
    -ms-border-radius-bottom-left: $bottom-left;
    border-bottom-left-radius: $bottom-left;

    -webkit-border-top-right-radius: $top-right;
    -moz-border-radius-top-right: $top-right;
    -ms-border-radius-top-right: $top-right;
    border-top-right-radius: $top-right;

    -webkit-border-bottom-right-radius: $bottom-right;
    -moz-border-radius-bottom-right: $bottom-right;
    -ms-border-radius-bottom-right: $bottom-right;
    border-bottom-right-radius: $bottom-right;  
}

@mixin bg-gradient($bg-color, $first-color, $first-opacity-color, $second-color, $second-opacity-color) {
    background-color: $bg-color;
    background: linear-gradient(rgba($first-color, $first-opacity-color), rgba($second-color, $second-opacity-color));
    -moz-background: linear-gradient(rgba($first-color, $first-opacity-color), rgba($second-color, $second-opacity-color));
    -o-background: linear-gradient(rgba($first-color, $first-opacity-color), rgba($second-color, $second-opacity-color));
    -webkit-background: linear-gradient(rgba($first-color, $first-opacity-color), rgba($second-color, $second-opacity-color)); 
}