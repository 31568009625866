

/*  
    ============================================================= 
        PARTNER
        
        list partner.
    ============================================================= 
*/
.partner {
    position: relative;
    
    .wrapper-partner {
        position: relative;
        width: 100%;
        height: 100%;

        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 5%;

            img {
                width: auto;
                height: 100px;
                display: block;
                margin: 0 auto;
            }
        }
    }

    @include breakpoint('<=medium') {

        .wrapper-partner {

            a {
                position: relative;
                padding: calc((1/2) * 100%) 4px 0 4px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                    vertical-align: middle;
                    justify-content: center;
                }
            }
        }
    }
}

