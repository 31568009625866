.reviews {
    position: relative;
    width: 100%;
    margin-bottom: 32px;

    .wrp-reviews {
        position: relative;
        width: 100%;
        padding-left: 96px;

        .foto {
            position: absolute;
            top: 0;
            left: 0;
            width: 80px;
            height: 80px;
            @include border-radius(50%);
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            span {
                @include flex($align: center, $justify: center);
                width: 100%;
                height: 100%;
                font-size: 18px;
                line-height: 24px;
                color: $black;
                font-weight: 600;
                background: $gray-light;
            }
        }

        .the-reviews {
            position: relative;
            width: 100%;

            .name {
                position: relative;
                width: 100%;
                text-align: left;
                color: $black;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;

                span.date {
                    display: inline-block;
                    margin: 0 0 0 8px;
                    position: relative;
                    vertical-align: top;
                    font-size: 14px;
                    line-height: 24px;
                    color: rgba($black, .7);
                    font-weight: 400;
                }
            }

            .stars {
                position: relative;
                width: 100%;
                text-align: left;
                padding: 0 0 8px 0;

                i.icon {
                    width: 24px;
                    height: 24px;
                    padding: 0 0;

                    &:before {
                        font-size: 14px;
                        color: #ffd938;
                    }
                }
            }

            .review {
                position: relative;
                width: 100%;

                p {
                    width: 100%;
                    margin: 0 0 0 0;
                }
            }
        }
    }

    @include breakpoint('<=medium') {

        .wrp-reviews {
            padding-left: 76px;

            .foto {
                width: 60px;
                height: 60px;
            }
        }
    }

    @include breakpoint('<=small') {

        .wrp-reviews {
            padding-left: 66px;

            .foto {
                width: 50px;
                height: 50px;
            }
        }
    }
}