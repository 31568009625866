.activities {
    position: relative;

    .wrp-activities {
        position: relative;
        width: 100%;
        height: 100%;
        @include border-radius(3px);
        border: 1px solid rgb(229,231,233);
        box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;
        overflow: hidden;

        .activities-thumbs {
            position: relative;
            width: 100%;
            padding-top: calc((1 / 2) * 100%);

            a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .activities-info {
            position: relative;
            width: 100%;
            padding: 16px 16px 81px 16px;

            h4 {
                position: relative;
                width: 100%;
                text-align: left;
                margin-bottom: 8px;

                a {
                    color: $black;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    height: calc(30 * 2);
                }
            }

            .duration {
                position: relative;
                width: 100%;
                padding-left: 24px;
                margin-bottom: 16px;

                i.icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    padding: 0;

                    &:before {
                        color: rgba($black, .7);
                        text-align: left;
                    }
                }

                span {
                    display: inline-block;
                    width: auto;
                    vertical-align: top;
                    color: rgba($black, .7);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .activities-bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                min-height: 65px;
                width: 100%;
                @include flex(wrap, row, center, center);
                padding: 0 16px 16px 16px;

                .review {
                    width: 40%;
                    position: relative;
                    height: 100%;
                    @include flex(wrap, column, center, center);

                    .c-stars {
                        position: relative;
                        width: 100%;
                        text-align: left;
                        @include float-pos;

                        i.icon {
                            width: 14px;
                            height: 14px;
                            padding: 0 0;
                            margin-right: 4px;
                            float: left;

                            &:before {
                                color: #ffd938;
                                font-size: 14px;
                                line-height: 14px;
                                width: 100%;
                                height: 100%;
                                display: block;
                            }

                            &:last-of-type {margin-right: 0;}
                        }
                    }

                    .c-reviews {
                        position: relative;
                        width: 100%;
                        text-align: left;

                        span {
                            font-weight: 600;
                            display: inline-block;
                            margin: 0 0;
                            font-size: 12px;
                            line-height: 20px;
                            color: $black;
                        }
                    }
                }

                .price {
                    position: relative;
                    width: 60%;
                    height: 100%;
                    @include flex(wrap, column, center, center);

                    span {
                        position: relative;
                        width: 100%;
                        text-align: right;
                        font-size: 12px;
                        line-height: 12px;
                        display: block;
                    }

                    .the-price,
                    .discount-price {
                        position: relative;
                        width: 100%;
                        text-align: right;
                        display: block;

                        i.icon {
                            width: 24px;
                            height: 24px;
                            padding: 0 0;
                            margin-right: 8px;
                        }

                        span {
                            display: inline-block;
                            width: auto;
                            margin: 0 0;
                            font-size: 18px;
                            line-height: 24px;
                            font-weight: 600;
                            color: $black;
                            position: relative;
                        }
                    }

                    .discount-price {

                        i.icon {

                            &:before {
                                color: $primary;
                            }
                        }

                        span {
                            color: $primary;
                            font-size: 20px;
                            line-height: 25px;
                            position: relative;
                        }
                    }

                    &.has-discount {

                        .the-price {
                            padding-left: 0;

                            i.icon {
                                display: none;
                            }

                            span {

                                &:after {
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    width: 100%;
                                    height: 1px;
                                    background: $black;
                                    content: '';
                                    @include vendor('transform', 'tanslate(-50%, 0)');
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}