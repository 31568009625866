/*  
    ============================================================= 
        NEWS & BLOG
        
        css for list blog and news.
    ============================================================= 
*/

.news {
    position: relative;

    .wrapper-news {
        position: relative;
        width: 100%;
        height: 100%;
        @include border-radius(3px);
        overflow: hidden;
        @include box-shadow(0, 0, 23px, 0, #0a0a0a, 0.08);

        .thumbs {
            position: relative;
            width: 100%;
            padding-top: calc((1/1) * 100%);
            @include vendor('transition', ('all 0.5s'));
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                @include vendor('transition', ('all 0.5s'));
            }

            .c-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($primary, .2);
                @include vendor('transition', ('all 0.5s'));

                .wrapper-c-content {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    @include flex(wrap, column, center, flex-end);
                    padding: 16px;

                    h4 {
                        text-align: left;
                        color: $white;
                        margin: 0 0;
                        width: 100%;

                        a {
                            display: inline-block;
                            width: 100%;
                            text-align: left;
                            font-size: 18px;
                            line-height: 28px;
                            letter-spacing: 0;
                            font-size: $roboto;
                            font-weight: 500;
                            color: $white;
                            word-break: break-all;
                        }
                    }

                    p {
                        position: relative;
                        text-align: left;
                        margin: 0px 0 0 0;
                        font-size: 12px;
                        line-height: 22px;
                        font-weight: 400;
                        color: rgba($white, .8);

                        b {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        &:hover {
            cursor: pointer;

            .thumbs {

                img {
                    @include vendor('transform', 'scale(1.1)')
                }

                .c-content {
                    background: rgba($black, .9);
                }
            }
        }
    }

    @include breakpoint('<=small') {
        
        .wrapper-news {
    
            .thumbs {
    
                .c-content {
    
                    .wrapper-c-content {
    
                        h4 {
    
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.blog {
    position: relative;

    .wrp-blog {
        position: relative;
        width: 100%;
        height: 100%;
        @include border-radius(3px);
        overflow: hidden;
        @include box-shadow(0, 0, 23px, 0, #0a0a0a, 0.08);

        .blog-thumbs {
            position: relative;
            width: 100%;
            padding-top: calc((1/1) * 100%);
            @include vendor('transition', ('all 0.5s'));

            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: transparent;
                border: 0;
                outline: none;
                @include vendor('transition', ('all 0.5s'));
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;  
                    object-fit: cover;
                    object-position: center;
                    @include vendor('transition', ('all 0.5s'));
                }

                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($primary, .2);
                    content: '';
                    display: block;
                }
            }

            h4 {
                position: absolute;
                bottom: 16px;
                left: 0;
                width: 100%;
                padding: 0 16px;
                text-align: left;
                color: $white;
                z-index: 2;
            }
        }

        &:hover {
            cursor: pointer;

            .blog-thumbs {

                a {

                    img {
                        @include vendor('transform', 'scale(1.1)')
                    }
                }
            }
        }
    }

    @include breakpoint('<=xxsmall') {
    
        .wrp-blog {
    
            .blog-thumbs {
                padding-top: calc((1/2) * 100%);
            }
        }
        
    }
}