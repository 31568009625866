#wrapper {

    #main {

        .s-pages.blogs {

            .container {

                .side-nav {
                    float: right !important;
                    padding-right: 0 !important;
                    padding-left: 16px;

                    .w-side {

                        .side-blog {
                            position: relative;
                            width: 100%;

                            h5 {
                                width: 100%;
                                text-align: left;
                                margin-bottom: 24px;
                                padding-bottom: 16px;
                                position: relative;

                                &:after {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 1px;
                                    background: $gray-light;
                                    content: '';
                                    display: block;
                                }
                            }

                            .row {
                                margin: 0 0;
                                
                                .blog-side {
                                    position: relative;
                                    width: 100%;
                                    margin-bottom: 16px;

                                    .wrp-blog {
                                        position: relative;
                                        padding-left: 96px;

                                        .thumbs-blog {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 80px;
                                            height: 80px;
                                            overflow: hidden;
                                            @include border-radius(3px);

                                            a {
                                                display: block;
                                                width: 100%;
                                                height: 100%;
                                                color: transparent;
                                                border: 0;
                                                outline: none;
                                                text-decoration: none;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    display: block;
                                                    object-fit: cover;
                                                }
                                            }
                                        }

                                        .title-blog {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                            min-height: 80px;
                                            @include flex($align: center, $justify: center);

                                            a {
                                                width: 100%;
                                                text-align: left;
                                                color: $black;
                                                font-weight: 600;
                                                text-decoration: none;
                                                outline: none;
                                                line-height: 20px;
                                            }
                                        }
                                    }

                                    &:last-of-type {margin-bottom: 0;}
                                }
                            }
                        }
                    }
                }

                .side-content {
                    float: left !important;
                    padding-left: 0 !important;
                    padding-right: 16px;

                    .w-side {

                        .c-blog-detail {
                            position: relative;
                            width: 100%;

                            h2 {
                                position: relative;
                                width: 100%;
                                text-align: left;
                            }

                            .blog-detail {
                                position: relative;
                                width: 100%;

                                p {
                                    margin-top: 16px;
                                }

                                img {
                                    width: 100%;
                                    display: block;
                                    height: auto;
                                    margin-top: 16px;
                                }
                            }
                        }

                        .blog-another {
                            display: none;
                            position: relative;
                            width: 100%;
                            margin-top: 32px;

                            h3 {
                                position: relative;
                                width: 100%;
                                text-align: left;
                                margin-bottom: 24px;
                            }

                            .row {
                                margin: 0 0;

                                .swiper-container {
                                    position: relative;
                                    width: 100%;
    
                                    .swiper-wrapper {
                                        position: relative;

                                        .blog {
                                            width: 100%;
                                            padding: 0 0;
                                        }
                                    }

                                    .btn-prev,
                                    .btn-next {
                                        position: absolute;
                                        top: 50%;
                                        width: 32px;
                                        height: 32px;
                                        z-index: 2;
                                        display: block;
                                        background: rgba(255, 255, 255, .6);
                                        @include border-radius(50%);
                                        overflow: hidden;
                                        @include vendor('transition', ('all 0.5s'));
                                        @include vendor('transform', 'translate(0, -50%)');
    
                                        i.icon {
                                            width: 100%;
                                            height: 100%;
                                            display: block;
                                            padding: 4px;
    
                                            &:before {
                                                color: $primary;
                                            }
                                        }
    
                                        &:hover {
                                            cursor: pointer;
                                            background: $primary;
    
                                            i.icon {
    
                                                &:before {
                                                    color: $white;
                                                }
                                            }
                                        }
                                    }
    
                                    .btn-prev {left: 8px;}
                                    .btn-next {right: 8px;}
                                }
                            }
                        }
                    }
                }
            }

            @include breakpoint('<=medium') {
                
                .container {

                    .side-nav {
                        float: none !important;
                        padding-left: 0 !important;

                        .w-side {

                            .side-blog {display: none;}
                        }
                    }

                    .side-content {
                        float: none !important;
                        padding-right: 0 !important;

                        .w-side {

                            .blog-another {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}