#wrapper {

    #main {

        .s-pages.sc-villa-detail {
            
            .container {

                .detail-pic {
                    position: relative;
                    margin-bottom: 32px;
                    border: 1px solid rgba(49,53,59,0.12);
                    background: $white;
                    box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;
                    
                    .slide-picture {
                        position: relative;
                        width: 100%;

                        .swiper-container {

                            &.gallery-top {

                                .swiper-wrapper {

                                    .swiper-slide {

                                        .big-img {
                                            position: relative;
                                            width: 100%;
                                            padding-top: calc((9 / 16) * 100%);

                                            img {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                object-position: center;
                                            }
                                        }
                                    }
                                }
                            }

                            &.gallery-thumbs {
                                position: absolute;
                                left: 0;
                                bottom: 8px;
                                width: 100%;

                                .swiper-wrapper {
                                    justify-content: flex-end;

                                    .swiper-slide {

                                        .thumbs-img {
                                            position: relative;
                                            width: 100%;
                                            padding-top: calc((1/2) * 100%);
                                            @include border-radius(3px);
                                            border: 2px solid $white;
                                            overflow: hidden;

                                            img {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                object-position: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .facilities {
                        position: relative;
                        width: 100%;
                        padding: 16px 0;
                        text-align: center;

                        .c-facilities {
                            display: inline-block;
                            margin: 0 12px;

                            i.tnt {
                                display: block;
                                margin: 0 auto;
                                padding: 0 0;

                                &:before {
                                    font-size: 48px;
                                    line-height: 48px;
                                    width: 48px;
                                    height: 48px;
                                }
                            }

                            span {
                                display: block;
                                width: 100%;
                                text-align: center;
                                color: $black;
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 600;
                            }

                            a.more {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                display: block;
                                padding-top: 48px;
                                min-width: 48px;

                                &:before {
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    width: 48px;
                                    height: 48px;
                                    margin-left: -24px;
                                    font-family: $ico;
                                    font-size: 24px;
                                    line-height: 24px;
                                    text-align: center;
                                    padding: 12px;
                                    content: '\E808';
                                    color: $black;
                                }
                            }
                        }
                    }
                }

                .side-nav {
                    float: right;
                    padding-right: 0;
                    padding-left: 16px;
                }

                .side-content {
                    padding-left: 0;
                    padding-right: 16px;

                    .w-side {
                        padding: 16px;
                        border: 1px solid rgba(49,53,59,0.12);
                        background: $white;
                        box-shadow: rgba(49,53,59,0.12) 0px 1px 6px 0px;

                        .villa-detail {
                            position: relative;
                            width: 100%;
                            
                            .description-villa {
                                position: relative;
                                width: 100%;
                                margin-bottom: 32px;

                                p {
                                    position: relative;
                                    width: 100%;
                                    margin-bottom: 16px;
                                }
                            }

                            .promo-mobile {
                                position: relative;
                                width: 100%;
                                display: none;

                                .row {
                                    margin: 0 8px;

                                    .promo {
                                        position: relative;
                                        width: calc(100% / 2);
                                        padding: 0 8px;
                                        margin-bottom: 16px;

                                        a {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            color: transparent;
                                            border: 0;
                                            @include border-radius(5px);
                                            overflow: hidden;

                                            img {
                                                display: block;
                                                width: 100%;
                                                height: 100%;
                                                object-position: center;
                                                object-fit: cover;
                                            }
                                        }
                                    }
                                }
                            }

                            .tabs-villa {
                                position: relative;
                                width: 100%;
                                border: 0;

                                ul {
                                    padding: 0 0;
                                    margin: 0 0;
                                    position: relative;
                                    border: 0;
                                    background: transparent;;
                                    @include vendor('transition', ('all 0.5s',));

                                    li {
                                    background: $gray-light;
                                    border: 0;;
                                    @include vendor('transition', ('all 0.5s',));

                                    a {
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: $black;
                                        padding: 8px 16px;;
                                        @include vendor('transition', ('all 0.5s',));

                                        i.icon {
                                            width: 24px;
                                            height: 24px;
                                            padding: 0 0;
                                            display: none;;
                                            @include vendor('transition', ('all 0.5s',));

                                            &:before {
                                                color: $black;;
                                                @include vendor('transition', ('all 0.5s',));
                                            }
                                        }

                                        span {
                                            display: inline-block;
                                            color: $black;
                                            margin-left: 8px;;
                                            @include vendor('transition', ('all 0.5s',));
                                        }
                                    }
                                    
                                    &.ui-tabs-active,
                                    &.ui-state-active {
                                        border: 0;
                                        background: $primary;

                                        a {
                                            color: $white;

                                            i.icon {

                                                    &:before {
                                                        color: $white;
                                                    }
                                            }

                                            span {
                                                color: $white;
                                            }
                                        }
                                    }
                                    }
                                }

                                .c-tabs-villa {
                                    position: relative;
                                    width: 100%;
                                    padding: 16px 16px;
                                    border: 1px solid $gray-light;

                                    .rates {
                                        position: relative;
                                        width: 100%;

                                        .rates-header {
                                            position: relative;
                                            width: 100%;
                                            @include float-pos;
                                            background-color: $gray-light;

                                            .room,
                                            .sleeps,
                                            .stay,
                                            .rates,
                                            .book {
                                                position: relative;
                                                float: left;
                                                padding: 8px 8px;
                                                text-align: center;
                                                color: $black;
                                                font-size: 16px;
                                                line-height: 24px;
                                                letter-spacing: 1px;
                                                text-transform: none;
                                            }

                                            .room {
                                                width: 25%;
                                            }

                                            .sleeps, 
                                            .stay {
                                                width: 15%;
                                            }

                                            .rates {
                                                width: 25%;
                                            }

                                            .book {
                                                width: 20%;
                                            }
                                        }

                                        .rates-content {
                                            position: relative;
                                            width: 100%;
                                            @include flex($flex-wrap: wrap, $align: center, $justify: stretch);
                                            margin-top: 32px;
                                            padding-bottom: 16px;
                                            border-bottom: 1px solid $gray-light;

                                            .room,
                                            .sleeps,
                                            .stay,
                                            .rates,
                                            .book {
                                                position: relative;
                                                height: 100%;
                                                font-size: 14px;
                                                line-height: 24px;
                                                text-align: center;
                                                color: $black;
                                                padding: 0 8px;

                                                .list-room {
                                                    position: relative;
                                                    width: 100%;

                                                    img {
                                                        position: relative;
                                                        display: block;
                                                        width: 100%;
                                                        height: auto;
                                                    }

                                                    p {
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                        text-align: center;
                                                        color: $black;
                                                        margin: 8px 0 0 0;
                                                    }

                                                    ul {
                                                        padding: 0 0;
                                                        margin: 8px 0 0 0;

                                                        li {
                                                            position: relative;
                                                            width: 100%;
                                                            background: none;
                                                            font-size: 12px;
                                                            line-height: 20px;
                                                            text-align: left;
                                                            color: $green;
                                                            margin-bottom: 4px;
                                                            padding-left: 22px;

                                                            &:before {
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                width: 22px;
                                                                height: 22px;
                                                                font-family: $ico;
                                                                font-size: 14px;
                                                                line-height: 22px;
                                                                text-align: center;
                                                                color: $green;
                                                                content: '\E816';
                                                            }
                                                        }

                                                        &:last-of-type {margin-bottom: 0;}
                                                    }
                                                }

                                                .the-price {
                                                    position: relative;
                                                    width: auto;
                                                    display: inline-block;
                                                    padding-left: 48px;

                                                    i.icon {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        padding: 0 0;

                                                        &:before {
                                                            width: 100%;
                                                            height: 100%;
                                                            font-size: 32px;
                                                            line-height: 48px;
                                                            text-align: center;
                                                            color: $black;
                                                        }
                                                    }

                                                    span {
                                                        width: 100%;
                                                        text-align: left;
                                                        position: relative;

                                                        &.disc {
                                                            font-size: 18px;
                                                            line-height: 24px;
                                                            color: black;
                                                            display: inline-block;
                                                            width: auto;

                                                            &:before {
                                                                position: absolute;
                                                                top: 50%;
                                                                left: 0;
                                                                width: 100%;
                                                                height: 2px;
                                                                background-color: $primary;
                                                                content: '';
                                                                margin-top: -1px;
                                                            }
                                                        }  
                                                        
                                                        &.price {
                                                            display: block;
                                                            font-size: 24px;
                                                            line-height: 24px;
                                                        }
                                                    }

                                                    ul {
                                                        padding: 0 0;
                                                        margin: 8px 0 0 0;

                                                        li {
                                                            position: relative;
                                                            width: 100%;
                                                            background: none;
                                                            font-size: 12px;
                                                            line-height: 20px;
                                                            text-align: left;
                                                            color: $green;
                                                            margin-bottom: 4px;
                                                            font-weight: 600;
                                                            padding-left: 22px;

                                                            &:before {
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                width: 22px;
                                                                height: 22px;
                                                                font-family: $ico;
                                                                font-size: 14px;
                                                                line-height: 22px;
                                                                text-align: center;
                                                                color: $green;
                                                                content: '\E816';
                                                            }
                                                        }

                                                        &:last-of-type {margin-bottom: 0;}
                                                    }
                                                }

                                                a.btn {
                                                    width: 100%;
                                                    height: 40px;
                                                    padding-top: 8px;
                                                    padding-bottom: 8px;
                                                    margin-bottom: 0;

                                                    span {
                                                        text-transform: uppercase;
                                                    }
                                                }
                                            }

                                            .room {
                                                width: 25%;
                                            }

                                            .sleeps, 
                                            .stay {
                                                width: 15%;
                                            }

                                            .rates {
                                                width: 25%;
                                                text-align: center;
                                            }

                                            .book {
                                                width: 20%;
                                            }

                                            &:last-of-type {
                                                border-bottom: 0;
                                            }
                                        }
                                    }

                                    .facilities {
                                        position: relative;
                                        width: 100%;

                                        .row {
                                            margin: 0 -8px;

                                            .c-facilities {
                                                width: calc(100% / 3);
                                                padding: 0 8px;
                                                margin-bottom: 16px;

                                                h4 {
                                                    margin-bottom: 16px;
                                                    padding-bottom: 8px;
                                                    position: relative;
                                                    width: 100%;
                                                    display: block;

                                                    &:after {
                                                        position: absolute;
                                                        left: 0;
                                                        bottom: 0;
                                                        width: 100%;
                                                        height: 1px;
                                                        background-color: $primary;
                                                        content: '';
                                                    }
                                                }

                                                .box-facilities {
                                                    position: relative;
                                                    width: 100%;
                                                    margin-bottom: 8px;
                                                    font-size: 14px;
                                                    line-height: 24px;
                                                    padding-left: 24px;
                                                    color: rgba($black, .5);

                                                    &:before {
                                                        position: absolute;
                                                        top: 5px;
                                                        left: 5px;
                                                        width: 14px;
                                                        height: 14px;
                                                        content: '';
                                                        background-color: $gray-light;
                                                        @include border-radius(3px);
                                                    }

                                                    &:after {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        width: 24px;
                                                        height: 24px;
                                                        font-size: 14px;
                                                        line-height: 24px;
                                                        text-align: center;
                                                        color: rgba($black, .5);
                                                        font-family: $ico;
                                                        content: '\E817';
                                                    }

                                                    &.yes {

                                                        &:after {
                                                            color: rgba($primary, .6);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .form-review {
                                        position: relative;
                                        width: 100%;
                                        margin-bottom: 32px;

                                        form {
                                            position: relative;
                                            width: 100%;
                                        }
                                    }

                                    .l-review {
                                        position: relative;
                                        width: 100%;

                                        .review {
                                            position: relative;
                                            width: 100%;
                                            margin-bottom: 16px;
                                            padding: 8px 8px;
                                            background-color: $gray-light;
                                            @include border-radius(5px);

                                            .w-review {
                                                position: relative;
                                                width: 100%;
                                                padding-left: 66px;

                                                .photo-review {
                                                    position: absolute;
                                                    left: 0;
                                                    top: 0;
                                                    width: 50px;
                                                    height: 50px;
                                                    @include border-radius(50%);
                                                    background-color: rgba($primary, 6);
                                                    font-size: 24px;
                                                    line-height: 50px;
                                                    text-align: center;
                                                    color: $white;
                                                    @include flex($align: center, $justify: center);

                                                    img {
                                                        position: relative;
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                        object-position: center;
                                                    }
                                                }

                                                .c-review {
                                                    position: relative;
                                                    width: 100%;
                                                    
                                                    .review-header {
                                                        position: relative;
                                                        @include float-pos;

                                                        .name {
                                                            float: left;
                                                            width: calc(100% - 120px);
                                                            padding-right: 8px;

                                                            p {
                                                                position: relative;
                                                                width: 100%;
                                                                text-align: left;
                                                                font-size: 15px;
                                                                line-height: 24px;
                                                                font-weight: 500;
                                                                color: $black;
                                                                margin: 0 0;
                                                            }
                                                        }

                                                        .rating {
                                                            position: relative;
                                                            text-align: right;
                                                            width: 120px;
                                                            float: left;

                                                            i.icon {
                                                                width: 24px;
                                                                height: 24px;
                                                                padding: 0 0;
                                                                margin: 0 0;

                                                                &:before {
                                                                    color: rgba($primary, 1);
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .review-body {
                                                        position: relative;
                                                        width: 100%;
                                                        margin-top: 16px;

                                                        p {
                                                            margin: 0 0 16px 0;
                                                            text-align: left;
                                                        }

                                                        .plus {
                                                            position: relative;
                                                            width: 100%;
                                                            padding-left: 32px;
                                                            margin-bottom: 24px;

                                                            p {
                                                                margin: 0 0;
                                                                text-align: left;
                                                            }

                                                            &:before {
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                width: 24px;
                                                                height: 24px;
                                                                font-family: $ico;
                                                                font-size: 16px;
                                                                line-height: 24px;
                                                                color: rgba($primary, 1);
                                                                content: '\E803';
                                                            }
                                                        }

                                                        .minus {
                                                            position: relative;
                                                            width: 100%;
                                                            padding-left: 32px;

                                                            p {
                                                                margin: 0 0;
                                                                text-align: left;
                                                            }

                                                            &:before {
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                width: 24px;
                                                                height: 24px;
                                                                font-family: $ico;
                                                                font-size: 16px;
                                                                line-height: 24px;
                                                                color: rgba($primary, 1);
                                                                content: '\E804';
                                                            }
                                                            
                                                        }
                                                    }

                                                    .review-footer {
                                                        position: relative;
                                                        width: 100%;
                                                        text-align: right;
                                                        font-size: 12px;
                                                        line-height: 24px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .maps {
                                        position: relative;
                                        width: 100%;
                                        padding-top: calc((9/16) * 100%);

                                        iframe {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .area-nearby {
                                        position: relative;
                                        width: 100%;
                                        display: block;

                                        h4 {
                                            margin-top: 24px;
                                            position: relative;
                                            width: 100%;
                                            text-align: left;
                                        }

                                        ul {
                                            padding: 0 0;
                                            margin: 16px 0 0 0;
                                            position: relative;
                                            width: 100%;
                                            @include flex(wrap, row, );

                                            li {
                                                position: relative;
                                                width: 50%;
                                                background: none;
                                                padding-top: 8px;
                                                padding-bottom: 16px;

                                                &:after {
                                                    position: absolute;
                                                    bottom: 8px;
                                                    width: calc(100% - 12px);
                                                    height: 1px;
                                                    background: $gray-light;
                                                    content: '';
                                                    display: block;
                                                }

                                                &:nth-child(even) {
                                                    padding-left: 12px;

                                                    &:after {
                                                        left: 12px;
                                                    }
                                                }

                                                &:nth-child(odd) {
                                                    padding-right: 12px;

                                                    &:after {
                                                        left: 0px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .title-with-small-span {
                    float: left;
                }

                .list-villa {
                    position: relative;
                    width: 100%;
                    float: left;

                    .row {
                        margin: 0 -8px;

                        .villa {
                            width: calc(100% /4);
                            padding: 0 8px;
                            margin-bottom: 16px;
                        }
                    }
                }
            }

            @include breakpoint('<=large') {

                .container {

                    .side-nav {
                        float: left;
                        padding-left: 0;
                        width: 100%;

                        .w-side {
                            @include float-pos;

                            .side-promo {
                                float: left;
                                width: 33.33%;
                                padding: 0 8px;
                                margin-bottom: 16px;

                                .wrapper-side-promo {
                                    padding-top: calc((1 / 2) * 100%);

                                    a {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;

                                        img {
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center;
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .side-content {
                        width: 100%;
                        padding-right: 0;
                    }
                }
            }

            @include breakpoint("<=medium") {

                .container {

                    .side-nav {
                        display: none;

                        .w-side {

                            .side-promo {
                                display: none;
                            }
                        }
                    }

                    .side-content {
                        float: none;
                            
                        .w-side {
    
                            .villa-detail {
    
                                .promo-mobile {
                                    display: block;
                                    margin-bottom: 32px;
                                }
    
                                .tabs-villa {
    
                                    ul {
    
                                        li {
                                            
                                            a {
                                                font-size: 15px;
    
                                                i.icon {
                                                    display: inline-block;
                                                }
    
                                                span {
                                                    display: none;
                                                    opacity: 0;
                                                    width: 0;
                                                    margin-left: 8px;
                                                }
                                            }
                                            
                                            &.ui-tabs-active,
                                            &.ui-state-active {
    
                                                a {
                                                    span {
                                                        display: inline-block;
                                                        width: auto;
                                                        opacity: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                    .c-tabs-villa {
                                        padding: 16px 16px;
                                        
                                        .rates {
    
                                            .rates-header {
                                                
                                                .room,
                                                .sleeps,
                                                .stay,
                                                .rates,
                                                .book {
                                                    font-size: 15px;
                                                }
    
                                                .room {
                                                    width: 30%;
                                                }
    
                                                .sleeps, 
                                                .stay {
                                                    width: 15%;
                                                }
    
                                                .rates {
                                                    width: 40%;
                                                }
    
                                                .book {
                                                    width: 100%;
                                                    display: none;
                                                }
                                            }
    
                                            .rates-content {
                                                flex-wrap: wrap;
    
                                                .room {
                                                    width: 30%;
                                                }
    
                                                .sleeps, 
                                                .stay {
                                                    width: 15%;
                                                }
    
                                                .rates {
                                                    width: 40%;
                                                }
    
                                                .book {
                                                    width: 100%;
                                                    text-align: center;
                                                    margin-top: 8px;
    
                                                    a.btn {
                                                        width: auto;
                                                    }
                                                }
                                            }
                                        }
    
                                        .facilities {
    
                                            .row {
    
                                                .c-facilities {
                                                    width: calc(100% / 2);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .title-with-small-span  {
                        float: none;  
                        margin-bottom: 0;
                        padding-bottom: 24px;
                    }
                    
                    .list-villa {
                        float: none; 
                    }
                }
            }

            @include breakpoint("<=small") {

                .container {

                    .detail-pic {
                        margin-bottom: 24px;

                        .facilities {

                            .c-facilities {
                                margin: 0 8px;

                                i.tnt {
                                    width: 32px;
                                    height: 32px;

                                    &:before {
                                        width: 32px;
                                        height: 32px;
                                        line-height: 32px;
                                        font-size: 24px;
                                    }
                                }

                                span {
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }

                    .side-content {

                        .w-side {
        
                            .villa-detail {
                                
                                .description-villa {
                                    margin-bottom: 24px;
                                }

                                .promo-mobile {
                                    margin-bottom: 8px;

                                    .row {
                                        margin: 0 -8px;

                                        .promo {
                                            width: 50%;
                                        }
                                    }
                                }

                                .tabs-villa {
    
                                    ul {
    
                                        li {
                                            
                                            a {
                                                font-size: 14px;
                                            }
                                        }
                                    }
    
                                    .c-tabs-villa {
                                        padding: 16px 8px;

                                        .rates {
    
                                            .rates-header {
                                                
                                                .room,
                                                .sleeps,
                                                .stay,
                                                .rates,
                                                .book {
                                                    font-size: 14px;
                                                }
    
                                                .room {
                                                    width: 60%;
                                                }
    
                                                .sleeps, 
                                                .stay {
                                                    width: 20%;
                                                }
    
                                                .rates {
                                                    width: 50%;
                                                    display: none;
                                                }
                                            }
    
                                            .rates-content {
                                                align-items: flex-start;

                                                .room {
                                                    width: 60%;
                                                }
    
                                                .sleeps, 
                                                .stay {
                                                    width: 20%;
                                                }
    
                                                .rates {
                                                    width: 100%;

                                                    .the-price {
                                                        text-align: left;
                                                    }
                                                }
                                            }
                                        }
    
                                        .facilities {
    
                                            .row {
    
                                                .c-facilities {
                                                    width: calc(100% / 2);

                                                    a.more {
                                                        color: $black;
                                                        position: relative;
                                                        width: 100%;
                                                        padding-top: 48px;

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .list-villa {

                        .row {

                            .villa {
                                width: calc(100% / 2);
                            }
                        }
                    }
                }
            }

            @include breakpoint('<=xsmall') {

                .container {

                    .side-content {

                        .w-side {
        
                            .villa-detail {
                                
                                .description-villa {
                                    margin-bottom: 24px;
                                }

                                .promo-mobile {
                                    margin-bottom: 8px;

                                    .row {
                                        margin: 0 -8px;

                                        .promo {
                                            width: 50%;
                                        }
                                    }
                                }

                                .tabs-villa {
    
                                    ul {
    
                                        li {
                                            
                                            a {
                                                font-size: 14px;
                                                padding: 8px 16px 8px 4px;
                                            }
                                        }
                                    }
    
                                    .c-tabs-villa {
                                        padding: 16px 8px;

                                        .rates {
    
                                            .rates-header {
                                                
                                                .room,
                                                .sleeps,
                                                .stay,
                                                .rates,
                                                .book {
                                                    font-size: 14px;
                                                }
    
                                                .room {
                                                    width: 100%;
                                                }
    
                                                .sleeps, 
                                                .stay {
                                                    width: 20%;
                                                    display: none;
                                                }
                                            }
    
                                            .rates-content {
                                                align-items: flex-start;

                                                .room {
                                                    width: 100%;
                                                }
    
                                                .sleeps {
                                                    width: 50%;
                                                    text-align: right;
                                                    padding-right: 16px;
                                                    font-weight: 600;
                                                } 
                                                .stay {
                                                    width: 50%;
                                                    text-align: left;
                                                    padding-left: 16px;
                                                    font-weight: 600;
                                                }
    
                                                .rates {
                                                    width: 100%;

                                                    .the-price {
                                                        text-align: left;
                                                    }
                                                }
                                            }
                                        }
    
                                        .facilities {
    
                                            .row {
    
                                                .c-facilities {
                                                    width: calc(100% / 2);

                                                    a.more {
                                                        color: $black;
                                                        position: relative;
                                                        width: 100%;
                                                        padding-top: 48px;

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .list-villa {

                        .row {

                            .villa {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        } 
    }
}