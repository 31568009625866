// Button 
.button,
.btn {
    @include vendor('appearance', 'none');
    @include vendor('transition', ('all .5s ease-in-out'));
    display: inline-block;
    width: auto;
    height: 48px;
    @include border-radius(5px);
    margin-bottom: 16px;
    margin-left: 0;
    padding: 12px 32px;
    text-align: center;
    overflow: hidden;
    background: transparent;
    border: 0;
    outline: none;
    position: relative;
    vertical-align: middle;

    span {
        display: inline-block;
        width: auto;
        @include fonts($barlow, 500, 14px, 24px, center, $spacing: 0.5px);
        @include vendor('transition', ('all .5s ease-in-out'));
        position: relative;
    }

    //hover show the shadow
    &:hover {
        cursor: pointer;
        @include box-shadow(0, 3px, 8px, 0, $black, .5);
    }

    &:last-of-type {
        @include pm('margin', $right: 0);
    }

    //button with icon
    &.with-icon {
        @include pm('padding', $left: 16px);

        span {
            vertical-align: top;
        }

        i.icon {
            width: 24px;
            height: 24px;
            @include pm('margin', $right: 8px);
            vertical-align: top;
            padding: 0 0;

            &:before {
                @include position('absolute', $top: 0, $left: 0);
                @include fonts($align: center !important);
                @include fonts($fz: 14px, $lh: 24px);
            }
        }

    }

    //button primary
    &.primary {
        background: $primary;

        span {
            color: $white;
        }

        .icon {

            &:before {
                color: $white;
            }
        }

        &:hover {
            @include box-shadow(0, 3px, 8px, 0, $primary, .5);
        }
    }

    //button primary
    &.secondary {
        background: $secondary;

        span {
            color: $white;
        }

        .icon {

            &:before {
                color: $white;
            }
        }

        &:hover {
            @include box-shadow(0, 3px, 8px, 0, $secondary, .5);
        }
    }

    @include breakpoint('<=small') {
        height: 40px;
        padding: 8px 24px;

        span {
            @include fonts($fz: 12px);
        }

        .icon {

            &:before {
                @include fonts($fz: 12px);
            }
        }

        //button with icon
        &.with-icon {
            @include pm('padding', $left: 12px);

        }
    }

    @include breakpoint('<=small') {
        width: 100%;
        @include pm('padding', $right:16px, $left: 16px);
        @include pm('margin', $right: 0, $bottom: 16px);

        //button with icon
        &.with-icon {
            @include pm('padding', $left: 0px);

        }
    }
}

//button large size : 56px
.btn-large {
    @extend .btn;
    height: 56px;
    @include pm('padding', $top: 16px, $bottom: 16px);

    span {	
        @include fonts($fz: 16px, $lh: 24px);
    }

    .icon {

        &:before {
            @include fonts($fz: 16px);
        }
    }

    @include breakpoint('<=small') {
        height: 48px;
        @include pm('padding', $top: 12px, $bottom: 12px);

        span {
            @include fonts($fz: 14px, $lh: 24px);
        }

        .icon {
            @include fonts($fz: 14px);
        }
    }

    @include breakpoint('<=small') {
        width: 100%;
        @include pm('padding', $left: 0, $right: 0);
        @include pm('margin', $right: 0, $bottom: 16px);
    }
}

//button small size : 32px
.btn-small {
    @extend .btn;
    height: 32px;
    @include pm('padding', $top: 4px, $bottom: 4px);

    span {
        @include fonts($fz: 10px, $lh: 24px);
    }

    .icon {

        &:before {
            
            @include fonts($fz: 10px);
        }
    }

    @include breakpoint('<=small') {
        width: 100%;
        @include pm('padding', $left: 0, $right: 0);
        @include pm('margin', $right: 0, $bottom: 16px);
    }
}

//btn border
.btn-border {
    @extend .btn;
    border: 1px solid;
    background: #fff;
    @include pm('padding', $top: 11px, $bottom: 11px);

    //button with icon
    &.with-icon {
        @include pm('padding', $left: 16px);

        span {
            vertical-align: top;
        }

        i.icon {
            width: 24px;
            height: 24px;
            @include pm('margin', $right: 8px);
            vertical-align: top;
            @include pm('padding');

            &:before {
                @include position('absolute', $top: 0, $left: 0);
                @include fonts($align: center !important);
                @include fonts($fz: 14px);
            }
        }

    }

    &.primary {
        background: none;
        border-color: $primary;

        span {
            color: $primary;
        }

        .icon {

            &:before {
                color: $primary;
            }
        }
    }

    &.secondary {
        background: none;
        border-color: $secondary;

        span {
            color: $secondary;
        }

        .icon {

            &:before {
                color: $secondary;
            }
        }
    }

    @include breakpoint('<=small') {
        
        @include pm('padding', $top: 7px,$bottom: 7px);
    }
}

//button border large size : 56px
.btn-large-border {
    @extend .btn-large;
    @extend .btn-border;
    @include pm('padding', $top: 15px, $bottom: 15px);

    @include breakpoint('<=small') {

        @include pm('padding', $top: 11px, $bottom: 11px);
    }
}

//button small size : 32px
.btn-small-border {
    @extend .btn-small;
    @extend .btn-border;
    @include pm('padding', $top: 3px, $bottom: 3px);
    background: none;
    border: 1px solid;
}

