#wrapper {

    #main.p-home {  
        position: relative;
        width: 100%;

        .recommend-villa {
            position: relative;
            width: 100%;

            .wrapper-recommend-villa {
                position: relative;
                width: 100%;

                .container {
                    
                    .l-recommend-villa {
                        position: relative;
                        width: 100%;

                        .row {
                            margin: 0 0px;

                            .swiper-container {
                                padding-bottom: 16px !important;

                                .swiper-wrapper {

                                    .swiper-slide {

                                        .villa {
                                            padding: 0 0px;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }

                                .rv-prev {
                                    position: absolute;
                                    top: 30%;
                                    left: 0;
                                    width: 56px;
                                    height: 48px;
                                    background-color: $primary;
                                    z-index: 2;
                                    outline: none;

                                    &:before {
                                        position: relative;
                                        display: block;
                                        margin: 12px auto;
                                        width: 24px;
                                        height: 24px;
                                        font-family: $ico;
                                        font-size: 24px;
                                        line-height: 24px;
                                        text-align: left;
                                        color: $white;
                                        content: '\E812';
                                    }

                                    &:focus {
                                        outline: none;
                                    }

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }

                                .rv-next {
                                    position: absolute;
                                    top: 30%;
                                    right: 0;
                                    width: 56px;
                                    height: 48px;
                                    background-color: $primary;
                                    z-index: 2;
                                    outline: none;

                                    &:before {
                                        position: relative;
                                        display: block;
                                        margin: 12px auto;
                                        width: 24px;
                                        height: 24px;
                                        font-family: $ico;
                                        font-size: 24px;
                                        line-height: 24px;
                                        text-align: right;
                                        color: $white;
                                        content: '\E813';
                                    }

                                    &:focus {
                                        outline: none;
                                    }

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .search-type {
            position: relative;
            width: 100%;

            .wrapper-search-type {
                position: relative;
                width: 100%;

                .container {

                    .list-by-type {
                        position: relative;
                        width: 100%;

                        .row {
                            margin: 0 0;

                            .swiper-container {
                                position: relative;
                                width: 100%;

                                .swiper-wrapper {
                                    position: relative;

                                    .c-by-type {
                                        width: 100%;
                                        padding: 0 0;
                                    }
                                }

                                .bt-prev,
                                .bt-next {
                                    position: absolute;
                                    top: 30%;
                                    width: 32px;
                                    height: 32px;
                                    z-index: 2;
                                    display: block;
                                    background: rgba(255, 255, 255, .6);
                                    @include border-radius(50%);
                                    overflow: hidden;
                                    @include vendor('transition', ('all 0.5s'));

                                    i.icon {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                        padding: 4px;

                                        &:before {
                                            color: $primary;
                                        }
                                    }

                                    &:hover {
                                        cursor: pointer;
                                        background: $primary;

                                        i.icon {

                                            &:before {
                                                color: $white;
                                            }
                                        }
                                    }
                                }

                                .bt-prev {left: 8px;}
                                .bt-next {right: 8px;}
                            }
                        }
                    }
                }

            }
        }

        .promo-villa {
            position: relative;
            width: 100%;
        
            .wrapper-promo-villa {

                .container {

                    .l-area {
                        position: relative;
                        width: 100%;

                        .row {
                            margin-right: -8px;
                            margin-left: -8px;

                            .area {
                                width: 33.33%;
                                padding: 0 8px;
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }

        .s-banner-promo {
            position: relative;
            width: 100%;
            
            .container {
                
                .banner-promo {
                    position: relative;
                    width: 100%;
    
                    .wrapper-banner-promo {
                        position: relative;
                        @include flex($align: stretch);
    
                        .content-image {
                            width: 50%;
                            @include set-border-radius(3px, 3px, 0, 0);
                            overflow: hidden;
    
                            .wrapper-content-image {
                                width: 100%;
                                height: 100%;
                                position: relative;
                                padding-top: calc((1/2) * 100%);
    
                                img {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }
    
                        .content-promo {
                            position: relative;
                            width: 50%;
                            @include set-border-radius(0, 0, 3px, 3px);
                            overflow: hidden;
    
                            .wrapper-content-promo {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                @include flex(wrap, column, center, center);
                                @include bg-gradient(#f97251, #f97251, 1, #FF9966, 1);
    
                                h4 {
                                    color: #ededed;
                                    font-weight: 400;
                                }
    
                                h2 {
                                    color: $white;
                                    margin-bottom: 16px;
                                }
    
                                a.btn {
                                    height: auto;
                                    padding: 8px 16px;
                                    @include border-radius(3px);
                                    background: rgba($black, 1);
    
                                    span {
                                        color: $white;
                                        font-size: 12px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }

                    .row {
                        margin: 0 -16px;

                        .c-banner-promo {
                            position: relative;
                            width: 50%;
                            padding: 16px;

                            .w-promo {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                padding: 16px;
                                @include flex(wrap, row, center, center);
                                border: 1px solid #dee0e2;

                                .thumb {
                                    position: relative;
                                    width: 100px;
                                    height: 100px;
                                    @include border-radius(3px);

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        position: relative;
                                        display: block;
                                    }
                                }

                                .desc {
                                    position: relative;
                                    width: calc(100% - 100px - 24px);
                                    margin-left: 24px;
                                    
                                    h5 {
                                        position: relative;
                                        text-align: left;
                                        width: 100%;
                                        margin-bottom: 8px;
                                    }

                                    p {
                                        display: block;
                                        width: 100%;
                                        margin: 0 0;
                                        text-align: left;

                                        a {
                                            color: rgba($black, .5);
                                            text-align: left;
                                            max-height: calc(24px * 2);
                                            overflow: hidden;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .s-news {
            position: relative;
            width: 100%;
            
            .wrapper-s-news {
    
                .container {
    
                    .list-news {
                        position: relative;
                        width: 100%;
    
                        .row {
                            margin: 0 -8px;
    
                            .news {
                                width: calc(100% / 4);
                                padding: 0 8px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=xlarge') {

        }

        @include breakpoint('<=large') { 
        }

        @include breakpoint('<=medium') {
            
            .recommend-villa {

                .wrapper-recommend-villa {

                    .container {

                        .l-recommend-villa {

                            .row {

                                .swiper-container {
                                    padding-right: 56px;

                                    .rv-prev,
                                    .rv-next {display: none;}
                                }
                            }
                        }
                    }
                }
            }

            .search-type {

                .wrapper-search-type {

                    .container {

                        .list-by-type {

                            .row {
                                margin: 0 0;
                            }
                        }
                    }

                }
            }

            .promo-villa {

                .wrapper-promo-villa {

                    .container {
    
                        .l-area {
    
                            .row {
    
                                .area {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }

            .s-banner-promo {
                
                .container {
                    
                    .banner-promo {

                        .row {
                            margin: 0 -16px;

                            .c-banner-promo {
                                width: 100%;
                                padding: 0 16px;
                                margin-bottom: 24px;
                                
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .s-news {

                .wrapper-s-news {

                    .container {
    
                        .list-news {
    
                            .row {
    
                                .news {
                                    width: 50%;
                                    margin-bottom: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint('<=small') {

            .recommend-villa {

                .wrapper-recommend-villa {

                    .container {

                        .l-recommend-villa {

                            .row {

                                .swiper-container {
                                    padding-right: 48px;
                                }
                            }
                        }
                    }
                }
            }

            .s-banner-promo {

                .container {

                    .banner-promo {
        
                        .wrapper-banner-promo {
                            
                            .content-image {
                                width: 100%;
                                @include set-border-radius(3px, 3px, 3px, 3px);
        
                                .wrapper-content-image {
                                    padding-top: calc((1/2) * 100%);
                                }
                            }
        
                            .content-promo {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                @include border-radius(0);
        
                                .wrapper-content-promo {
                                    padding: 16px 16px;
                                    @include bg-gradient(rgba(#f97251, .7), #f97251, .7, #FF9966, .7);
        
                                    a.btn {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }

        @include breakpoint('<=xsmall') {
            
            .recommend-villa {

                .wrapper-recommend-villa {

                    .container {

                        .l-recommend-villa {

                            .row {

                                .swiper-container {
                                    padding-right: 32px;
                                }
                            }
                        }
                    }
                }
            }

            .promo-villa {

                .wrapper-promo-villa {

                    .container {
    
                        .l-area {
    
                            .row {
    
                                .area {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .s-banner-promo {

                .container {

                    .banner-promo {
        
                        .wrapper-banner-promo {
                            
                            .content-image {
        
                                .wrapper-content-image {
                                    padding-top: calc((1/1) * 100%);
                                }
                            }
                        }
                    }
                }
            }

            .s-news {

                .wrapper-s-news {

                    .container {
        
                        .list-news {
        
                            .row {
        
                                .news {
                                    width: 100%;
                                    margin-bottom: 16px;
    
                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}