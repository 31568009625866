// Colors
$base-color: #000000;
$accent-color: blue;

// Font size
$base-font-size: 14px;
$base-line-height: 1.7;

// Fonts
$base-font: 'Open Sans', sans-serif;

// Misc.
$misc: (
    z-index-base:		10000
);

// Duration.
$duration: (
    menu:				0.5s,
    transition:			0.2s
);

// Size.
$size: (
    element-height:		3rem,
    element-margin:		2rem,
    padding:			2rem,
    wrapper:			72rem
);

// Font.
$font: (
    family:				('Open Sans', serif),
    family-heading:		('Roboto', sans-serif),
    family-fixed:		('Roboto', sans-serif),
    weight:				400,
    weight-bold:		600,
    weight-heading:		900
);

// Palette.
$palette: (
    wrapper-bg:			#212931,

    bg:					#ffffff,
    fg:					#212931,
    fg-bold:			#212931,
    fg-light:			mix(#212931, #ffffff, 50%),
    border:				mix(#dcdcdc, #ffffff, 50%),
    border-bg:			rgba(#dcdcdc, 0.25),
    accent:				#18bfef,

    alt: (
        bg:				#f5f5f5,
        fg:				#717981,
        fg-bold:		#717981,
        fg-light:		mix(#717981, #f5f5f5, 50%),
        border:			mix(#dcdcdc, #f5f5f5, 75%),
        border-bg:		rgba(#dcdcdc, 0.5),
        accent:			#18bfef,
    ),

    invert: (
        bg:				#1e252d,
        bg-alt:			#1e252d,
        fg:				#ffffff,
        fg-bold:		#ffffff,
        fg-light:		rgba(#ffffff, 0.5),
        border:			#ffffff,
        border-bg:		rgba(#ffffff,0.075),
        accent:			#18bfef,
    ),
);

//path asset images and fonts
//how to use
//bakground-image : url('#{$path-img}/logo.png')
$path-img : 'images';
$path-font: '../fonts/';

//declare all color
//fonts
$opensans           : "Open Sans", sans-serif;
$ico                : "ico", sans-serif;
$roboto 			: "Roboto", sans-serif;
$arca 				: "Arca", sans-serif;
$libre 				: "Libre Baskerville", sans-serif;
$barlow				: "Barlow", sans-serif;
$tnt                : "tnt";

//color 
$black              : #000000;
$blue               : #007bff;
$indigo             : #6610f2;
$purple             : #6f42c1;
$pink               : #e83e8c;
$red                : #dc3545;
$orange             : #fd7e14;
$yellow             : #ffc107;
$green              : #28a745;
$teal               : #20c997;
$cyan               : #17a2b8;
$white              : #fff;
$gray               : #868e96;
$gray-light 		: #f5f5f5;
$gray-dark          : #343a40;
$gray-smoth         : #cccccc;
$primary            : #c22600;
$secondary          : #ffb677;
$third 				: #5f6caf;
$fourth 			: #edf7fa;
$success            : #28a745;
$info               : #17a2b8;
$warning            : #ffc107;
$danger             : #dc3545;
$light              : #f8f9fa;
$dark               : #343a40;

//icon social media color
$facebook 			: #3C5A99;
$twitter 			: #1da1f2;
$youtube 			: #ff0000;
$instagram   		: #d6249f;
$instagram-gradient : radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$linkedin			: #007bb5;
$google 			: #4285f4;
$snapchat 			: #fffc00;
$whatsapp			: #25d366;

//padding and margin
$pm_default    : 0 !default;
$pm_xlarge     : 64px;
$pm_large      : 48px;
$pm_medium     : 32px;
$pm_small      : 16px;
$pm_xsmall     : 8px;
