/*  
    ============================================================= 
        HEADER
        set css header
    ============================================================= 
*/

//css header global
#header {
	position: relative;
	width: 100%;
	background: $white;
	z-index: 5;
    @include vendor('transition', ('transform 0.10s ease-in-out'));
		
	.w-header {
		position: relative;
		padding: 0 0;

		.navbar {
            position: relative;
            width: 100%;
            @include vendor('transition', ('all .5s'));

            .container {

                .navbar-brand {
                    position: absolute;
                    top: 13px;
                    left: 32px;
                    width: 127px;
                    height: 119px;
                    z-index: 2;
                    @include vendor('transition', ('all .3s'));

                    a {
                        display: block;
                        width: 127px;
                        height: 119px;
                        color: transparent;
                        border: 0;
                        outline: none;
                        text-decoration: none;
                        background: url('../img/logo.png') no-repeat;
                        background-size: 100% 100%;
                        @include vendor('transition', ('all .3s'));
                    }
                }

                .navbar-top {
                    position: relative;
                    width: 100%;
                    padding-left: calc(137px + 16px);
                    @include float-pos;

                    .right-content {
                        float: right;
                        @include flex(wrap, row);

                        .login-register {
                            position: relative;
                            @include flex;
                            width: auto;
                            margin-right: 16px;
                            border-right: 1px solid #e1e1e1;
                            padding: 8px 0;

                            a.login,
                            a.register {
                                display: inline-block;
                                width: auto;
                                margin: 0 16px 0 0;
                                color: #94999f;
                                position: relative;
                                text-transform: uppercase;
                                vertical-align: middle;
                                height: 24px;

                                i.icon {
                                    padding: 0 0;
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 8px;

                                    &:before {  
                                        width: 100%;
                                        height: 100%;
                                        color: #94999f; 
                                    }
                                }

                                span {
                                    display: inline-block;
                                    color: #94999f;
                                    font-weight: 500;
                                    line-height: 24px;
                                    font-size: 14px;
                                    letter-spacing: 1px;
                                    position: relative;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .price-language {
                            position: relative;
                            @include flex;
                            width: auto;
                            margin-left: 16px;
                            padding: 0 0;

                            .select-box {
                                display: inline-block;
                                margin: 0 0;
                                position: relative;
                                width: auto;
                                min-width: 50px;

                                .selectric-wrapper {
                                    width: 100%;
                                    
                                    .selectric {
                                        @include border-radius(0px);
                                        background: transparent;
                                        height: 40px;
                                        padding: 8px 0;
            
                                        span {
                                            color: #94999f;
                                            height: auto;
                                            font-size: 14px;
                                            line-height: 24px;
                                            text-transform: uppercase;
                                            font-weight: 500;
                                            text-align: center;
                                        }
            
                                        .button,
                                        &:before {
                                            display: none;
                                        }
                                    }
                        
                                    .selectric-items {
                                        background: rgba($white, 1);
                                        width: 100% !important;
                                        
                                        .selectric-scroll {
            
                                            ul {
            
                                                li {
                                                    text-align: center;
                                                    font-size: 14px;
                                                    line-height: 24px;
                                                    text-transform: uppercase;
                                                }
                                            }
                                        }
                                    }
                        
                                    &.selectric-open {
                        
                                        .selectric {
                                            @include set-border-radius(2px, 0px, 2px, 0px);
                                        }
                                    }
            
                                    &.selectric-money {
            
                                        .selectric {
            
                                            &:before {
                                                content: '\E830';
                                            }
                                        }
                                    }
            
                                    &.selectric-language {
            
                                        .selectric {
            
                                            &:before {
                                                content: '\E82F';
                                            }
                                        }
                                    }
                                }
                            }

                            .language {
                                display: inline-block;
                                margin: 0 8px;
                                width: auto;
                                position: relative;

                                span {
                                    position: relative;
                                    width: 100%;
                                    color: #94999f;
                                    line-height: 24px;
                                    font-weight: 400;
                                    letter-spacing: 1px;
                                    padding: 12px 0 12px 48px;
                                    display: block;

                                    &:before {
                                        position: absolute;
                                        top: 12px;
                                        left: 6px;
                                        width: 24px;
                                        height: 24px;
                                        content: '';
                                        display: block;
                                    }

                                    &.indonesia {

                                        &:before {
                                            background: url('../img/flag-id.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.english {

                                        &:before {
                                            background: url('../img/flag-usd.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.japan {

                                        &:before {
                                            background: url('../img/flag-jpn.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }
                                }

                                .c-language {
                                    display: none;
                                    position: absolute;
                                    top: 48px;
                                    left: 0;
                                    width: auto;
                                    height: 0;
                                    min-width: 160px;
                                    background-color: $white;
                                    z-index: 2;
                                    padding: 16px 8px;
                                    @include box-shadow(0, 3px, 8px, 0, $black, .2);
                                    @include set-border-radius(0, 3px, 0, 3px);

                                    a.flag {
                                        display: block;
                                        width: 100%;
                                        position: relative;
                                        margin-bottom: 16px;
                                        color: $black;
                                        padding-left: 32px;
                                        text-transform: none;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 24px;

                                        &:before {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 24px;
                                            height: 24px;
                                            content: '';
                                        } 

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }
                                        
                                        &.id {

                                            &:before {
                                                background: url('../img/flag-id.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
    
                                        &.en {
    
                                            &:before {
                                                background: url('../img/flag-usd.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
    
                                        &.jp {
    
                                            &:before {
                                                background: url('../img/flag-jpn.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    cursor: pointer;
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: 48px;
                                    left: 8px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }

                                &.active {
                                    @include vendor('transition', ('all 0.5s ease'));

                                    .c-language {
                                        display: block;
                                        @include vendor('transition', ('transform 0.5s ease'));
                                        height: auto;
                                    }

                                    &:after {
                                        display: none;
                                    }
                                }
                            }

                            .currency {
                                display: inline-block;
                                margin: 0 8px;
                                width: auto;
                                position: relative;

                                span {
                                    position: relative;
                                    width: 100%;
                                    color: #94999f;
                                    line-height: 24px;
                                    font-weight: 400;
                                    letter-spacing: 1px;
                                    padding: 12px 0 12px 48px;
                                    display: block;

                                    &:before {
                                        position: absolute;
                                        top: 12px;
                                        left: 6px;
                                        width: 24px;
                                        height: 24px;
                                        content: '';
                                        display: block;
                                    }
                                    
                                    i.icon {
                                        padding: 0 0;
                                        width: 24px;
                                        height: 24px;
                                    }

                                    &.indonesia {

                                        &:before {
                                            background: url('../img/flag-id.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.english {

                                        &:before {
                                            background: url('../img/flag-usd.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.japan {

                                        &:before {
                                            background: url('../img/flag-jpn.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }
                                }

                                .c-currency {
                                    display: none;
                                    position: absolute;
                                    top: 48px;
                                    left: 0;
                                    width: auto;
                                    height: 0;
                                    min-width: 80px;
                                    background-color: $white;
                                    z-index: 2;
                                    padding: 16px 8px;
                                    @include box-shadow(0, 3px, 8px, 0, $black, .2);
                                    @include set-border-radius(0, 3px, 0, 3px);

                                    a.flag {
                                        display: block;
                                        width: 100%;
                                        position: relative;
                                        margin-bottom: 16px;
                                        color: $black;
                                        padding-left: 32px;
                                        text-transform: none;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 24px;

                                        i.icon {
                                            width: 24px;
                                            height: 24px;
                                            padding: 0 0;

                                            &:before {
                                                color: $black;
                                                font-size: 15px;
                                            }
                                        }

                                        &:before {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 24px;
                                            height: 24px;
                                            content: '';
                                        } 

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }
                                        
                                        &.id {

                                            &:before {
                                                background: url('../img/flag-id.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
    
                                        &.en {
    
                                            &:before {
                                                background: url('../img/flag-usd.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
    
                                        &.jp {
    
                                            &:before {
                                                background: url('../img/flag-jpn.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    cursor: pointer;
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: 48px;
                                    left: 8px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }

                                &.active {
                                    @include vendor('transition', ('all 0.5s ease'));

                                    .c-currency {
                                        display: block;
                                        @include vendor('transition', ('transform 0.5s ease'));
                                        height: auto;
                                    }

                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &:before {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: calc(100% - 137px - 32px);
                        height: 1px;
                        background-color: #e1e1e1;
                        content: '';
                        display: block;
                    }
                }

                .navbar-bottom {
                    position: relative;
                    width: 100%;
                    @include float-pos;

                    a.navbar-brand {
                        position: relative;
                        width: 92px;
                        height: 86px;
                        background: url('../img/logo.png') no-repeat;
                        background-size: 100% 100%;
                        color: transparent;
                        border: 0;
                        outline: none;
                        text-decoration: none;
                        white-space: nowrap;
                        line-height: inherit;
                        @include vendor('transition', ('all 0.3s'));
                        z-index: 2;
                        float: left;
                        margin-top: 5px;

                        &:hover,
                        &:focus {
                            outline: none;
                            cursor: pointer;
                        }
                    }

                    .sf-menu {
                        float: right;
                        width: calc(100% - 92px);
                        margin-bottom: 0;
                        padding: 24px 0 24px calc(137px + 16px);
                        text-align: right;
    
                        li {
                            margin: 0 0;
                            margin: 0 0;
                            float: none;
                            display: inline-block;
                            background: transparent;
                            
                            a {
                                display: block;
                                width: 100%;
                                height: 100%;
                                padding: 12px 16px;
                                font-family: $barlow;
                                font-size: 16px;
                                line-height: 24px;
                                color: #94999f;
                                text-transform: uppercase;
                                font-weight: 500;
                                border: 0;
                                @include border-radius(0);
                                @include vendor('transition', (
                                    'all 0.3s',
                                ));

                                &.search {
                                    padding: 0 0;

                                    i.icon {
                                        padding: 12px;

                                        &:before {
                                            width: 24px;
                                            height: 24px;
                                            text-align: center;
                                            font-size: 20px;
                                            line-height: 24px;
                                        }
                                    }
                                }
                            }
    
                            //sub menu
                            &.current,
                            &.sfHover {
    
                                a.sf-with-ul {
                                    padding-right: 40px;
    
                                    &:after {
                                        width: 24px;
                                        height: 24px;
                                        right: 8px;
                                        margin-top: 0;
                                        top: 11px;
                                        border: 0;
                                        font-family: $ico;
                                        font-size: 14px;
                                        line-height: 24px;
                                        text-align: center;
                                        color: #94999f;
                                        content: '\F107';
                                        @include vendor('transition', (
                                            'all 0.3s',
                                        ));
                                        @include vendor('transform', 'rotate(0deg)');
                                    }
                                }
    
                                //one sub menu
                                ul {
                                    background: $white;
                                    box-shadow: none;
                                    top: 100%;
                                    padding-top: 24px;
                                    
                                    //line on top
                                    &:before {
                                        position: absolute;
                                        top: calc(24px - 5px);
                                        left: 0;
                                        width: 0;
                                        height: 5px;
                                        background-color: $primary;
                                        content: '';
                                        @include vendor('transition', (
                                            'all 0.8s',
                                        ));
                                    }
                
                                    li {
                                        width: 100%;
                                        float: left;
                
                                        a {
                                            padding: 4px 16px;
                                            text-align: left;
                                            font-family: $barlow;
                                            font-size: 12px;
                                            line-height: 24px;
                                            text-transform: uppercase;
                                            background-color: transparent;
                                            @include vendor('transition', (
                                                'all 0.3s',
                                            ));
                                        }
    
                                        &.current,
                                        &.sfHover {
    
                                            a.sf-with-ul {
    
                                                &:after {	
                                                    top: 4px;
                                                    @include vendor('transform', 'rotate(-90deg)');
    
                                                }
                                            }

                                            ul {
                                                padding-top: 0;
                                                top: 0;
                                            }
                                        }
                
                                        &:hover {
                                            
                                            a,
                                            a.sf-with-ul {
                                                background-color: $primary;
                                                color: $white !important;
                                            }
    
                                            a.sf-with-ul {
    
                                                &:after {
                                                    color: $white !important;
                                                }
                                            }
    
                                            ul {
                                                
                                                li {
    
                                                    a {
                                                        color: $gray !important;
                                                    }
                                                }
                                            }
                                        }
    
                                        ul {
    
                                            li {
    
                                                &:hover {
    
                                                    a {
                                                        background-color: $primary !important;
                                                        color: $white !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
    
                                //hover
                                &:hover {
                                    
                                    a.sf-with-ul {
                                        
                                        &:after {
                                            color: $primary;
                                            @include vendor('transform', 'rotate(-180deg)');
                                        }
                                    }
    
                                    ul {
    
                                        &:before {
                                            display: block;
                                            width: 100%;
                                        }
    
                                        li {
    
                                            a {
                                                color: $gray;
                                            }
    
                                            &.current,
                                            &.sfHover {
    
                                                a.sf-with-ul {
                                        
                                                    &:after {
                                                        color: $gray;
                                                        @include vendor('transform', 'rotate(-90deg)');
                                                    }
                                                }
    
                                                ul {
    
                                                    li {
    
                                                        a {
                                                            background-color: transparent;
                                                            color: $gray;
                                                        }
                                                    }
    
                                                    &:before {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
    
                            //hover
                            &:hover {
                                cursor: pointer;
    
                                a {
                                    @include border-radius(0);
                                    color: $primary;
                                    background: transparent;
                                }
                            }

                            &.mega-nav {
                                position: unset;

                                .sf-mega {
                                    background-color: $white;
                                    padding: 16px;
                                    box-shadow: none;

                                    .nav-destination {
                                        position: relative;
                                        width: 100%;

                                        .row {
                                        margin-left: -8px;
                                        margin-right: -8px;

                                            .nav-mega {
                                                width: 20%;
                                                padding: 0 8px;
                                                overflow: hidden;

                                                .title {
                                                    position: relative;
                                                    width: 100%;
                                                    text-align: center;
                                                    color: $black;
                                                    font-family: $barlow;
                                                    font-size: 16px;
                                                    line-height: 26px;
                                                    letter-spacing: 1px;
                                                    text-transform: uppercase;
                                                    font-weight: 600;
                                                    margin-bottom: 8px;
                                                }

                                                .c-nav-mega {
                                                    position: relative;
                                                    width: 100%;
                                                    height: 100%;

                                                    a {
                                                        display: block;
                                                        width: 100%;
                                                        height: 100%;
                                                        color: transparent;
                                                        border: 0;
                                                        outline: none;
                                                        overflow: hidden;
                                                        padding: 0 0;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            object-fit: cover;
                                                            object-position: center;
                                                        }

                                                        span {
                                                            position: absolute;
                                                            top: 50%;
                                                            left: 0;
                                                            width: 100%;
                                                            text-align: center;
                                                            color: $white;
                                                            font-size: 14px;
                                                            line-height: 24px;
                                                            letter-spacing: 1px;
                                                            font-weight: 600;
                                                            letter-spacing: 1px;
                                                            margin-top: -12px;
                                                            display: block;
                                                        }
                                                    }
                                                }

                                                &.double {

                                                    .c-nav-mega {
                                                        height: 50%;

                                                        &:first-of-type {
                                                            padding-bottom: 8px;
                                                        }

                                                        &:last-of-type {
                                                            padding-top: 8px;
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-of-type {
                                vertical-align: top;
                            }
                        }
                    }

                    .mobile-nav {
                        display: none;
                        position: relative;
                        width: 100%;
    
                        .nav-mobile {
                            display: none;
                        }
    
                        .slicknav_menu {
                            display: none;
                            padding: 0 0 0 0;
                            background: transparent;
                            height: 56px;
                            width: 48px;
                            float: right;
                            @include vendor('transition', ('all 0.5s'));
        
                            .slicknav_btn {
                                margin: 0 0 0 0;
                                width: 48px;
                                height: 48px;
                                background-color: transparent;
                                @include border-radius(0);
                                padding: 12px;
                                float: left;
        
                                .slicknav_menutxt {
                                    display: none;
                                    color: transparent;
                                }
        
                                .slicknav_icon {
                                    width: 24px;
                                    height: 24px;
        
                                    .slicknav_icon-bar {	
                                        width: 24px;
                                        height: 2px;
                                        position: absolute;
                                        left: 12px;
                                        @include vendor('transition', ('all 0.3s'));
                                        margin-top: 0;
                                        background-color: $primary;
                                        box-shadow: none;
        
                                        &:nth-child(1) {
                                            top: 16px;
                                        }
        
                                        &:nth-child(2) {
                                            top: 50%;
                                            margin-top: -1px;
                                        }
        
                                        &:nth-child(3) {
                                            bottom: 16px;
                                        }
                                    }

                                    &:before {
                                        display: none;
                                    }
                                }
        
                                &.slicknav_open {
                                    z-index: 2;
        
                                    .slicknav_icon {
        
                                        .slicknav_icon-bar {
                                            background-color: $primary;
                                            box-shadow: none;
        
                                            &:nth-child(1) {
                                                top: 23px;
                                                @include vendor('transform', 'rotate(-45deg)');
                                            }
        
                                            &:nth-child(2) {
                                                display: none;
                                                width: 0;	
                                            }
        
                                            &:nth-child(3) {
                                                bottom: 23px;
                                                @include vendor('transform', 'rotate(45deg)');
                                            }
                                        }
                                    }
                                }
                            }
        
                            .slicknav_nav {
                                position: fixed;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                width: 0;
                                width: 100%;
                                height: 100%;
                                overflow: auto;
                                background-color: $white;
                                padding-top: 72px;
        
                                li {
                                    width: 100%;
                                    margin: 0 0;
                                    padding: 0 0;
        
                                    a {
                                        display: block;
                                        width: 100%;
                                        padding: 4px 16px;
                                        margin: 0 0;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: $black;
                                        font-weight: 500;
                                        height: auto;
                                        text-align: left;
                                        @include vendor('transition', ('all 0.3s'));
                                        position: relative;
                                    }
        
                                    &.current {
                                        padding: 0 0 0 16px;
                                        margin: 0 0;
        
                                        a.slicknav_item {
                                            padding-left: 0;
                                            padding-right: 0;
                                            position: relative;
        
                                            a {
                                                padding: 0 24px 0 0;
                                            }
        
                                            span.slicknav_arrow {
                                                position: absolute;
                                                top: 4px;
                                                right: 0;
                                                width: 24px;
                                                height: 24px;
                                                margin: 0 0;
                                                color: transparent;
                                                @include vendor('transition', ('all 0.3s'));
        
                                                &:before {
                                                    display: block;
                                                    width: 100%;
                                                    height: 100%;
                                                    font-family: $ico;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    content: '\F105';
                                                    text-align: center;
                                                    color: $black;
                                                    @include vendor('transition', ('all 0.3s'));
                                                }
                                            }
                                        }
        
                                        ul {
                                            padding: 0 0;
                                            margin: 0 0;
        
                                            li {
        
                                                a {
                                                    font-size: 14px;
                                                    
                                                }
        
                                                &.slicknav_collapsed {
        
                                                    a.slicknav_item {
                                                        padding: 0 0;
        
                                                        a {
                                                            padding: 0 24px 0 0;
                                                        }
        
                                                        span.slicknav_arrow {
        
                                                            &:before {
                                                                color: $black;
                                                            }
                                                        }
                                                    }
        
                                                    ul {
                                                        padding: 0 0;
                                                        margin: 0 0;
                                                    }
                                                }
                                            }
                                        }
        
                                        //menu child open
                                        &.slicknav_open {
        
                                            a.slicknav_item {
        
                                                span.slicknav_arrow {
        
                                                    &:before {
                                                        color: $primary;
                                                        @include vendor('transform', 'rotate(90deg)');
                                                    }
                                                }
                                            }
        
                                            ul {
            
                                                li {
            
                                                    &.slicknav_collapsed {
            
                                                        a.slicknav_item {
            
                                                            span.slicknav_arrow {
            
                                                                &:before {	
                                                                    @include vendor('transform', 'rotate(0)');
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
        
                                    &:hover {
                                        background: transparent;
        
                                        a {
                                            background: transparent;
                                            color: $primary;
                                        }
        
                                        ul {
        
                                            li {
        
                                                a {
                                                    color: $black;
        
                                                    span.slicknav_arrow {
        
                                                        &:before {
                                                            color: $black;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
        
                                &:after {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 64px;
                                    background-color: $white;
                                    content: '';
                                }
                            }
                        }

                        .search {
                            position: absolute;
                            top: 8px;
                            left: 64px;
                            float: left;
                            width: calc(100% - 64px - 56px - 48px);
                            height: 48px;
                            @include vendor('transition', ('transform 0.10s ease-in-out'));

                            form,
                            .form {
                                position: relative;
                                width: 100%;
                                height: 100%;

                                .row {
                                    margin: 0 0;

                                    .box {
                                        padding-left: 0;
                                        padding-right: 100px;

                                        input[type=text] {
                                            @include set-border-radius(5px, 5px, 0, 0);
                                        }

                                        .btn {
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            width: 100px;
                                            @include set-border-radius(0, 0, 5px, 5px);
                                            margin-bottom: 0;

                                            span {
                                                font-weight: 600;
                                                text-transform: uppercase;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .nav-account {
                            position: absolute;
                            top: 0;
                            right: calc(48px * 3);
                            @include vendor('transition', ('transform 0.10s ease-in-out'));

                            i.icon {

                                &:before {
                                    color: $primary;
                                }
                            }

                            .w-nav-account {
                                display: none;
                                position: absolute;
                                top: 72px;
                                right: 0;
                                width: 130px;
                                background: $white;
                                @include border-radius(5px);
                                @include vendor('transition', ('transform 0.10s ease-in-out'));

                                .c-nav-account {
                                    position: relative;
                                    width: 100%;
                                    height: 82px;
                                    padding: 8px;
                                    
                                    ul {
                                        padding: 0 0;
                                        margin: 0 0;

                                        li {
                                            margin: 0 0 0 0;

                                            a {
                                                display: block;
                                                width: 100%;
                                                height: auto;
                                                color: $black;
                                                font-weight: 600;
                                                letter-spacing: 1px;
                                                text-transform: uppercase;
                                                padding: 4px 0;
                                            }
                                        }
                                    }
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: -2px;
                                    right: 14px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }
                            }

                            &.active {

                                i.icon {

                                    &:before {
                                        color: $primary;
                                    }
                                }

                                .w-nav-account {
                                    display: block;

                                    &:after {
                                        display: block;
                                    }
                                }
                            }
                        }

                        .nav-currency-language {
                            position: absolute;
                            top: 0;
                            right: calc(48px * 2);
                            width: 48px;
                            height: 48px;
                            @include vendor('transition', ('transform 0.10s ease-in-out'));

                            i.flag {
                                width: 48px;
                                height: 48px;
                                position: relative;
                                padding: 12px;
                                display: block;

                                &:before {
                                    position: relative;
                                    width: 27px;
                                    height: 18px;
                                    content: '';
                                    display: block;
                                }

                                &.indonesia {

                                    &:before {
                                        background: url('../img/indonesia-flag.jpg') no-repeat;
                                        background-size: 100% auto;
                                        background-position: center;
                                    }
                                }

                                &.english {

                                    &:before {
                                        background: url('../img/united-states-flag.jpg') no-repeat;
                                        background-size: 100% auto;
                                        background-position: center;
                                    }
                                }

                                &.japan {

                                    &:before {
                                        background: url('../img/japan-flag.jpg') no-repeat;
                                        background-size: 100% auto;
                                        background-position: center;
                                    }
                                }
                            }

                            .w-nav-currency-language {
                                display: none;
                                position: absolute;
                                top: 72px;
                                right: 0;
                                width: 200px;
                                //height: 204px;
                                height: auto;
                                padding: 8px;
                                background: $white;
                                @include border-radius(5px);
                                @include vendor('transition', ('transform 0.10s ease-in-out'));

                                .c-language {
                                    position: relative;
                                    width: 100%;
                                    //padding-bottom: 8px;

                                    a.flag {
                                        display: block;
                                        position: relative;
                                        width: 100%;
                                        margin-top: 4px;
                                        margin-bottom: 4px;
                                        padding-left: 24px;
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: $black;
                                        letter-spacing: 1px;

                                        &:before {
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            width: 14px;
                                            height: 14px;
                                            content: '';
                                            margin-top: -7px;
                                        }

                                        &.id {

                                            &:before {
                                                background: url('../img/indonesia-flag.jpg') no-repeat;
                                                background-size: 100% auto;
                                                background-position: center;
                                            }
                                        }

                                        &.en {

                                            &:before {
                                                background: url('../img/united-states-flag.jpg') no-repeat;
                                                background-size: 100% auto;
                                                background-position: center;
                                            }
                                        }

                                        &.jp {

                                            &:before {
                                                background: url('../img/japan-flag.jpg') no-repeat;
                                                background-size: 100% auto;
                                                background-position: center;
                                            }
                                        }
                                    }

                                    &:after {
                                        position: absolute;
                                        bottom: 0;
                                        left: 50%;
                                        width: 80%;
                                        height: 1px;
                                        background: $gray-light;
                                        content: '';
                                        display: none;
                                        margin-left: -40%;
                                    }
                                }

                                .c-currency {
                                    position: relative;
                                    width: 100%;
                                    padding-top: 8px;

                                    a.flag {
                                        display: block;
                                        position: relative;
                                        width: 100%;
                                        margin-top: 4px;
                                        margin-bottom: 4px;
                                        padding-left: 24px;
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: $black;
                                        letter-spacing: 1px;

                                        i.icon {
                                            width: 24px;
                                            height: 24px;
                                            padding: 0 0;

                                            &:before {
                                                font-size: 14px;
                                                color: $black;
                                            }
                                        }

                                        &:before {
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            width: 14px;
                                            height: 14px;
                                            content: '';
                                            margin-top: -7px;
                                        }

                                        &.id {

                                            &:before {
                                                background: url('../img/indonesia-flag.jpg') no-repeat;
                                                background-size: 100% auto;
                                                background-position: center;
                                            }
                                        }

                                        &.en {

                                            &:before {
                                                background: url('../img/united-states-flag.jpg') no-repeat;
                                                background-size: 100% auto;
                                                background-position: center;
                                            }
                                        }

                                        &.jp {

                                            &:before {
                                                background: url('../img/japan-flag.jpg') no-repeat;
                                                background-size: 100% auto;
                                                background-position: center;
                                            }
                                        }
                                    }
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: -2px;
                                    right: 14px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }
                            }

                            &.active {

                                .w-nav-currency-language {
                                    display: block;

                                    &:after {
                                        display: block;
                                    }
                                }
                            }
                        }

                        a.btn-search {
                            display: none;
                            width: 48px;
                            height: 48px;
                            color: $white;
                            text-decoration: none;
                            position: absolute;
                            top: 0;
                            right: 48px;

                            i.icon {
                                display: block;
                                position: relative;
                                width: 48px;
                                height: 48px;
                                float: none;
                                top: 0;
                                left: 0;
                                padding: 12px;

                                &:before {
                                    color: $primary;
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }
            }
		}
	}
    
    //user dashboar header
    &.header-dashboard {

        .w-header {

            .navbar {
                
                .navbar-top {
                    border-bottom: 0;
                    background-color: $primary;
                    padding-top: 8px;
                    padding-bottom: 8px;

                    .container {
                        @include float-pos;

                        .left-content {
                            float: left;
                            width: auto;

                            a.logo {
                                display: block;
                                position: relative;
                                width: 51px;
                                height: 48px;
                                background: url('../img/logo-white.png') no-repeat;
                                background-size: 100% 100%;
                                color: transparent;
                                border: 0;
                            }
                        }

                        .right-content {
                            text-align: right;
                            float: right;

                            .language {
                                display: inline-block;
                                margin: 0 8px;
                                width: auto;
                                position: relative;

                                span {
                                    position: relative;
                                    width: 100%;
                                    color: $white;
                                    font-size: 12px;
                                    line-height: 24px;
                                    font-weight: 600;
                                    letter-spacing: 1px;
                                    padding: 12px 0 12px 28px;
                                    display: block;

                                    &:before {
                                        position: absolute;
                                        top: 16px;
                                        left: 0;
                                        width: 16px;
                                        height: 16px;
                                        content: '';
                                        display: block;
                                    }

                                    &.indonesia {

                                        &:before {
                                            background: url('../img/flag-id.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.english {

                                        &:before {
                                            background: url('../img/flag-usd.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.japan {

                                        &:before {
                                            background: url('../img/flag-jpn.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }
                                }

                                .c-language {
                                    display: none;
                                    position: absolute;
                                    top: 56px;
                                    left: 0;
                                    width: auto;
                                    height: 0;
                                    min-width: 120px;
                                    background-color: $white;
                                    z-index: 2;
                                    padding: 16px 8px;
                                    @include box-shadow(0, 3px, 8px, 0, $black, .2);
                                    @include set-border-radius(0, 3px, 0, 3px);

                                    a.flag {
                                        display: block;
                                        width: 100%;
                                        position: relative;
                                        margin-bottom: 16px;
                                        color: $black;
                                        padding-left: 24px;
                                        text-transform: none;
                                        font-weight: 600;
                                        font-size: 12px;
                                        line-height: 24px;
                                        text-align: left;

                                        &:before {
                                            position: absolute;
                                            top: 4px;
                                            left: 0;
                                            width: 16px;
                                            height: 16px;
                                            content: '';
                                        } 

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }
                                        
                                        &.id {

                                            &:before {
                                                background: url('../img/flag-id.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }

                                        &.en {

                                            &:before {
                                                background: url('../img/flag-usd.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }

                                        &.jp {

                                            &:before {
                                                background: url('../img/flag-jpn.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    cursor: pointer;
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: 48px;
                                    left: 8px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }

                                &.active {
                                    @include vendor('transition', ('all 0.5s ease'));

                                    .c-language {
                                        display: block;
                                        @include vendor('transition', ('transform 0.5s ease'));
                                        height: auto;
                                    }

                                    &:after {
                                        display: block;
                                    }
                                }
                            }

                            .currency {
                                display: inline-block;
                                margin: 0 8px;
                                width: auto;
                                position: relative;

                                span {
                                    position: relative;
                                    width: 100%;
                                    color: $white;
                                    font-size: 12px;
                                    line-height: 24px;
                                    font-weight: 600;
                                    letter-spacing: 1px;
                                    padding: 12px 0 12px 28px;
                                    display: block;

                                    &:before {
                                        position: absolute;
                                        top: 16px;
                                        left: 0px;
                                        width: 16px;
                                        height: 16px;
                                        content: '';
                                        display: block;
                                    }
                                    
                                    i.icon {
                                        padding: 0 0;
                                        width: 24px;
                                        height: 24px;

                                        &:before {
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    }

                                    &.indonesia {

                                        &:before {
                                            background: url('../img/flag-id.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.english {

                                        &:before {
                                            background: url('../img/flag-usd.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }

                                    &.japan {

                                        &:before {
                                            background: url('../img/flag-jpn.png') no-repeat;
                                            background-size: 100% 100%;
                                        }
                                    }
                                }

                                .c-currency {
                                    display: none;
                                    position: absolute;
                                    top: 56px;
                                    left: 0;
                                    width: auto;
                                    height: 0;
                                    min-width: 80px;
                                    background-color: $white;
                                    z-index: 2;
                                    padding: 16px 8px;
                                    @include box-shadow(0, 3px, 8px, 0, $black, .2);
                                    @include set-border-radius(0, 3px, 0, 3px);

                                    a.flag {
                                        display: block;
                                        width: 100%;
                                        position: relative;
                                        margin-bottom: 16px;
                                        color: $black;
                                        padding-left: 24px;
                                        text-transform: none;
                                        font-weight: 600;
                                        font-size: 12px;
                                        line-height: 24px;
                                        text-align: left;

                                        i.icon {
                                            width: 24px;
                                            height: 24px;
                                            padding: 0 0;

                                            &:before {
                                                font-size: 14px;
                                                line-height: 24px;
                                                color: $black;
                                            }
                                        }

                                        &:before {
                                            position: absolute;
                                            top: 4px;
                                            left: 0;
                                            width: 16px;
                                            height: 16px;
                                            content: '';
                                        } 

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }
                                        
                                        &.id {

                                            &:before {
                                                background: url('../img/flag-id.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }

                                        &.en {

                                            &:before {
                                                background: url('../img/flag-usd.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }

                                        &.jp {

                                            &:before {
                                                background: url('../img/flag-jpn.png') no-repeat;
                                                background-size: 100% 100%;
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    cursor: pointer;
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: 48px;
                                    left: 8px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }

                                &.active {
                                    @include vendor('transition', ('all 0.5s ease'));

                                    .c-currency {
                                        display: block;
                                        @include vendor('transition', ('transform 0.5s ease'));
                                        height: auto;
                                    }

                                    &:after {
                                        display: block;
                                    }
                                }
                            }

                            .user {
                                display: inline-block;
                                margin: 0 8px;
                                width: auto;
                                position: relative;

                                i.icon {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    margin: 0 0;

                                    &:before {
                                        color: $white;
                                        font-size: 16px;
                                    }
                                }

                                span {
                                    position: relative;
                                    width: 100%;
                                    color: $white;
                                    font-size: 12px;
                                    line-height: 24px;
                                    font-weight: 600;
                                    letter-spacing: 1px;
                                    padding: 12px 0 12px 48px;
                                    display: block;
                                }
                                
                                .c-user {
                                    display: none;
                                    position: absolute;
                                    top: 56px;
                                    right: 0;
                                    width: auto;
                                    height: 0;
                                    min-width: 200px;
                                    background-color: $white;
                                    z-index: 2;
                                    padding: 16px 8px;
                                    @include box-shadow(0, 3px, 8px, 0, $black, .2);
                                    @include set-border-radius(0, 3px, 0, 3px);

                                    a {
                                        display: block;
                                        width: 100%;
                                        position: relative;
                                        margin-bottom: 8px;
                                        color: $black;
                                        padding-left: 0;
                                        text-transform: none;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 24px;

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }
                                    }
                                }

                                &:hover {
                                    cursor: pointer;
                                }

                                &:after {
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: inherit;
                                    background: inherit;
                                    -webkit-box-shadow: inherit;
                                    box-shadow: inherit;
                                    top: 48px;
                                    right: 8px;
                                    @include vendor('transform', ('scaleX(0.9) rotate(45deg)'));
                                    @include vendor('transition', ('all 0.5s ease'));
                                    background-color: $white;
                                }

                                &.active {
                                    @include vendor('transition', ('all 0.5s ease'));

                                    .c-user {
                                        display: block;
                                        @include vendor('transition', ('transform 0.5s ease'));
                                        height: auto;
                                    }

                                    &:after {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                .container {

                    .navbar-bottom {

                        .sf-menu {
                            padding: 0 0;
                        }

                        .nav-mobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }

	@include breakpoint('<=large') {

        .w-header {

            .navbar {

                .container {
                    .navbar-top {

                        .right-content {

                            .login-register {
                                margin-right: 16px;
                                border-right: 1px solid #e1e1e1;
                                padding: 8px 0;

                                a.login,
                                a.register {
                                    margin: 0 16px 0 0;
                                    height: 24px;

                                    i.icon {
                                        width: 24px;
                                        height: 24px;
                                        margin-right: 8px;
                                    }

                                    span {
                                        line-height: 24px;
                                        font-size: 14px;
                                    }
                                }
                            }

                            .price-language {
                                margin-left: 16px;

                                .language {
                                    margin: 0 8px;
                                    
                                    span {
                                        line-height: 24px;
                                        padding: 12px 0 12px 48px;
                                        
                                        &:before {
                                            top: 12px;
                                            left: 6px;
                                        }
                                    }

                                    .c-language {
                                        top: 48px;
                                        left: 0;
                                        padding: 16px 8px;
                                        
                                        a.flag {
                                            margin-bottom: 16px;
                                            padding-left: 32px;
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    }
                                }

                                .currency {
                                    margin: 0 8px;
                                    
                                    span {
                                        line-height: 24px;
                                        padding: 12px 0 12px 48px;
                                        
                                        &:before {
                                            top: 12px;
                                            left: 6px;
                                        }

                                        i.icon {

                                            &:before {
                                                font-size: 14px;
                                            }
                                        }
                                    }

                                    .c-currency {
                                        top: 48px;
                                        left: 0;
                                        min-width: 80px;
                                        padding: 16px 8px;
                                        
                                        a.flag {
                                            margin-bottom: 16px;
                                            padding-left: 32px;
                                            font-size: 14px;
                                            line-height: 24px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .navbar-bottom {

                        a.navbar-brand {
                            width: 85px;
                            height: 80px;
                            margin-top: 5px;
                        }

                        .sf-menu {
                            padding: 21px 0;
        
                            li {
                                
                                a {
                                    padding: 12px 16px;
                                    font-size: 16px;
                                    line-height: 24px;
                                }
        
                                //sub menu
                                &.current,
                                &.sfHover {
        
                                    a.sf-with-ul {
                                        padding-right: 40px;
        
                                        &:after {
                                            right: 8px;
                                            top: 11px;
                                        }
                                    }
        
                                    ul {
                                        padding-top: 24px;
                                        
                                        &:before {
                                            top: calc(24px - 5px);
                                            left: 0;
                                            height: 5px;
                                        }
                    
                                        li {
                                            
                                            a {
                                                padding: 4px 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}

	@include breakpoint('<=medium') {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
		
		.w-header {

            .navbar {

                .container {

                    .navbar-brand {
                        top: 4px;
                        left: 16px;
                        width: 68px;
                        height: 64px;

                        a {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .navbar-top {
                        display: none;
                    }

                    .navbar-bottom {
                        padding-left: 0;
                        padding-top: 8px;
                        padding-bottom: 8px;;
                        
                        a.navbar-brand {
                            width: 85px;
                            height: 80px;
                            margin-top: 5px;
                            display: none;
                        }

                        .sf-menu {
                            display: none;
                        }
                        

                        .mobile-nav {
                            display: block;
    
                            .slicknav_menu {
                                display: block;
                            }

                            .search {
                                display: none;
                            }

                            a.btn-search {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include breakpoint('<=small') {

    }

	@include breakpoint('<=xsmall') {

        &.header-dashboard {

            .w-header {
    
                .navbar {
                    
                    .navbar-top {
    
                        .container {
    
                            .right-content {
    
                                .currency {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
	}
}

#header.fixed {
    position: fixed;
    background: rgba($white, 1);
    top: 0;
    left: 0;
    @include vendor('transition', ('transform 0.5s ease-in-out'));
    z-index: 5;

    .w-header {
        padding: 0 0;

        .container {
            
            .navbar-brand {
                width: 68px;
                height: 64px;
                top: 5px;
                @include vendor('transition', ('all .3s'));
                
                a {
                    width: 68px;
                    height: 64px;
                    @include vendor('transition', ('all .3s'));
                }
            }

            .navbar-top {
                display: none;
            }

            .navbar-bottom {

                a.navbar-brand {
                    width: 68px;
                    height: 64px;
                    margin-top: 5px;
                }

                .sf-menu {
                    padding: 13px 0;

                    li {

                        //sub menu
                        &.current,
                        &.sfHover {

                            //one sub menu
                            ul {
                                //top: calc(100% + 13px);
                                padding-top: 13px;
                                
                                &:before {
                                    top: calc(13px - 5px);
                                }
                            }
                        }
                    }
                }

                .mobile-nav {
    
                        .slicknav_menu {
        
                            .slicknav_btn {
        
                                .slicknav_icon {
        
                                    .slicknav_icon-bar {
                                        background-color: $primary;
                                        box-shadow: none;
                                    }
                                }
                            }
                        }

                    .nav-account {
                        
                        i.icon.user {

                            &:before {
                                color: $primary;
                            }
                        }
                    }

                    a.btn-search {

                        i.icon {

                            &:before {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=large') {
        .w-header {

            .container {
                
                .navbar-bottom {

                    a.navbar-brand {
                        width: 53px;
                        height: 50px;
                        margin-top: 4px;
                    }

                    .sf-menu {
                        padding: 5px 0;
    
                        li {
    
                            //sub menu
                            &.current,
                            &.sfHover {
    
                                //one sub menu
                                ul {
                                    //top: calc(100% + 13px);
                                    padding-top: 5px;
                                    
                                    &:before {
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=medium') {
        .w-header {

            .container {
                .navbar-brand {
                    top: 4px;
                }

                .navbar-bottom {

                    a.navbar-brand,
                    .sf-menu {
                        display: none;
                    }
                }
            }
        }
    }
}

#header.header-dashboard.fixed {

    .w-header {

        .navbar-top {
            display: block;
        }

        .container {

            .navbar-bottom {
                display: none;
            }
        }
    }

    @include breakpoint('<=large') {
        
    }

    @include breakpoint('<=medium') {
        
    }
}

//header nav mobile active
#header.navMobile {

    .w-header { 

        .navbar {
                
            .container {

                .navbar-bottom {

                    .mobile-nav {

                        .nav-account,
                        .nav-currency-language,
                        a.btn-search {
                            display: none;
                            opacity: 0;
                        }

                        .search {
                            width: calc(100% - 64px);
                            @include vendor('transition', ('transform 0.10s ease-in-out'));
                        }

                        .slicknav_menu {

                            .slicknav_nav {
                                right: 0;
                                width: 100%;                                        
                                @include vendor('transition', ('transform 0.5s ease-in-out'));
                            }
                        }
                    }
                }
            }
        }
    }
}