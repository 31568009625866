/*  
    ============================================================= 
        FOOTER
        set css footer
    ============================================================= 
*/

#footer {
	position: relative;
	flex-shrink: 0;
	width: 100%;
	background-color: #222222;
	//background-color: $primary;
	.w-footer {
		position: relative;
		width: 100%;

		.c-subcribes {
			position: relative;
			width: 100%;
			background-color: $white;
			padding: 48px 0;

			.container {

				.w-subcribes {
					position: relative;
					width: 100%;
					text-align: center;

					h3 {
						text-align: center;
						width: 100%;
						text-transform: none;
						margin: 0 0 4px 0;
					}

					span {
						display: inline-block;
						width: auto;
						font-size: 16px;
						line-height: 26px;
						letter-spacing: 0;
						color: rgba($black, .7);
					}

					.form,
					form {
						position: relative;
						width: 100%;
						max-width: 768px;
						display: block;
						margin: 32px auto 0 auto;

						.row {
							margin: 0 0;

							input[type="text"] {
								padding-right: 200px;
							}

							.box {
								padding: 0 0;

								.btn {
									position: absolute;
									top: 0;
									right: 0;
									width: 200px;
									@include set-border-radius(0, 0, 3px, 3px);

									span {
										color: $white;
										letter-spacing: 1px;
										text-transform: uppercase;
									}
								}
							}
						}
					}
				}
			}
		}

		.c-instagram {
			position: relative;
			width: 100%;
			@include float-pos;

			.box-instagram {
				position: relative;
				float: left;
				width: calc(100% / 5);

				a {
					padding-top: calc((1/1) * 100%);
					position: relative;
                    width: 100%;
                    color: transparent;
                    border: 0;
					overflow: hidden;
					display: block;

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}

					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		.c-footer-nav {
			position: relative;
			width: 100%;
			padding-top: 32px;

			.container {

				.row {
					margin: 0 -16px;

					.footer-about {
						width: 20%;
						padding: 0 16px;

						.wrp-footer {
							position: relative;
							width: 100%;

							.logo-footer {
								width: 100%;
								display: block;

								a {
									display: block;
									width: 100%;
									height: 100%;
									color: transparent;
									border: 0;
									outline: none;
									text-decoration: none;
									max-width: 100px;

									img {
										display: block;
										width: 100%;
										height: auto;
									}
								}
							}

							.about-footer {
								position: relative;
								width: 100%;
								display: block;
								margin-top: 32px;

								h5 {
									text-align: left;
									color: $white;
									margin: 0 0;
								}

								p {
									position: relative;
									width: 100%;
									margin: 16px 0 0 0;
									text-align: left;
									color: $white;
									
									i.icon {
										width: 24px;
										height: 24px;
										margin: 0 8px 0 0;
										padding: 0 0;

										&:before {
											color: $white;
										}
									}

									a {
										color: $white;
										text-decoration: none;
										outline: none;
										width: auto;
										display: inline-block;
										margin: 0 0;
									}
								}
							}

							.social-media-footer {
								position: relative;
								width: 100%;
								display: block;
								margin-top: 32px;

								h5 {
									text-align: left;
									width: 100%;
									text-align: left;
									margin: 0 0 8px 0;
									color: $white;
								}

								.social-media {
									position: relative;
									width: 100%;
									text-align: left;

									a {
										width: 24px;
										height: 24px;
										display: inline-block;
										margin: 0 4px;
										outline: none;
										text-decoration: none;
										color: $white;

										i.icon {
											width: 24px;
											height: 24px;
											margin: 0 0;
											display: block;
											padding: 0 0;

											&:before {
												color: $white;
											}
										}

										&:first-of-type {
											margin-left: 0;
										}

										&:last-of-type {
											margin-right: 0;
										}
									}
								}
							}
						}
					}

					.footer-nav {
						width: 50%;
						padding: 0 16px;

						.wrp-footer {
							position: relative;
							width: 100%;
							padding-top: 124px;

							h5 {
								display: block;
								width: 100%;
								text-align: left;
								margin-bottom: 32px;
								text-decoration: none;
								color: $white;
							}

							ul {
								padding: 0 0;
								margin: 0 0;
								@include flex(wrap, row, flex-start, flex-start);

								li {
									position: relative;
									width: 50%;
									background: none;
									margin-bottom: 8px;

									a {
										display: inline-block;
										width: auto;
										height: auto;
										color: $white;
										text-align: left;
										text-decoration: none;
										@include vendor('transition', ('all .3s ease-in-out'));

										&:hover {
											cursor: pointer;
											color: $primary;
										}
									}

									&:nth-child(even) {
										padding-left: 12px;

										&:after {
											left: 12px;
										}
									}

									&:nth-child(odd) {
										padding-right: 12px;

										&:after {
											left: 0px;
										}
									}
								}
							}
						}
					}

					.footer-subcribes {
						width: 30%;
						padding: 0 16px;

						.wrp-footer {
							position: relative;
							width: 100%;
							padding-top: 124px;

							h5 {
								display: block;
								width: 100%;
								text-align: left;
								margin-bottom: 0px;
								text-decoration: none;
								color: $white;
							}

							p {
								position: relative;
								width: 100%;
								text-align: left;
								color: $white;
								margin: 8px 0 0 0;
							}

							.subcribe {
								position: relative;
								width: 100%;
								margin-top: 24px;

								.form,
								form {
									position: relative;
									width: 100%;
									display: block;

									input[type="text"] {
										padding-right: 100px;
									}

									.box {
										padding: 0 0;

										.btn {
											position: absolute;
											top: 0;
											right: 0;
											width: 100px;
											@include set-border-radius(0, 0, 3px, 3px);
											padding-right: 0;
											padding-left: 0;
											margin-bottom: 0;
											background: #222222;

											span {
												color: $white;
												letter-spacing: 1px;
												text-transform: uppercase;
												font-weight: 600;
											}
										}
									}
								}
							}
						}
					}
				}

				.footer-copy {
					position: relative;
					width: 100%;
					padding: 8px 0px;

					p {
						position: relative;
						width: 100%;
						text-align: center;
						margin: 0 0;
						color: $white;
					}
				}
			}
		}
    }
    
    &.hide {
       
        .w-footer {
            position: relative;
            width: 100%;
    
            .c-subcribes,
            .c-instagram {
                display: none;
            }
        }
    }

	@include breakpoint('<=xlarge') {

    }
    
    @include breakpoint('<=medium') {
		padding-bottom: 48px;
		
        &.hide {

            .w-footer {

                .c-subcribes,
                .c-instagram {
                    display: block;
                }
            }
		}
		
		.w-footer {

			.c-footer-nav {
				padding-top: 24px;
	
				.container {
	
					.row {
						margin: 0 -8px;
	
						.footer-about {
							width: 100%;
							padding: 0 8px;
	
							.wrp-footer {
								@include float-pos;

								.logo-footer {
	
									a {
										margin: 0 auto;
									}
								}
	
								.about-footer {
									margin-top: 24px;
									width: 50%;
									padding-right: 16px;
									float: left;
								}
	
								.social-media-footer {
									margin-top: 24px;
									width: 50%;
									padding-left: 16px;
									float: left;
								}
							}
						}
	
						.footer-nav {
							width: 100%;
							padding: 0 8px;
	
							.wrp-footer {
								padding-top: 24px;
	
								h5 {
									margin-bottom: 24px;
								}
	
								ul {
	
									li {
										width: 50%;
	
										&:nth-child(even) {
											padding-left: 12px;
										}
	
										&:nth-child(odd) {
											padding-right: 12px;
										}
									}
								}
							}
						}
	
						.footer-subcribes {
							width: 100%;
							padding: 0 8px;
	
							.wrp-footer {
								padding-top: 24px;
	
								h5 {
									margin-bottom: 24px;
									text-align: center;
								}
	
								p {
									text-align: center;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@include breakpoint('<=xsmall') {

		.w-footer {

			.c-footer-nav {
	
				.container {
	
					.row {
						margin: 0 -8px;
	
						.footer-about {
	
							.wrp-footer {

								.logo-footer {
	
									a {
										margin: 0 auto;
									}
								}
	
								.about-footer {
									width: 100%;
									padding-right: 0px;
									float: none;
								}
	
								.social-media-footer {
									width: 100%;
									padding-left: 0px;
									float: none;
								}
							}
						}
	
						.footer-nav {
							width: 100%;
							padding: 0 8px;
	
							.wrp-footer {
								padding-top: 24px;
	
								h5 {
									margin-bottom: 24px;
								}
	
								ul {
	
									li {
										width: 50%;
	
										&:nth-child(even) {
											padding-left: 12px;
										}
	
										&:nth-child(odd) {
											padding-right: 12px;
										}
									}
								}
							}
						}
	
						.footer-subcribes {
							width: 100%;
							padding: 0 8px;
	
							.wrp-footer {
								padding-top: 24px;
	
								h5 {
									margin-bottom: 24px;
									text-align: center;
								}
	
								p {
									text-align: center;
								}
							}
						}
					}
				}
			}
		}
    }
}

.nav-fixed-mobile {
	display: none;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 48px;
	background-color: $primary;
	z-index: 10;
	@include set-border-radius(10px, 0, 10px, 0);

	ul {
		position: relative;
		width: 100%;
		height: 100%;
		@include float-pos;
		padding: 0 0;
		margin: 0 0;

		li {
			float: left;
			width: 33.33%;
			
			a {
				display: block;
				width: 100%;
				height: 100%;
				color: $white;
				text-align: center;

				span {
					font-weight: 600;
					letter-spacing: 1px;
					display: inline-block;
					line-height: 48px;
					font-size: 14px;
				}
			}
		}
	}

	@include breakpoint('<=medium') {
		display: block;
	}

	@include breakpoint('<=small') {

		ul {
	
			li {
				
				a {
	
					span {
						font-size: 12px;
					}
				}
			}
		}
	}

	@include breakpoint('<=xsmall') {

		ul {
			padding: 4px 0;

			li {
				
				a {

					i.icon {

						&:before {
							font-size: 24px;
						}
					}

					span {
						display: none;
					}
				}
			}
		}
	}
}