#wrapper {

    #main {

        .s-pages {

            .container {

                .sc-top-activities {
                    position: relative;
                    width: 100%;
                    margin-bottom: 32px;
                    @include float-pos;

                    .find-activities {
                        float: left;
                        width: 60%;

                        p {
                            position: relative;
                            width: 100%;
                            margin: 0 0 4px 0;
                            text-align: left;
                        }

                        .form,
                        form {
                            position: relative;
                            width: 100%;

                            .row {
                                margin: 0 -2px;
                                .box {
                                    width: 20%;
                                    padding: 0 2px;

                                    input[type="text"] {
                                        padding-right: 8px;
                                        padding-left: 48px;
                                    }

                                    .btn {
                                        margin-top: 28px;
                                        margin-bottom: 0;
                                        width: 100%;
                                        padding-left: 0;
                                        padding-right: 0;

                                        span {
                                            font-weight: 600;
                                            text-transform: uppercase;
                                        }
                                    }

                                    i.icon {
                                        position: absolute;
                                        top: 28px;
                                        left: 0;

                                        &:before {
                                            color: rgba($black, .4);
                                        }
                                    }

                                    &:first-of-type {
                                        width: 40%;
                                    }
                                }
                            }
                        }
                    }

                    .sort {
                        float: right;
                        width: auto;
                        padding-top: 28px;
                        width: 190px;

                        form {

                            .row {

                                .box {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }

                    &.search-result {

                        .find-activities {
                            width: auto;
                            text-align: left;
                            min-height: 83px;
                            @include flex($justify: center, $align: center);

                            p {
                                display: inline-block;
                                width: auto;
                                margin-bottom: 0;
                            }
                        }

                        .sort {
                            padding-top: 0;
                        }
                    }
                }

                .sc-activities {

                    .row {
                        margin: 0 -12px;

                        .activities {
                            width: 25%;
                            padding: 0 12px;
                            margin: 12px 0;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=large') {

        #main {

            .s-pages {

                .container {

                    .sc-top-activities {

                        .find-activities {

                            width: 70%;

                            .form,
                            form {
    
                                .row {

                                    .box {
                                        width: 20%;
    
                                        input[type="text"] {
                                            padding-left: 48px;
                                        }
    
                                        .btn {
                                            margin-top: 28px;
                                        }
    
                                        i.icon {
                                           top: 28px;
                                        }
    
                                        &:first-of-type {
                                            width: 40%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .sc-activities {

                        .row {

                            .activities {
                                width: 33.33%;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=small') {

        #main {

            .s-pages {

                .container {

                    .sc-top-activities {
                        margin-bottom: 16px;

                        .find-activities {

                            width: 100%;

                            .form,
                            form {
    
                                .row {

                                    .box {
                                        width: 20%;
    
                                        input[type="text"] {
                                            padding-left: 40px;
                                        }
    
                                        &:first-of-type {
                                            width: 40%;
                                        }
                                    }
                                }
                            }
                        }

                        .sort {
                            width: 100%;
                            padding-top: 16px;
                        }
                    }

                    .sc-activities {

                        .row {

                            .activities {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint('<=xsmall') {

        #main {

            .s-pages {

                .container {

                    .sc-top-activities {
                        margin-bottom: 16px;

                        .find-activities {

                            width: 100%;

                            .form,
                            form {
    
                                .row {

                                    .box {
                                        width: 20%;
    
                                        input[type="text"] {
                                            padding-left: 40px;
                                        }
    
                                        &:first-of-type {
                                            width: 40%;
                                        }
                                    }
                                }
                            }
                        }

                        
                    }

                    .sc-activities {

                        .row {

                            .activities {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
